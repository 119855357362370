import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

import SellerPresenter from './SellerPresenter';

const SellerContainer = () => {
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <SellerPresenter
        active={active}
        setActive={setActive}
        userInfo={userInfo}
      />
    </>
  );
};

SellerContainer.defaultProps = {};

export default SellerContainer;
