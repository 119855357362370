import React, { Dispatch, SetStateAction } from 'react';
import * as s from './UserListStyled';
import { Link } from 'react-router-dom';
import { FaFileExcel } from 'react-icons/fa';
import Pagination from 'react-js-pagination';
import moment from 'moment';
import 'components/styles/Paging.css';

interface UserListProps {
  page: number;
  handlePageChange: (page: number) => void;
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  userStCd: string;
  setUserStCd: Dispatch<SetStateAction<string>>;
  userStList: any[];
  userTypeList: any[];

  searchData: () => void;
  pageOffset: number;
  setPageOffset: Dispatch<SetStateAction<number>>;
  pageOffsetList: number[];
  totalCount: number;
  userList: any[];
  setUserList: Dispatch<SetStateAction<any[]>>;
  downloadExcel: () => void;
  updateUser: (user: any) => void;
}

const UserListPresenter = (props: UserListProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>사용자 목록</s.PageTitle>
        <s.PageIndicator>{'사용자관리 > 사용자목록'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.GeneralInfoArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>검색키워드</s.SectionTh>
              <s.SectionTd width="80%" align="left" noPad>
                <s.GeneralInfoInput
                  type="text"
                  placeholder="통합검색 키워드를 입력하세요."
                  value={props.keyword}
                  onChange={(e) => props.setKeyword(e.target.value)}
                  autoComplete="new-password"
                />
              </s.SectionTd>
              <s.SectionTh style={{ width: '10%' }} rowSpan={2}>
                <s.SearchButton onClick={() => props.searchData()}>
                  검색
                </s.SearchButton>
                <s.ExcelButton onClick={() => props.downloadExcel()}>
                  <FaFileExcel color={'#78DD78'} size={'2rem'} />
                  엑셀
                </s.ExcelButton>
              </s.SectionTh>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>상태</s.SectionTh>
              <s.SectionTd width="80%" align="left" noPad>
                <s.GeneralInfoSelect
                  width="20rem"
                  value={props.userStCd}
                  onChange={(e) => props.setUserStCd(e.target.value)}
                >
                  <option value="">선택</option>
                  {props.userStList.map((userSt: any) => (
                    <option value={userSt.comnCd}>{userSt.comnCdNm}</option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.GeneralInfoArea>
      <s.PageOffsetArea>
        <s.SectionTable style={{ width: '25rem' }}>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '12rem' }}>조회수량</s.SectionTh>
              <s.SectionTd width="13rem" noPad>
                <s.GeneralInfoSelect
                  value={props.pageOffset}
                  onChange={(e) => props.setPageOffset(Number(e.target.value))}
                  width="12rem"
                >
                  {props.pageOffsetList.map((offset: number) => (
                    <option value={offset}>{offset}건 씩</option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.PageOffsetArea>
      <s.DataArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh center style={{ width: '8%' }}>
                이름
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                ID
              </s.SectionTh>
              <s.SectionTh center style={{ width: '6%' }}>
                생년월일
              </s.SectionTh>
              <s.SectionTh center style={{ width: '9%' }}>
                휴대전화
              </s.SectionTh>
              <s.SectionTh center style={{ width: '16%' }}>
                이메일
              </s.SectionTh>
              <s.SectionTh center style={{ width: '10%' }}>
                소속
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                사용자유형
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                상태
              </s.SectionTh>
              <s.SectionTh center style={{ width: '10%' }}>
                가입일
              </s.SectionTh>
              <s.SectionTh center style={{ width: '12%' }}>
                비밀번호초기화
              </s.SectionTh>
              <s.SectionTh center style={{ width: '10%' }}>
                수정하기
              </s.SectionTh>
            </s.SectionTr>
            {props.userList.length < 1 ? (
              <s.SectionTr>
                <s.SectionTd colSpan={10} style={{ height: '50rem' }}>
                  조회값이 없습니다.
                </s.SectionTd>
              </s.SectionTr>
            ) : (
              props.userList.map((user: any, index: number) => (
                <s.SectionTr hoverActive>
                  <s.SectionTd>{user.userNm}</s.SectionTd>
                  <s.SectionTd>{user.loginId}</s.SectionTd>
                  <s.SectionTd>{user.birthDt}</s.SectionTd>
                  <s.SectionTd>{user.userMobno}</s.SectionTd>
                  <s.SectionTd>{user.userEmail}</s.SectionTd>
                  <s.SectionTd>{user.comNm}</s.SectionTd>
                  <s.SectionTd>
                    <s.GeneralInfoSelect
                      width="100%"
                      value={user.userTypeCd}
                      onChange={(e) => {
                        const tempUserList = props.userList;
                        tempUserList[index].userTypeCd = e.target.value;
                        props.setUserList([...tempUserList]);
                      }}
                    >
                      {props.userTypeList.map((type: any) => (
                        <option value={type.comnCd}>{type.comnCdNm}</option>
                      ))}
                    </s.GeneralInfoSelect>
                  </s.SectionTd>
                  <s.SectionTd>
                    <s.GeneralInfoSelect
                      width="100%"
                      value={user.userStCd}
                      onChange={(e) => {
                        const tempUserList = props.userList;
                        tempUserList[index].userStCd = e.target.value;
                        props.setUserList([...tempUserList]);
                      }}
                    >
                      {props.userStList.map((st: any) => (
                        <option value={st.comnCd}>{st.comnCdNm}</option>
                      ))}
                    </s.GeneralInfoSelect>
                  </s.SectionTd>
                  <s.SectionTd>
                    {moment(user.regDtm).format('YYYY-MM-DD HH:mm')}
                  </s.SectionTd>
                  <s.SectionTd noPad>
                    <s.GeneralInfoInput
                      width="95%"
                      type="password"
                      placeholder="초기화비밀번호 입력"
                      value={user.newPassword}
                      onChange={(e) => {
                        const tempUserList = props.userList;
                        tempUserList[index].newPassword = e.target.value;
                        props.setUserList([...tempUserList]);
                      }}
                      autoComplete="new-password"
                    />
                  </s.SectionTd>
                  <s.SectionTd>
                    <s.UpdateUserButton onClick={() => props.updateUser(user)}>
                      수정
                    </s.UpdateUserButton>
                  </s.SectionTd>
                </s.SectionTr>
              ))
            )}
          </s.SectionTbody>
        </s.SectionTable>
      </s.DataArea>
      {props.userList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
    </s.Container>
  );
};

export default UserListPresenter;
