import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 80%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
`;

export const LeftMenu = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  padding: 5rem 2rem;
  border-right: 1px solid ${theme.color.grey};
`;

export const LeftMenuTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${theme.color.black};
  padding: 2rem;
  border-bottom: 2px solid ${theme.color.grey};
  display: flex;
`;

export const LeftMenuItem = styled.div<{ level: number; active?: boolean }>`
  font-size: ${(props) => (props.level === 1 ? '1.6rem' : '1.2rem')};
  font-weight: ${(props) => (props.level === 1 ? '500' : '400')};
  color: ${(props) => (props.active ? theme.color.primary : theme.color.black)};
  border-top: ${(props) =>
    props.level === 1 ? `1px solid ${theme.color.grey}` : null};
  border-bottom: ${(props) =>
    props.level === 1 ? `1px solid ${theme.color.grey}` : null};
  padding: ${(props) => (props.level === 1 ? '1.5rem 2rem' : '1rem 2rem')};
  display: flex;
  cursor: ${(props) => (props.level !== 1 ? 'pointer' : 'default')};
`;

export const ContentArea = styled.div`
  width: 85%;
`;
