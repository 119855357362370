import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { categoryApi } from 'api/api-category';
import { productApi } from 'api/api-product';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';

import ProductListPresenter from './ProductListPresenter';

interface Props {
  setActive: Dispatch<SetStateAction<number>>;
  setCurrentProduct: Dispatch<SetStateAction<any>>;
}

const ProductListContainer = (props: Props) => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const pageOffsetList = [10, 50, 100, 300];

  const [pageOffset, setPageOffset] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>('');
  const [categoryLv1, setCategoryLv1] = useState<string>('');
  const [categoryLv2, setCategoryLv2] = useState<string>('');
  const [categoryLv3, setCategoryLv3] = useState<string>('');
  const [categoryLv4, setCategoryLv4] = useState<string>('');

  const [categoryLv1List, setCategoryLv1List] = useState<any[]>([]);
  const [categoryLv2List, setCategoryLv2List] = useState<any[]>([]);
  const [categoryLv3List, setCategoryLv3List] = useState<any[]>([]);
  const [categoryLv4List, setCategoryLv4List] = useState<any[]>([]);

  const [sellYn, setSellYn] = useState<string>('');

  const [totalCount, setTotalCount] = useState<number>(0);
  const [productList, setProductList] = useState<any[]>([]);

  useEffect(() => {
    props.setCurrentProduct(null);
    getCategoryList(null, 1);
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  }, [pageOffset]);

  useEffect(() => {
    if (categoryLv1) {
      getCategoryList(categoryLv1, 2);
    } else {
      setCategoryLv2('');
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv2List([]);
      setCategoryLv3List([]);
      setCategoryLv4List([]);
    }
  }, [categoryLv1]);

  useEffect(() => {
    if (categoryLv2) {
      getCategoryList(categoryLv2, 3);
    } else {
      setCategoryLv2('');
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv3List([]);
      setCategoryLv4List([]);
    }
  }, [categoryLv2]);

  useEffect(() => {
    if (categoryLv3) {
      getCategoryList(categoryLv3, 4);
    } else {
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv4List([]);
    }
  }, [categoryLv3]);

  useEffect(() => {
    setCategoryLv2('');
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv2List([]);
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv1List]);

  useEffect(() => {
    setCategoryLv2('');
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv2List]);

  useEffect(() => {
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv4List([]);
  }, [categoryLv3List]);

  useEffect(() => {
    setCategoryLv4('');
  }, [categoryLv4List]);

  const getCategoryList = async (
    upperCategoryCd: string | null,
    level: number,
  ) => {
    try {
      const data = {
        upperCategoryCd: upperCategoryCd,
      };

      setIsLoading(true);
      const categoryRes = await categoryApi.getList(data);
      if (categoryRes.data.rsltCd === '00') {
        if (level === 1) {
          setCategoryLv1List(categoryRes.data.data.categoryList);
        } else if (level === 2) {
          setCategoryLv2List(categoryRes.data.data.categoryList);
        } else if (level === 3) {
          setCategoryLv3List(categoryRes.data.data.categoryList);
        } else if (level === 4) {
          setCategoryLv4List(categoryRes.data.data.categoryList);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data: any = {
        comId: comInfo.comId,
      };

      if (keyword) {
        data.keyword = keyword;
      }

      if (categoryLv4) {
        data.category = categoryLv4;
        data.categoryLv = 4;
      } else if (categoryLv3) {
        data.category = categoryLv3;
        data.categoryLv = 3;
      } else if (categoryLv2) {
        data.category = categoryLv2;
        data.categoryLv = 2;
      } else if (categoryLv1) {
        data.category = categoryLv1;
        data.categoryLv = 1;
      }

      if (sellYn) {
        data.sellYn = sellYn;
      }

      data.page = page;
      data.pageOffset = pageOffset;

      data.userId = userInfo.userId;

      const res = await productApi.getList(data);

      if (res.data.rsltCd === '00') {
        setProductList(res.data.data.productList);
        setTotalCount(res.data.data.totalCount);
      } else {
        setProductList([]);
        setTotalCount(0);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('조회에 실패하였습니다.', 'error');
    }
  };

  const searchData = () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const goToUpdate = async (product: any) => {
    props.setCurrentProduct(product);
    props.setActive(1);
  };

  const downloadExcel = async () => {
    makeMsg('준비중입니다.', 'warning');
  };

  return (
    <>
      <ProductListPresenter
        page={page}
        handlePageChange={handlePageChange}
        keyword={keyword}
        setKeyword={setKeyword}
        categoryLv1={categoryLv1}
        setCategoryLv1={setCategoryLv1}
        categoryLv2={categoryLv2}
        setCategoryLv2={setCategoryLv2}
        categoryLv3={categoryLv3}
        setCategoryLv3={setCategoryLv3}
        categoryLv4={categoryLv4}
        setCategoryLv4={setCategoryLv4}
        categoryLv1List={categoryLv1List}
        categoryLv2List={categoryLv2List}
        categoryLv3List={categoryLv3List}
        categoryLv4List={categoryLv4List}
        sellYn={sellYn}
        setSellYn={setSellYn}
        totalCount={totalCount}
        productList={productList}
        goToUpdate={goToUpdate}
        searchData={searchData}
        pageOffset={pageOffset}
        setPageOffset={setPageOffset}
        pageOffsetList={pageOffsetList}
        downloadExcel={downloadExcel}
      />
    </>
  );
};

ProductListContainer.defaultProps = {};

export default ProductListContainer;
