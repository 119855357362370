import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeMsg, readFileAsDataURL } from 'util/util';
import recoilItem from 'util/recoilitem';

import BannerPresenter from './BannerPresenter';
import { bannerApi } from 'api/api-banner';

const BannerContainer = () => {
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const token = useRecoilValue(recoilItem.token);

  const [banner1Id, setBanner1Id] = useState<number>(-1);
  const [banner2Id, setBanner2Id] = useState<number>(-1);
  const [banner3Id, setBanner3Id] = useState<number>(-1);
  const [banner1Img, setBanner1Img] = useState<any>(null);
  const [banner1Preview, setBanner1Preview] =
    useState<string>('/img/empty.png');
  const [banner2Img, setBanner2Img] = useState<any>(null);
  const [banner2Preview, setBanner2Preview] =
    useState<string>('/img/empty.png');
  const [banner3Img, setBanner3Img] = useState<any>(null);
  const [banner3Preview, setBanner3Preview] =
    useState<string>('/img/empty.png');

  const [banner1Keyword, setBanner1Keyword] = useState<string>('');
  const [banner2Keyword, setBanner2Keyword] = useState<string>('');
  const [banner3Keyword, setBanner3Keyword] = useState<string>('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = {
        comId: comInfo.comId,
      };

      const res = await bannerApi.getList(data);

      if (res.data.rsltCd === '00') {
        const banner1 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 1,
        );

        const banner2 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 2,
        );

        const banner3 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 3,
        );

        if (banner1) {
          setBanner1Id(banner1.bannerId);
          setBanner1Preview(banner1.img);
          setBanner1Keyword(banner1.keyword);
          setBanner1Img(null);
        }

        if (banner2) {
          setBanner2Id(banner2.bannerId);
          setBanner2Preview(banner2.img);
          setBanner2Keyword(banner2.keyword);
          setBanner2Img(null);
        }

        if (banner3) {
          setBanner3Id(banner3.bannerId);
          setBanner3Preview(banner3.img);
          setBanner3Keyword(banner3.keyword);
          setBanner3Img(null);
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (banner1Img) {
      const reader = new FileReader();
      reader.readAsDataURL(banner1Img);
      reader.onloadend = async () => {
        setBanner1Preview(String(reader.result));
      };
    }
  }, [banner1Img]);

  useEffect(() => {
    if (banner2Img) {
      const reader = new FileReader();
      reader.readAsDataURL(banner2Img);
      reader.onloadend = async () => {
        setBanner2Preview(String(reader.result));
      };
    }
  }, [banner2Img]);

  useEffect(() => {
    if (banner3Img) {
      const reader = new FileReader();
      reader.readAsDataURL(banner3Img);
      reader.onloadend = async () => {
        setBanner3Preview(String(reader.result));
      };
    }
  }, [banner3Img]);

  const saveBannerInfo = async (index: number) => {
    try {
      setIsLoading(true);
      const data: any = {
        comId: comInfo.comId,
        index: index,
      };

      if (index === 1) {
        if (banner1Img) {
          data.img = await readFileAsDataURL(banner1Img);
        }

        data.keyword = banner1Keyword;
        if (banner1Id > -1) {
          data.bannerId = banner1Id;
        }
      } else if (index === 2) {
        if (banner2Img) {
          data.img = await readFileAsDataURL(banner2Img);
        }

        data.keyword = banner2Keyword;
        if (banner2Id > -1) {
          data.bannerId = banner2Id;
        }
      } else if (index === 3) {
        if (banner3Img) {
          data.img = await readFileAsDataURL(banner3Img);
        }

        data.keyword = banner3Keyword;
        if (banner3Id > -1) {
          data.bannerId = banner3Id;
        }
      }

      const res = await bannerApi.upsert(token, data);
      if (res.data.rsltCd === '00') {
        makeMsg('저장되었습니다.', 'success');
        setIsLoading(false);
        fetchData();
      } else {
        makeMsg('저장에 실패하였습니다.', 'error');
        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <BannerPresenter
        banner1Img={banner1Img}
        setBanner1Img={setBanner1Img}
        banner2Img={banner2Img}
        setBanner2Img={setBanner2Img}
        banner3Img={banner3Img}
        setBanner3Img={setBanner3Img}
        banner1Preview={banner1Preview}
        banner2Preview={banner2Preview}
        banner3Preview={banner3Preview}
        banner1Keyword={banner1Keyword}
        setBanner1Keyword={setBanner1Keyword}
        banner2Keyword={banner2Keyword}
        setBanner2Keyword={setBanner2Keyword}
        banner3Keyword={banner3Keyword}
        setBanner3Keyword={setBanner3Keyword}
        saveBannerInfo={saveBannerInfo}
      />
    </>
  );
};

BannerContainer.defaultProps = {};

export default BannerContainer;
