import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProductListStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import 'components/styles/Paging.css';
import { priceToString } from 'util/util';
import { FaSearch } from 'react-icons/fa';
import theme from 'components/styles/theme';
import { isMobile } from 'react-device-detect';

interface ProductListProps {
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  userInfo: any;
  page: number;
  handlePageChange: (page: number) => void;
  pageOffset: number;

  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;

  banner1Preview: string;
  banner2Preview: string;
  banner3Preview: string;
  banner1Keyword: string;
  banner2Keyword: string;
  banner3Keyword: string;

  totalCount: number;
  productList: any[];
  searchData: () => void;

  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  searchByKeyword: () => void;
  checkSearchEnter: (event: any) => void;
  toDetail: (productId: any) => void;
}

const ProductListPresenter = (props: ProductListProps) => {
  return (
    <s.Container>
      <s.TopSelectArea>
        <s.TopItemWrapper>
          <s.TopSelectItem
            focused={props.keyword === ''}
            onClick={() => props.setKeyword('')}
          >
            전체보기
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '삼성전자'}
            onClick={() => props.setKeyword('삼성전자')}
          >
            삼성전자
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === 'LG전자'}
            onClick={() => props.setKeyword('LG전자')}
          >
            LG전자
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.keyword === '건강식품'}
            onClick={() => props.setKeyword('건강식품')}
          >
            건강식품
          </s.TopSelectItem>
          <s.TopRightArea>
            <s.KeywordSearchArea>
              <s.KeywordSearchInput
                type="text"
                placeholder="검색어를 입력하세요."
                value={props.searchKeyword}
                onChange={(e) => props.setSearchKeyword(e.target.value)}
                onKeyDown={(e) => props.checkSearchEnter(e)}
              />
              <s.KeywordSearchIcon onClick={() => props.searchByKeyword()}>
                <FaSearch color={theme.color.black} size={'100%'} />
              </s.KeywordSearchIcon>
            </s.KeywordSearchArea>
            <s.TopOrderBySelect
              value={props.orderBy}
              onChange={(e) => props.setOrderBy(e.target.value)}
            >
              <option value="regDtm">최신순</option>
              <option value="popular">인기순</option>
            </s.TopOrderBySelect>
          </s.TopRightArea>
        </s.TopItemWrapper>
      </s.TopSelectArea>
      <s.ContentArea>
        {props.banner1Preview &&
        props.banner2Preview &&
        props.banner3Preview ? (
          <s.BannerAreaWrapper>
            {isMobile ? null : (
              <>
                <s.BannerWave1 />
                <s.BannerWave2 />
                <s.BannerWave3 />
                <s.BannerWave4 />
              </>
            )}

            <s.BannerArea>
              <s.Banner1 onClick={() => props.setKeyword(props.banner1Keyword)}>
                <s.BannerImg src={props.banner1Preview} />
              </s.Banner1>
              <s.BannerRightWrapper>
                <s.Banner2
                  onClick={() => props.setKeyword(props.banner2Keyword)}
                >
                  <s.BannerImg src={props.banner2Preview} />
                </s.Banner2>
                <s.Banner2
                  onClick={() => props.setKeyword(props.banner3Keyword)}
                >
                  <s.BannerImg src={props.banner3Preview} />
                </s.Banner2>
              </s.BannerRightWrapper>
            </s.BannerArea>
          </s.BannerAreaWrapper>
        ) : null}
        <s.ProductArea>
          {props.productList.length > 0 ? (
            props.productList.map((product: any) => (
              <s.ProductItem onClick={() => props.toDetail(product.productId)}>
                <s.ProductImg src={product.thumbnail} />
                <s.ProductNm>{product.productNm}</s.ProductNm>
                <s.ProductPrice>
                  {priceToString(product.price)} 원
                </s.ProductPrice>
                <s.ProductDiscountPriceWrapper>
                  <s.ProductDiscountRate>
                    {Math.round(
                      ((product.price - product.priceDiscount) /
                        product.price) *
                        100,
                    )}
                    %
                  </s.ProductDiscountRate>
                  <s.ProductPriceDiscount>
                    <s.ProductPriceDiscountSpan>
                      {priceToString(product.priceDiscount)}
                    </s.ProductPriceDiscountSpan>
                    원
                  </s.ProductPriceDiscount>
                </s.ProductDiscountPriceWrapper>
              </s.ProductItem>
            ))
          ) : (
            <s.NoProductIndicator>검색된 상품이 없습니다.</s.NoProductIndicator>
          )}
        </s.ProductArea>
      </s.ContentArea>
      {props.productList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
    </s.Container>
  );
};

export default ProductListPresenter;
