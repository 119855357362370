import styled from 'styled-components';
import theme from 'components/styles/theme';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const BodyArea = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 800px) {
    overflow-x: auto;
  }
`;

export const OrderTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 2px solid ${theme.color.black};
  width: 100%;

  overflow-x: auto;
`;

export const OrderTbody = styled.tbody``;

export const OrderTr = styled.tr``;

export const OrderTh = styled.th`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: center;
  padding: 1rem;
  border-bottom: 1px solid ${theme.color.lightGrey};

  @media screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 1.2rem;
    width: auto;
    white-space: nowrap;
  }
`;

export const OrderTd = styled.td<{ align: string; isLast?: boolean }>`
  font-size: 1.2rem;
  text-align: ${(props) => props.align};
  padding: 2rem 1rem;
  border-bottom: 1px solid ${theme.color.lightGrey};
  border-right: ${(props) => (props.isLast ? '0' : '1px')} solid
    ${theme.color.lightGrey};
  font-weight: 500;

  @media screen and (max-width: 800px) {
    padding: 0.5rem;
    font-size: 1.2rem;
    white-space: nowrap;
  }
`;

export const OrderTdWrapper = styled.div<{ direction?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  gap: 0.5rem;
`;

export const OrderTdText = styled.div<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : theme.color.black)};
`;

export const OrderTdButton = styled.div<{ background?: string }>`
  padding: 0.5rem;
  background-color: ${(props) =>
    props.background ? props.background : theme.color.lightGrey};
  color: #ffffff;
  cursor: pointer;
`;

export const OrderTdImg = styled.img`
  width: 10rem;
  height: 10rem;
  object-fit: contain;

  width: 10%;
  height: 100%;
`;

export const OrderTdProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;

export const OrderTdProductNm = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.color.black};
`;

export const OrderTdModelNm = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.color.grey};

  @media screen and (max-width: 800px) {
    width: 100%;
    overflow: hidden;
  }
`;

export const OrderTdProductPrice = styled.div`
  margin-top: 3rem;
  font-weight: bold;
  font-size: 1.2rem;
  color: ${theme.color.black};
`;

export const NoItem = styled.div`
  width: 100%;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.8rem;
  color: ${theme.color.lightGrey};
`;

export const LinkButton = styled(Link)`
  width: 14rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.primary};
  border-radius: 5rem;
  cursor: pointer;
`;

export const PaginationWrapper = styled.div`
  width: 120rem;
  padding: 1rem 0;
  margin: 7rem auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const OrderDetailBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const PopupTitle = styled.div`
  font-size: 1.6rem;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid ${theme.color.black};
  color: ${theme.color.black};
  padding: 1rem 0;
  width: 100%:
`;

export const ClosePopupButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const OrderDetailPopup = styled.div`
  width: 100rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;
  gap: 1rem;
`;

export const OrderDetailTableTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  width: 100%;
  color: ${theme.color.black};
  margin-top: 3rem;
`;

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const OrderUpdatePopup = styled.div`
  width: 80rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;
  gap: 1rem;
`;

export const OrderPopupTableTitle = styled.div`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
`;

export const OrderPopupTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const OrderPopupTbody = styled.tbody``;

export const OrderPopupTr = styled.tr``;

export const OrderPopupTh = styled.th`
  width: 10rem;
  padding: 0.5rem;
  background-color: ${theme.color.darkGrey};
  text-align: left;
  font-size: 1.2rem;
  color: #ffffff;
  border: 1px solid ${theme.color.grey};
`;

export const OrderPopupTd = styled.td`
  width: 23.3rem;
  padding: 0.5rem;
  text-align: left;
  font-size: 1.2rem;
  color: ${theme.color.black};
  border: 1px solid ${theme.color.grey};
`;

export const GeneralInfoInput = styled.input<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const SaveButton = styled.div`
  padding: 1rem;
  width: 20rem;
  background-color: ${theme.color.darkGrey};
  cursor: pointer;
  color: #ffffff;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  margin: 1.5rem auto;
  font-weight: 600;
`;
