import React, { Dispatch, SetStateAction } from 'react';
import * as s from './SellerStyled';
import { Link } from 'react-router-dom';

import Order from './order';
import Product from './product';

import UserList from './user';
import Banner from './banner';

interface SellerProps {
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
  userInfo: any;
}

const SellerPresenter = (props: SellerProps) => {
  return (
    <s.Container>
      <s.TopSelectArea>
        <s.TopItemWrapper>
          <s.TopSelectItem
            focused={props.active === 0}
            onClick={() => props.setActive(0)}
          >
            주문/배송
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.active === 1}
            onClick={() => props.setActive(1)}
          >
            상품관리
          </s.TopSelectItem>
          <s.TopSelectItem
            focused={props.active === 2}
            onClick={() => props.setActive(2)}
          >
            게시글관리
          </s.TopSelectItem>
          {props.userInfo.userTypeCd === 'ADMIN' ||
          props.userInfo.userTypeCd === 'MASTERADMIN' ? (
            <>
              <s.TopSelectItem
                focused={props.active === 3}
                onClick={() => props.setActive(3)}
              >
                사용자관리
              </s.TopSelectItem>
              <s.TopSelectItem
                focused={props.active === 4}
                onClick={() => props.setActive(4)}
              >
                배너관리
              </s.TopSelectItem>
            </>
          ) : null}
        </s.TopItemWrapper>
      </s.TopSelectArea>
      <s.ContentArea>
        {props.active === 0 ? (
          <Order />
        ) : props.active === 1 ? (
          <Product />
        ) : props.active === 2 ? null : props.active === 3 ? (
          <UserList />
        ) : props.active === 4 ? (
          <Banner />
        ) : null}
      </s.ContentArea>
    </s.Container>
  );
};

export default SellerPresenter;
