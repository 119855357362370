import React, { useState, useEffect } from 'react';
import { makeMsg } from 'util/util';
import recoilItem from 'util/recoilitem';

import UserListPresenter from './UserListPresenter';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comnCdApi } from 'api/api-comncd';
import { userApi } from 'api/api-user';

const UserListContainer = () => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const pageOffsetList = [10, 50, 100, 300];

  const [pageOffset, setPageOffset] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>('');
  const [userStList, setUserStList] = useState<any[]>([]);
  const [userStCd, setUserStCd] = useState<string>('');
  const [userTypeList, setUserTypeList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [userList, setUserList] = useState<any[]>([]);

  useEffect(() => {
    getUserStList();
    getUserTypeList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  }, [pageOffset]);

  const getUserStList = async () => {
    try {
      const data = {
        upperComnCd: 'USRST',
      };

      const res = await comnCdApi.getList(data);

      if (res.data.rsltCd === '00') {
        setUserStList(res.data.data.comnCdList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const getUserTypeList = async () => {
    try {
      const data = {
        upperComnCd: 'USRTYPE',
      };

      const res = await comnCdApi.getList(data);

      if (res.data.rsltCd === '00') {
        setUserTypeList(res.data.data.comnCdList);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setUserList([]);
      setTotalCount(0);
      const data: any = {
        comId: comInfo.comId,
        page: page,
        pageOffset: pageOffset,
      };

      if (keyword) {
        data.keyword = keyword;
      }

      if (userStCd) {
        data.userStCd = userStCd;
      }

      const res = await userApi.getList(token, data);
      if (res.data.rsltCd === '00') {
        setUserList(res.data.data.userList);
        setTotalCount(res.data.data.totalCount);
      } else {
        setUserList([]);
        setTotalCount(0);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const searchData = () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const downloadExcel = async () => {
    makeMsg('준비중입니다.', 'warning');
  };

  const updateUser = async (user: any) => {
    try {
      setIsLoading(true);
      const data: any = {
        userId: user.userId,
        userTypeCd: user.userTypeCd,
        userStCd: user.userStCd,
      };

      if (user.newPassword?.replaceAll(' ', '')) {
        data.newPassword = user.newPassword;
      }

      const updateRes = await userApi.update(token, data);
      if (updateRes.data.rsltCd === '00') {
        makeMsg('수정되었습니다.', 'success');
        searchData();
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <UserListPresenter
        page={page}
        handlePageChange={handlePageChange}
        searchData={searchData}
        keyword={keyword}
        setKeyword={setKeyword}
        userStCd={userStCd}
        setUserStCd={setUserStCd}
        userStList={userStList}
        userTypeList={userTypeList}
        pageOffset={pageOffset}
        setPageOffset={setPageOffset}
        pageOffsetList={pageOffsetList}
        totalCount={totalCount}
        userList={userList}
        setUserList={setUserList}
        downloadExcel={downloadExcel}
        updateUser={updateUser}
      />
    </>
  );
};

UserListContainer.defaultProps = {};

export default UserListContainer;
