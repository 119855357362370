import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const SectionTitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: 500;
  margin-top: 2rem;
`;

export const SectionTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  position: relative;
`;

export const SectionTbody = styled.tbody``;

export const SectionTr = styled.tr<{ hoverActive?: boolean }>`
  color: ${theme.color.black};
  ${(props) =>
    props.hoverActive
      ? `&:hover {
    background-color: ${theme.color.primary};
    color: #ffffff;
  }`
      : null}
`;

export const SectionTh = styled.th<{
  center?: boolean;
  color?: string;
  noPad?: boolean;
}>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  color: ${(props) => (props.color ? props.color : null)};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  background-color: ${theme.color.grey};
  border: 1px solid ${theme.color.lightGrey};
`;

export const SectionTd = styled.td<{ noPad?: boolean; align?: string }>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.lightGrey};
  text-align: ${(props) => (props.align ? props.align : 'center')};
`;

export const SectionTdText = styled.div``;

export const SectionThSpan = styled.span`
  color: #ff0000;
`;

export const CategoryArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 1rem;
`;

export const CategoryItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const CategoryItem = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? theme.color.primary : null)};
  color: ${(props) => (props.selected ? '#ffffff' : null)};
  padding: 0.5rem;
  display: flex;
  cursor: pointer;
`;

export const AddCategoryButton = styled.div`
  padding: 1rem 8rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.darkGrey};
  cursor: pointer;
  margin: 0 auto;
  border-radius: 0.3rem;
`;

export const CategoryMainCheckbox = styled.input`
  width: 2rem;
  height: 2rem;
  border: 1px solid ${theme.color.lightGrey};
  border-radius: 0.5rem;
`;

export const CategoryListButton = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.primary};
  color: #ffffff;
  cursor: pointer;
  padding: 0.5rem;
`;

export const GeneralInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 2rem;
`;

export const GeneralInfoTdWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const GeneralInfoTdVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GeneralInfoInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

export const GeneralInfoRadio = styled.input``;

export const GeneralInfoLabel = styled.label`
  font-size: 1.4rem;
  color: ${theme.color.black};
`;

export const GeneralInfoInput = styled.input<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const GeneralInfoSelect = styled.select<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const GeneralInfoThumbnailPreview = styled.img`
  width: 95%;
  height: 30rem;
  object-fit: contain;
  margin: 1rem auto;
`;
export const QuillWrapper = styled.div`
  width: 95%;
  height: 50rem;
  margin: 0.5rem;
`;

export const OpenPopupButton = styled.div`
  width: 10rem;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  background-color: ${theme.color.orange};
  color: #ffffff;
  cursor: pointer;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  margin: 3rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const SaveButton = styled.div`
  padding: 1rem;
  width: 20rem;
  background-color: ${theme.color.darkGrey};
  cursor: pointer;
  color: #ffffff;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  font-weight: 600;
`;

export const PopupArea = styled.div`
  position: fixed;
  top: 10rem;
  left: 30%;
  width: 80rem;
  border: 1px solid ${theme.color.lightGrey};
  box-shadow: 0 0 0.3rem 0.3rem ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  background-color: #ffffff;
`;

export const ClosePopupButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const PopupTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  margin-bottom: 1rem;
`;

export const DataArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 2rem;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

export const RemoveOptionButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: -1rem;
  top: -1rem;

  cursor: pointer;
  background-color: #ffffff;
  border-radius: 50%;
`;
