import React, { Dispatch, SetStateAction } from 'react';
import * as s from './OrderStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { priceToString } from 'util/util';
import moment from 'moment';
import theme from 'components/styles/theme';
import { MdClose } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';

interface OrderProps {
  searchDv: string;
  setSearchDv: Dispatch<SetStateAction<string>>;
  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  page: number;
  handlePageChange: (page: number) => void;

  strDtm: string;
  setStrDtm: Dispatch<SetStateAction<string>>;
  endDtm: string;
  setEndDtm: Dispatch<SetStateAction<string>>;
  setSearchPeriod: (period: number) => void;
  categoryLv1: string;
  setCategoryLv1: Dispatch<SetStateAction<string>>;
  categoryLv2: string;
  setCategoryLv2: Dispatch<SetStateAction<string>>;
  categoryLv3: string;
  setCategoryLv3: Dispatch<SetStateAction<string>>;
  categoryLv4: string;
  setCategoryLv4: Dispatch<SetStateAction<string>>;
  categoryLv1List: any[];
  categoryLv2List: any[];
  categoryLv3List: any[];
  categoryLv4List: any[];
  orderStCdList: any[];
  checkOrderStCd: (index: number, setAll?: boolean) => void;
  searchData: () => void;
  orderList: any[];
  totalCount: number;
  checkOrder: (index: number, setAll?: boolean) => void;

  showPopup: boolean;
  popupDv: number;
  setPopupDv: Dispatch<SetStateAction<number>>;
  selectedOrder: any;
  setSelectedOrder: Dispatch<SetStateAction<any>>;
  pageOffset: number;
  setPageOffset: Dispatch<SetStateAction<number>>;
  pageOffsetList: number[];
  updateOrderSt: (fromCd: string[], toCd: string) => void;
  userInfo: any;

  showUpdatePopup: boolean;
  setShowUpdatePopup: Dispatch<SetStateAction<boolean>>;
  cancelRsn: string;
  setCancelRsn: Dispatch<SetStateAction<string>>;
  cancelBankAccount: string;
  setCancelBankAccount: Dispatch<SetStateAction<string>>;
  cancelBankName: string;
  setCancelBankName: Dispatch<SetStateAction<string>>;
  cancelBankHolderName: string;
  setCancelBankHolderName: Dispatch<SetStateAction<string>>;
  deliveryComNm: string;
  setDeliveryComNm: Dispatch<SetStateAction<string>>;
  deliveryNum: string;
  setDeliveryNum: Dispatch<SetStateAction<string>>;
  toCd: string;
  updateOrder: (to?: string) => void;

  getMyOrderOnly: boolean;
  setGetMyOrderOnly: Dispatch<SetStateAction<boolean>>;
  handleCopyClipBoard: (text: string) => void;
}

const OrderPresenter = (props: OrderProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>주문리스트</s.PageTitle>
        <s.PageIndicator>{'주문/배송관리 > 주문관리'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.SearchArea>
        <s.SectionTable style={{ width: '100%' }}>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>검색조건</s.SectionTh>
              <s.SectionTd noPad align="left" width="85%">
                <s.GeneralInfoSelect
                  width={'20rem'}
                  value={props.searchDv}
                  onChange={(e) => props.setSearchDv(e.target.value)}
                >
                  <option value="userNm">주문자명</option>
                  <option value="receiverNm">수령자명</option>
                  <option value="orderId">주문번호</option>
                  <option value="receiverMobno">수령자전화번호</option>
                </s.GeneralInfoSelect>
                <s.GeneralInfoInput
                  type="text"
                  placeholder="검색어를 입력하세요."
                  value={props.searchKeyword}
                  onChange={(e) => props.setSearchKeyword(e.target.value)}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>주문일 기간</s.SectionTh>
              <s.SectionTd noPad align="left" width="85%">
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  <s.GeneralInfoInput
                    type="date"
                    width="14rem"
                    value={props.strDtm}
                    onChange={(e) => props.setStrDtm(e.target.value)}
                  />
                  ~
                  <s.GeneralInfoInput
                    type="date"
                    width="14rem"
                    value={props.endDtm}
                    onChange={(e) => props.setEndDtm(e.target.value)}
                  />
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(0)}
                  >
                    당일
                  </s.GeneralInfoDateButton>
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(3)}
                  >
                    3일
                  </s.GeneralInfoDateButton>
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(7)}
                  >
                    7일
                  </s.GeneralInfoDateButton>
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(30)}
                  >
                    30일
                  </s.GeneralInfoDateButton>
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(60)}
                  >
                    60일
                  </s.GeneralInfoDateButton>
                  <s.GeneralInfoDateButton
                    onClick={() => props.setSearchPeriod(90)}
                  >
                    90일
                  </s.GeneralInfoDateButton>
                </s.GeneralInfoInputWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>카테고리</s.SectionTh>
              <s.SectionTd align="left" noPad width="80%">
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  <s.GeneralInfoSelect
                    value={props.categoryLv1}
                    onChange={(e) => props.setCategoryLv1(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv1List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv2}
                    onChange={(e) => props.setCategoryLv2(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv2List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv3}
                    onChange={(e) => props.setCategoryLv3(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv3List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv4}
                    onChange={(e) => props.setCategoryLv4(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv4List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                </s.GeneralInfoInputWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>주문상태</s.SectionTh>
              <s.SectionTd align="left" width="85%">
                <s.GeneralInfoCheckbox
                  type="checkbox"
                  checked={
                    props.orderStCdList.length ===
                    props.orderStCdList.filter((item: any) => item.checked)
                      .length
                  }
                  onClick={() =>
                    props.checkOrderStCd(
                      -1,
                      props.orderStCdList.length !==
                        props.orderStCdList.filter((item: any) => item.checked)
                          .length,
                    )
                  }
                />
                <s.GeneralInfoCheckboxLabel>전체</s.GeneralInfoCheckboxLabel>
                {props.orderStCdList.map((item: any, index: number) => (
                  <>
                    <s.GeneralInfoCheckbox
                      type="checkbox"
                      checked={item.checked}
                      onClick={() => props.checkOrderStCd(index)}
                    />
                    <s.GeneralInfoCheckboxLabel>
                      {item.comnCdNm}
                    </s.GeneralInfoCheckboxLabel>
                  </>
                ))}
              </s.SectionTd>
            </s.SectionTr>
            {props.userInfo.userTypeCd === 'MASTERADMIN' ||
            props.userInfo.userTypeCd === 'ADMIN' ? (
              <s.SectionTr>
                <s.SectionTh style={{ width: '15%' }}>전체주문보기</s.SectionTh>
                <s.SectionTd align="left" width="85%">
                  <s.GeneralInfoCheckbox
                    type="checkbox"
                    checked={!props.getMyOrderOnly}
                    onClick={() =>
                      props.setGetMyOrderOnly(!props.getMyOrderOnly)
                    }
                  />
                </s.SectionTd>
              </s.SectionTr>
            ) : null}
          </s.SectionTbody>
        </s.SectionTable>
        <s.SaveButton onClick={() => props.searchData()}>조회</s.SaveButton>
      </s.SearchArea>
      <s.PageOffsetArea>
        <s.ButtonWrapper>
          상태변경 :
          <s.OrderStButton
            active={
              props.orderList.filter((item: any) => item.checked).length > 0
            }
            onClick={() =>
              props.updateOrderSt(['ORDST01', 'ORDST02'], 'ORDST11')
            }
          >
            배송준비중
          </s.OrderStButton>
          <s.OrderStButton
            active={
              props.orderList.filter((item: any) => item.checked).length > 0
            }
            onClick={() => props.updateOrderSt(['ORDST11'], 'ORDST12')}
          >
            배송중
          </s.OrderStButton>
          <s.OrderStButton
            active={
              props.orderList.filter((item: any) => item.checked).length > 0
            }
            onClick={() => props.updateOrderSt(['ORDST12'], 'ORDST13')}
          >
            배송완료
          </s.OrderStButton>
          <s.OrderStButton
            active={
              props.orderList.filter((item: any) => item.checked).length === 1
            }
            onClick={() =>
              props.updateOrderSt(
                ['ORDST11', 'ORDST12', 'ORDST13', 'ORDST01', 'ORDST02'],
                'ORDST92',
              )
            }
          >
            주문취소요청
          </s.OrderStButton>
          <s.OrderStButton
            active={
              props.orderList.filter((item: any) => item.checked).length > 0
            }
            onClick={() => props.updateOrderSt(['ORDST91'], 'ORDST92')}
          >
            입점사취소승인
          </s.OrderStButton>
          {props.userInfo.userTypeCd === 'MASTERADMIN' ||
          props.userInfo.userTypeCd === 'ADMIN' ? (
            <s.OrderStButton
              active={
                props.orderList.filter((item: any) => item.checked).length > 0
              }
              onClick={() => props.updateOrderSt(['ORDST92'], 'ORDST93')}
            >
              마스터취소승인
            </s.OrderStButton>
          ) : null}
        </s.ButtonWrapper>
        <s.SectionTable style={{ width: '25rem' }}>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '12rem' }}>조회수량</s.SectionTh>
              <s.SectionTd width="13rem" noPad>
                <s.GeneralInfoSelect
                  value={props.pageOffset}
                  onChange={(e) => props.setPageOffset(Number(e.target.value))}
                  width="12rem"
                >
                  {props.pageOffsetList.map((offset: number) => (
                    <option value={offset}>{offset}건 씩</option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.PageOffsetArea>
      <s.DataArea>
        <s.SectionTable
          style={{ width: '130rem', display: 'block', overflowX: 'auto' }}
        >
          <s.SectionTbody style={{ tableLayout: 'unset', overflowX: 'auto' }}>
            <s.SectionTr style={{ tableLayout: 'unset' }}>
              <s.SectionTh
                center
                style={{ minWidth: '5rem', tableLayout: 'unset' }}
              >
                <s.GeneralInfoCheckbox
                  type="checkbox"
                  checked={
                    props.orderList.length ===
                    props.orderList.filter((item: any) => item.checked).length
                  }
                  onClick={() =>
                    props.checkOrder(
                      -1,
                      props.orderList.length !==
                        props.orderList.filter((item: any) => item.checked)
                          .length,
                    )
                  }
                />
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                주문번호
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                주문일시
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                구매확정일시
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '10rem', tableLayout: 'unset' }}
              >
                주문상태
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '10rem', tableLayout: 'unset' }}
              >
                구매자명
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '12rem', tableLayout: 'unset' }}
              >
                상품번호
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '25rem', tableLayout: 'unset' }}
              >
                상품명
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '25rem', tableLayout: 'unset' }}
              >
                옵션
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '10rem', tableLayout: 'unset' }}
              >
                수량
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                판매단가
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                공급단가
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                총금액
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '15rem', tableLayout: 'unset' }}
              >
                배송비
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '10rem', tableLayout: 'unset' }}
              >
                수령인
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '12rem', tableLayout: 'unset' }}
              >
                수령인번호
              </s.SectionTh>
              <s.SectionTh
                center
                style={{ minWidth: '50rem', tableLayout: 'unset' }}
              >
                배송지
              </s.SectionTh>
            </s.SectionTr>
            {props.orderList.length < 1 ? (
              <s.SectionTr style={{ tableLayout: 'unset' }}>
                <s.SectionTd
                  colSpan={16}
                  style={{
                    height: '50rem',
                    textAlign: 'left',
                    paddingLeft: '55rem',
                    fontSize: '3rem',
                    color: theme.color.lightGrey,
                  }}
                >
                  조회값이 없습니다.
                </s.SectionTd>
              </s.SectionTr>
            ) : (
              props.orderList.map((order: any, index: number) => (
                <s.SectionTr hoverActive style={{ tableLayout: 'unset' }}>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                  >
                    <s.GeneralInfoCheckbox
                      type="checkbox"
                      checked={order.checked}
                      onClick={() => props.checkOrder(index)}
                    />
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.orderNum}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {moment(order.regDtm).format('YYYY-MM-DD HH:mm:ss')}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.purchaseConfirmDtm
                      ? moment(order.purchaseConfirmDtm).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )
                      : ''}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.orderStNm}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.buyerNm}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.productId}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                      textAlign: 'left',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.productNm}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                      textAlign: 'left',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.optionNm}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {priceToString(order.count)} EA
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {priceToString(order.priceDiscount + order.optionPrice)} 원
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {priceToString(order.priceSupply + order.optionPriceSupply)}{' '}
                    원
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {priceToString(
                      (order.priceDiscount + order.optionPrice) * order.count,
                    )}{' '}
                    원
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {priceToString(order.priceDelivery)}원
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.receiverNm}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {order.receiverMobno}
                  </s.SectionTd>
                  <s.SectionTd
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      padding: '2rem 1rem',
                      textAlign: 'left',
                    }}
                    onClick={() => props.setSelectedOrder(order)}
                  >
                    {`(${order.deliveryZipcode}) ${order.deliveryAddr}`}
                  </s.SectionTd>
                </s.SectionTr>
              ))
            )}
          </s.SectionTbody>
        </s.SectionTable>
      </s.DataArea>

      {props.orderList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}

      {props.showPopup ? (
        <s.PopupBackground>
          <s.OrderDetailPopup>
            <s.ClosePopupButton onClick={() => props.setSelectedOrder(null)}>
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>주문상세</s.PopupTitle>
            <s.OrderDetailFilterWrapper>
              <s.OrderDetailFilterItem
                onClick={() => props.setPopupDv(1)}
                active={props.popupDv === 1}
              >
                주문정보
              </s.OrderDetailFilterItem>
              <s.OrderDetailFilterItem
                onClick={() => props.setPopupDv(2)}
                active={props.popupDv === 2}
              >
                취소/반품/교환 정보
              </s.OrderDetailFilterItem>
            </s.OrderDetailFilterWrapper>
            {props.popupDv === 1 ? (
              <>
                <s.OrderPopupTableTitle>주문기본정보</s.OrderPopupTableTitle>
                <s.OrderPopupTable>
                  <s.OrderPopupTbody>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>주문번호</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.orderNum}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>구매자</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.buyerNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>구매자연락처</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.buyerMobno}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>주문일시</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {moment(props.selectedOrder?.orderDtm).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>수령자</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.receiverNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>수령자 연락처</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.receiverMobno}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>배송지 주소</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={5}>
                        {`(${props.selectedOrder?.deliveryZipcode}) ${props.selectedOrder?.deliveryAddr}`}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    {props.userInfo.userTypeCd === 'MASTERADMIN' ||
                    props.userInfo.userTypeCd === 'ADMIN' ? (
                      <s.OrderPopupTr>
                        <s.OrderPopupTh>가맹점번호</s.OrderPopupTh>
                        <s.OrderPopupTd colSpan={5}>
                          {props.selectedOrder?.merchantUid}
                          <FaCopy
                            style={{ marginLeft: '0.5rem' }}
                            size={'1.5rem'}
                            color={theme.color.secondary}
                            onClick={() =>
                              props.handleCopyClipBoard(
                                props.selectedOrder?.merchantUid,
                              )
                            }
                          />
                        </s.OrderPopupTd>
                      </s.OrderPopupTr>
                    ) : null}
                  </s.OrderPopupTbody>
                </s.OrderPopupTable>

                <s.OrderPopupTableTitle>주문상품정보</s.OrderPopupTableTitle>
                <s.OrderPopupTable>
                  <s.OrderPopupTbody>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>상품명</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={3}>
                        {props.selectedOrder?.productNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>옵션명</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.optionNm}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>주문상태</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.orderStNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>상품번호</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={3}>
                        {props.selectedOrder?.productId}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>정상가</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(props.selectedOrder?.priceDiscount)} 원
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>옵션가</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(props.selectedOrder?.optionPrice)} 원
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>객단가</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(
                          props.selectedOrder?.priceDiscount +
                            props.selectedOrder?.optionPrice,
                        )}{' '}
                        원
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>주문수량</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(props.selectedOrder?.count)} EA
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>상품총액</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(
                          (props.selectedOrder?.priceDiscount +
                            props.selectedOrder?.optionPrice) *
                            props.selectedOrder?.count,
                        )}{' '}
                        원
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>할인액</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(
                          props.selectedOrder?.discountAmount || '0',
                        )}{' '}
                        원
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>최종주문금액</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={5}>
                        {props.selectedOrder?.discountAmount
                          ? priceToString(
                              props.selectedOrder?.priceDiscount +
                                props.selectedOrder?.optionPrice -
                                props.selectedOrder?.discountAmount,
                            )
                          : priceToString(
                              (props.selectedOrder?.priceDiscount +
                                props.selectedOrder?.optionPrice) *
                                props.selectedOrder?.count,
                            )}{' '}
                        원
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>기본배송비</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(props.selectedOrder?.priceDelivery)} 원
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>배송비(제주)</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(
                          props.selectedOrder?.additionalPriceJeju,
                        )}{' '}
                        원
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>배송비(도서)</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {priceToString(
                          props.selectedOrder?.additionalPriceMountain,
                        )}{' '}
                        원
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>배송요청사항</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={5}>
                        {props.selectedOrder?.orderRequest}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>배송사</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.deliveryComNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>송장번호</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={4}>
                        {props.selectedOrder?.deliveryNum}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                  </s.OrderPopupTbody>
                </s.OrderPopupTable>
              </>
            ) : props.popupDv === 2 ? (
              <>
                <s.OrderPopupTableTitle>취소/반품 정보</s.OrderPopupTableTitle>
                <s.OrderPopupTable>
                  <s.OrderPopupTbody>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>요청자</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.cancelUserNm}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>요청사유</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={3}>
                        {props.selectedOrder?.cancelRsn}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>취소요청일시</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.cancelReqDtm
                          ? moment(props.selectedOrder?.cancelReqDtm).format(
                              'YYYY-MM-DD HH:mm:ss',
                            )
                          : null}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>결제방식</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.paymentCd !== 'V'
                          ? '카드'
                          : '가상계좌'}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>요청자 연락처</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.cancelUserMobno}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>환불받을 분</s.OrderPopupTh>
                      <s.OrderPopupTd>
                        {props.selectedOrder?.cancelBankHolderName}
                      </s.OrderPopupTd>
                      <s.OrderPopupTh>환불계좌은행</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={3}>
                        {props.selectedOrder?.cancelBankName}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh>환불계좌번호</s.OrderPopupTh>
                      <s.OrderPopupTd colSpan={5}>
                        {props.selectedOrder?.cancelBankAccount}
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                  </s.OrderPopupTbody>
                </s.OrderPopupTable>
              </>
            ) : null}
          </s.OrderDetailPopup>
        </s.PopupBackground>
      ) : null}
      {props.showUpdatePopup ? (
        <s.PopupBackground>
          <s.OrderUpdatePopup>
            <s.ClosePopupButton onClick={() => props.setShowUpdatePopup(false)}>
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>주문상태변경</s.PopupTitle>
            <s.OrderPopupTable>
              <s.OrderPopupTbody>
                {props.toCd === 'ORDST12' ? (
                  <>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh style={{ width: '20rem' }}>
                        배송사명
                      </s.OrderPopupTh>
                      <s.OrderPopupTd style={{ width: '56rem' }}>
                        <s.GeneralInfoInput
                          type="text"
                          placeholder="실제 배송사를 입력하세요"
                          value={props.deliveryComNm}
                          onChange={(e) =>
                            props.setDeliveryComNm(e.target.value)
                          }
                          maxLength={50}
                        />
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh style={{ width: '20rem' }}>
                        송장번호
                      </s.OrderPopupTh>
                      <s.OrderPopupTd style={{ width: '56rem' }}>
                        <s.GeneralInfoInput
                          type="text"
                          placeholder="송장번호를 입력하세요"
                          value={props.deliveryNum}
                          onChange={(e) => props.setDeliveryNum(e.target.value)}
                          maxLength={50}
                        />
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                  </>
                ) : props.toCd === 'ORDST92' ? (
                  <>
                    <s.OrderPopupTr>
                      <s.OrderPopupTh style={{ width: '20rem' }}>
                        취소사유
                      </s.OrderPopupTh>
                      <s.OrderPopupTd style={{ width: '56rem' }}>
                        <s.GeneralInfoInput
                          type="text"
                          placeholder="취소사유를 입력하세요"
                          value={props.cancelRsn}
                          onChange={(e) => props.setCancelRsn(e.target.value)}
                          maxLength={100}
                        />
                      </s.OrderPopupTd>
                    </s.OrderPopupTr>
                    {props.orderList.filter((item: any) => item.checked)[0]
                      .paymentCd === 'V' ? (
                      <>
                        <s.OrderPopupTr>
                          <s.OrderPopupTh style={{ width: '20rem' }}>
                            환불은행
                          </s.OrderPopupTh>
                          <s.OrderPopupTd style={{ width: '56rem' }}>
                            <s.GeneralInfoInput
                              type="text"
                              placeholder="환불은행을 입력하세요"
                              value={props.cancelBankName}
                              onChange={(e) =>
                                props.setCancelBankName(e.target.value)
                              }
                              maxLength={50}
                            />
                          </s.OrderPopupTd>
                        </s.OrderPopupTr>
                        <s.OrderPopupTr>
                          <s.OrderPopupTh style={{ width: '20rem' }}>
                            환불계좌번호
                          </s.OrderPopupTh>
                          <s.OrderPopupTd style={{ width: '56rem' }}>
                            <s.GeneralInfoInput
                              type="text"
                              placeholder="계좌번호를 입력하세요"
                              value={props.cancelBankAccount}
                              onChange={(e) =>
                                props.setCancelBankAccount(e.target.value)
                              }
                              maxLength={50}
                            />
                          </s.OrderPopupTd>
                        </s.OrderPopupTr>
                        <s.OrderPopupTr>
                          <s.OrderPopupTh style={{ width: '20rem' }}>
                            환불계좌소유자
                          </s.OrderPopupTh>
                          <s.OrderPopupTd style={{ width: '56rem' }}>
                            <s.GeneralInfoInput
                              type="text"
                              placeholder="계좌소유자명을 입력하세요"
                              value={props.cancelBankHolderName}
                              onChange={(e) =>
                                props.setCancelBankHolderName(e.target.value)
                              }
                              maxLength={50}
                            />
                          </s.OrderPopupTd>
                        </s.OrderPopupTr>
                      </>
                    ) : null}
                  </>
                ) : null}
              </s.OrderPopupTbody>
            </s.OrderPopupTable>
            <s.SaveButton onClick={() => props.updateOrder()}>
              변경
            </s.SaveButton>
          </s.OrderUpdatePopup>
        </s.PopupBackground>
      ) : null}
    </s.Container>
  );
};

export default OrderPresenter;
