import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 130rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const SectionTitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: 500;
  margin-top: 2rem;
`;

export const SectionTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const SectionTbody = styled.tbody``;

export const SectionTr = styled.tr<{ hoverActive?: boolean }>`
  color: ${theme.color.black};
  ${(props) =>
    props.hoverActive
      ? `&:hover {
    background-color: ${theme.color.primary};
    color: #ffffff;
  }`
      : null}
`;

export const SectionTh = styled.th<{
  center?: boolean;
  color?: string;
  noPad?: boolean;
}>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  color: ${(props) => (props.color ? props.color : null)};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  background-color: ${theme.color.grey};
  border: 1px solid ${theme.color.lightGrey};
`;

export const SectionTd = styled.td<{ noPad?: boolean; align?: string }>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  border: 1px solid ${theme.color.lightGrey};
  text-align: ${(props) => (props.align ? props.align : 'center')};
`;

export const SectionTdText = styled.div``;

export const SectionThSpan = styled.span`
  color: #ff0000;
`;

export const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 2rem;
`;

export const DataArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 2rem;
  min-height: 50rem;
`;

export const GeneralInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 2rem;
`;

export const GeneralInfoTdWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const GeneralInfoTdVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GeneralInfoInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

export const GeneralInfoRadio = styled.input``;

export const GeneralInfoLabel = styled.label`
  font-size: 1.4rem;
  color: ${theme.color.black};
`;

export const GeneralInfoInput = styled.input<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const GeneralInfoDateButton = styled.div`
  width: 6rem;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-size: 1.2rem;
  background-color: ${theme.color.grey};
  color: #ffffff;
  cursor: pointer;
`;

export const GeneralInfoThumbnailPreview = styled.img`
  width: 95%;
  height: 30rem;
  object-fit: contain;
  margin: 1rem auto;
`;
export const QuillWrapper = styled.div`
  width: 95%;
  height: 50rem;
  margin: 0.5rem;
`;

export const SaveButton = styled.div`
  padding: 1rem;
  width: 20rem;
  background-color: ${theme.color.darkGrey};
  cursor: pointer;
  color: #ffffff;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  margin: 1.5rem auto;
  font-weight: 600;
`;

export const GeneralInfoSelect = styled.select<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

export const GeneralInfoCheckbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  border: 1px solid ${theme.color.lightGrey};

  &:checked {
    background-color: ${theme.color.secondary};
    border: 1px solid ${theme.color.primary};
  }
`;

export const GeneralInfoCheckboxLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${theme.color.black};
  margin: 0 1.5rem 0 0.5rem;
`;

export const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const OrderDetailPopup = styled.div`
  width: 100rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;
  gap: 1rem;
`;

export const OrderDetailFilterWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.lightGrey};
  display: flex;
  gap: 0.5rem;
`;

export const OrderDetailFilterItem = styled.div<{ active: boolean }>`
  border-radius: 0.5rem 0.5rem 0 0;
  border-top: 1px solid ${theme.color.lightGrey};
  border-left: 1px solid ${theme.color.lightGrey};
  border-right: 1px solid ${theme.color.lightGrey};
  background-color: ${(props) =>
    props.active ? theme.color.primary : theme.color.veryLightGrey};
  color: ${(props) => (props.active ? '#ffffff' : theme.color.black)};
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  width: 15rem;
  font-size: 1.4rem;
  font-weight: bold;
`;

export const OrderPopupTableTitle = styled.div`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
`;

export const OrderPopupTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const OrderPopupTbody = styled.tbody``;

export const OrderPopupTr = styled.tr``;

export const OrderPopupTh = styled.th`
  width: 10rem;
  padding: 0.5rem;
  background-color: ${theme.color.darkGrey};
  text-align: left;
  font-size: 1.2rem;
  color: #ffffff;
  border: 1px solid ${theme.color.grey};
`;

export const OrderPopupTd = styled.td`
  width: 23.3rem;
  padding: 0.5rem;
  text-align: left;
  font-size: 1.2rem;
  color: ${theme.color.black};
  border: 1px solid ${theme.color.grey};
`;

export const PopupTitle = styled.div`
  font-size: 1.6rem;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid ${theme.color.black};
  color: ${theme.color.black};
  padding: 1rem 0;
  width: 100%:
`;

export const ClosePopupButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const PageOffsetArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 0.5rem 0;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-right: 2rem;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: ${theme.color.black};
  font-weight: bold;
`;

export const OrderStButton = styled.div<{ active: boolean }>`
  width: 10rem;
  padding: 1rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  background-color: ${(props) =>
    props.active ? theme.color.primary : theme.color.lightGrey};
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  border-radius: 0.5rem;
  box-shadow: 0 0 0.1rem 0.1rem ${theme.color.veryLightGrey};
`;

export const OrderUpdatePopup = styled.div`
  width: 80rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;
  gap: 1rem;
`;
