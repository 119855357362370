import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProductListStyled';
import { Link } from 'react-router-dom';
import { FaFileExcel } from 'react-icons/fa';
import Pagination from 'react-js-pagination';
import { priceToString } from 'util/util';
import moment from 'moment';
import 'components/styles/Paging.css';

interface ProductListProps {
  page: number;
  handlePageChange: (page: number) => void;
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  categoryLv1: string;
  setCategoryLv1: Dispatch<SetStateAction<string>>;
  categoryLv2: string;
  setCategoryLv2: Dispatch<SetStateAction<string>>;
  categoryLv3: string;
  setCategoryLv3: Dispatch<SetStateAction<string>>;
  categoryLv4: string;
  setCategoryLv4: Dispatch<SetStateAction<string>>;
  categoryLv1List: any[];
  categoryLv2List: any[];
  categoryLv3List: any[];
  categoryLv4List: any[];
  sellYn: string;
  setSellYn: Dispatch<SetStateAction<string>>;
  totalCount: number;
  productList: any[];

  goToUpdate: (product: any) => void;
  searchData: () => void;
  pageOffset: number;
  setPageOffset: Dispatch<SetStateAction<number>>;
  pageOffsetList: number[];
  downloadExcel: () => void;
}

const ProductListPresenter = (props: ProductListProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>상품목록</s.PageTitle>
        <s.PageIndicator>{'상품관리 > 상품목록'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.GeneralInfoArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>키워드 검색</s.SectionTh>
              <s.SectionTd width="80%" align="left" noPad>
                <s.GeneralInfoInput
                  type="text"
                  value={props.keyword}
                  onChange={(e) => props.setKeyword(e.target.value)}
                />
              </s.SectionTd>
              <s.SectionTh style={{ width: '10%' }} rowSpan={3}>
                <s.SearchButton onClick={() => props.searchData()}>
                  검색
                </s.SearchButton>
                <s.ExcelButton onClick={() => props.downloadExcel()}>
                  <FaFileExcel color={'#78DD78'} size={'2rem'} />
                  엑셀
                </s.ExcelButton>
              </s.SectionTh>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>카테고리</s.SectionTh>
              <s.SectionTd align="left" noPad width="80%">
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  <s.GeneralInfoSelect
                    value={props.categoryLv1}
                    onChange={(e) => props.setCategoryLv1(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv1List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv2}
                    onChange={(e) => props.setCategoryLv2(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv2List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv3}
                    onChange={(e) => props.setCategoryLv3(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv3List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                  <s.GeneralInfoSelect
                    value={props.categoryLv4}
                    onChange={(e) => props.setCategoryLv4(e.target.value)}
                    width="20rem"
                  >
                    <option value="">전체</option>
                    {props.categoryLv4List.map((category: any) => (
                      <option value={category.categoryCd}>
                        {category.categoryNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                </s.GeneralInfoInputWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>판매여부</s.SectionTh>
              <s.SectionTd align="left" width="80%">
                <s.GeneralInfoTdWrapper>
                  <s.GeneralInfoInputWrapper>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={!props.sellYn}
                      onChange={() => props.setSellYn('')}
                    />
                    <s.GeneralInfoLabel>전체</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'Y'}
                      onChange={() => props.setSellYn('Y')}
                    />
                    <s.GeneralInfoLabel>판매중</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'N'}
                      onChange={() => props.setSellYn('N')}
                    />
                    <s.GeneralInfoLabel>판매안함</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'R'}
                      onChange={() => props.setSellYn('R')}
                    />
                    <s.GeneralInfoLabel>품절</s.GeneralInfoLabel>
                  </s.GeneralInfoInputWrapper>
                </s.GeneralInfoTdWrapper>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.GeneralInfoArea>
      <s.PageOffsetArea>
        <s.SectionTable style={{ width: '25rem' }}>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '12rem' }}>조회수량</s.SectionTh>
              <s.SectionTd width="13rem" noPad>
                <s.GeneralInfoSelect
                  value={props.pageOffset}
                  onChange={(e) => props.setPageOffset(Number(e.target.value))}
                  width="12rem"
                >
                  {props.pageOffsetList.map((offset: number) => (
                    <option value={offset}>{offset}건 씩</option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.PageOffsetArea>

      <s.DataArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh center style={{ width: '8rem' }}>
                이미지
              </s.SectionTh>
              <s.SectionTh center style={{ width: '10%' }}>
                몰
              </s.SectionTh>
              <s.SectionTh center style={{ width: '7%' }}>
                판매상태
              </s.SectionTh>
              <s.SectionTh center style={{ width: '7%' }}>
                Best노출
              </s.SectionTh>
              <s.SectionTh center style={{ width: '7%' }}>
                입점사
              </s.SectionTh>
              <s.SectionTh center style={{ width: '25%' }}>
                상품명
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                출하가
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                판매가
              </s.SectionTh>
              <s.SectionTh center style={{ width: '8%' }}>
                공급가
              </s.SectionTh>
              <s.SectionTh center style={{ width: '10%' }}>
                등록일
              </s.SectionTh>
            </s.SectionTr>
            {props.productList.length < 1 ? (
              <s.SectionTr>
                <s.SectionTd colSpan={10} style={{ height: '50rem' }}>
                  조회값이 없습니다.
                </s.SectionTd>
              </s.SectionTr>
            ) : (
              props.productList.map((product: any) => (
                <s.SectionTr
                  onClick={() => props.goToUpdate(product)}
                  hoverActive
                >
                  <s.SectionTd>
                    <s.SectionTdImg src={product.thumbnail} />
                  </s.SectionTd>
                  <s.SectionTd>{product.comNm}</s.SectionTd>
                  <s.SectionTd>
                    {product.sellYn === 'Y'
                      ? '판매중'
                      : product.sellYn === 'N'
                      ? '판매안함'
                      : product.sellYn === 'R'
                      ? '품절'
                      : ''}
                  </s.SectionTd>
                  <s.SectionTd>{product.bestYn}</s.SectionTd>
                  <s.SectionTd>{product.userNm}</s.SectionTd>
                  <s.SectionTd>{product.productNm}</s.SectionTd>
                  <s.SectionTd>{priceToString(product.price)}</s.SectionTd>
                  <s.SectionTd>
                    {priceToString(product.priceDiscount)}
                  </s.SectionTd>
                  <s.SectionTd>
                    {priceToString(product.priceSupply)}
                  </s.SectionTd>
                  <s.SectionTd>
                    {moment(product.regDtm).format('YYYY-MM-DD HH:mm')}
                  </s.SectionTd>
                </s.SectionTr>
              ))
            )}
          </s.SectionTbody>
        </s.SectionTable>
      </s.DataArea>
      {props.productList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
    </s.Container>
  );
};

export default ProductListPresenter;
