import { categoryApi } from 'api/api-category';
import { comnCdApi } from 'api/api-comncd';
import { orderApi } from 'api/api-order';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import recoilitem from 'util/recoilitem';
import useCopyClipBoard from 'util/useCopyClipboard';
import { makeMsg } from 'util/util';

import OrderPresenter from './OrderPresenter';

const OrderContainer = () => {
  const token = useRecoilValue(recoilitem.token);
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const comInfo = useRecoilValue(recoilitem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilitem.isLoading);

  const pageOffsetList = [10, 50, 100, 300];

  const [pageOffset, setPageOffset] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [orderList, setOrderList] = useState<any[]>([]);
  const [searchDv, setSearchDv] = useState<string>('userNm');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [strDtm, setStrDtm] = useState<string>(
    moment().add('-1', 'months').format('YYYY-MM-DD'),
  );
  const [endDtm, setEndDtm] = useState<string>(moment().format('YYYY-MM-DD'));

  const [categoryLv1, setCategoryLv1] = useState<string>('');
  const [categoryLv2, setCategoryLv2] = useState<string>('');
  const [categoryLv3, setCategoryLv3] = useState<string>('');
  const [categoryLv4, setCategoryLv4] = useState<string>('');

  const [categoryLv1List, setCategoryLv1List] = useState<any[]>([]);
  const [categoryLv2List, setCategoryLv2List] = useState<any[]>([]);
  const [categoryLv3List, setCategoryLv3List] = useState<any[]>([]);
  const [categoryLv4List, setCategoryLv4List] = useState<any[]>([]);
  const [orderStCdList, setOrderStCdList] = useState<any[]>([]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupDv, setPopupDv] = useState<number>(1);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);

  const [fromCd, setFromCd] = useState<string[]>([]);
  const [toCd, setToCd] = useState<string>('');

  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false);
  const [cancelRsn, setCancelRsn] = useState<string>('');
  const [cancelBankAccount, setCancelBankAccount] = useState<string>('');
  const [cancelBankName, setCancelBankName] = useState<string>('');
  const [cancelBankHolderName, setCancelBankHolderName] = useState<string>('');
  const [deliveryComNm, setDeliveryComNm] = useState<string>('');
  const [deliveryNum, setDeliveryNum] = useState<string>('');
  const [getMyOrderOnly, setGetMyOrderOnly] = useState<boolean>(true);

  const [isCopy, onCopy] = useCopyClipBoard();

  useEffect(() => {
    getCategoryList(null, 1);
    getOrderStCdList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  }, [pageOffset]);

  useEffect(() => {
    if (!showUpdatePopup) {
      setFromCd([]);
      setToCd('');
      setCancelRsn('');
      setCancelBankAccount('');
      setCancelBankHolderName('');
      setDeliveryComNm('');
      setDeliveryNum('');
    }
  }, [showUpdatePopup]);

  useEffect(() => {
    if (selectedOrder?.orderId) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (categoryLv1) {
      getCategoryList(categoryLv1, 2);
    } else {
      setCategoryLv2('');
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv2List([]);
      setCategoryLv3List([]);
      setCategoryLv4List([]);
    }
  }, [categoryLv1]);

  useEffect(() => {
    if (categoryLv2) {
      getCategoryList(categoryLv2, 3);
    } else {
      setCategoryLv2('');
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv3List([]);
      setCategoryLv4List([]);
    }
  }, [categoryLv2]);

  useEffect(() => {
    if (categoryLv3) {
      getCategoryList(categoryLv3, 4);
    } else {
      setCategoryLv3('');
      setCategoryLv4('');
      setCategoryLv4List([]);
    }
  }, [categoryLv3]);

  useEffect(() => {
    setCategoryLv2('');
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv2List([]);
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv1List]);

  useEffect(() => {
    setCategoryLv2('');
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv2List]);

  useEffect(() => {
    setCategoryLv3('');
    setCategoryLv4('');
    setCategoryLv4List([]);
  }, [categoryLv3List]);

  useEffect(() => {
    setCategoryLv4('');
  }, [categoryLv4List]);

  const fetchData = async () => {
    try {
      const data: any = {
        comId: comInfo.comId,
        strDtm: strDtm + ' 00:00',
        endDtm: endDtm + ' 23:59',
      };

      if (searchKeyword.replaceAll(' ', '')) {
        if (searchDv === 'orderId') {
          if (!/^[0-9]{8}-[0-9]{9}$/.test(searchKeyword)) {
            makeMsg(
              '주문번호는 YYYYMMDD-주문번호9자리 형태로 입력하세요.',
              'warning',
            );
            return;
          }
          data[searchDv] = Number(searchKeyword.split('-')[1]);
        } else {
          data[searchDv] = searchKeyword;
        }
      }

      if (categoryLv4) {
        data.categoryCd = categoryLv4;
      } else if (categoryLv3) {
        data.categoryCd = categoryLv3;
      } else if (categoryLv2) {
        data.categoryCd = categoryLv2;
      } else if (categoryLv1) {
        data.categoryCd = categoryLv1;
      }

      data.orderStCd = orderStCdList
        .filter((item: any) => item.checked)
        .map((item: any) => item.comnCd);

      data.page = page;
      data.pageOffset = pageOffset;

      data.sellerId = getMyOrderOnly ? userInfo.userId : null;

      setIsLoading(true);
      const res = await orderApi.getList(token, data);

      if (res.data.rsltCd === '00') {
        setOrderList(
          res.data.data.orderList.map((order: any) => ({
            ...order,
            checked: false,
          })),
        );
        setTotalCount(res.data.data.totalCount);
      } else {
        setOrderList([]);
        setTotalCount(0);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('조회에 실패하였습니다.', 'error');
    }
  };

  const searchData = () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const handleCopyClipBoard = async (text: string) => {
    const res = await onCopy(text);
    if (res) {
      makeMsg('클립보드에 저장했습니다.', 'success');
    }
  };

  const getCategoryList = async (
    upperCategoryCd: string | null,
    level: number,
  ) => {
    try {
      const data = {
        upperCategoryCd: upperCategoryCd,
      };

      setIsLoading(true);
      const categoryRes = await categoryApi.getList(data);
      if (categoryRes.data.rsltCd === '00') {
        if (level === 1) {
          setCategoryLv1List(categoryRes.data.data.categoryList);
        } else if (level === 2) {
          setCategoryLv2List(categoryRes.data.data.categoryList);
        } else if (level === 3) {
          setCategoryLv3List(categoryRes.data.data.categoryList);
        } else if (level === 4) {
          setCategoryLv4List(categoryRes.data.data.categoryList);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const getOrderStCdList = async () => {
    try {
      const data = {
        upperComnCd: 'ORDST',
      };

      const res = await comnCdApi.getList(data);

      if (res.data.rsltCd === '00') {
        setOrderStCdList(
          res.data.data.comnCdList.map((item: any) => ({
            ...item,
            checked: true,
          })),
        );
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
      setIsLoading(false);
    }
  };

  const setSearchPeriod = (period: number) => {
    setEndDtm(moment().format('YYYY-MM-DD'));
    setStrDtm(moment().add(`-${period}`, 'days').format('YYYY-MM-DD'));
  };

  const checkOrderStCd = (index: number, setAll?: boolean) => {
    let temp = orderStCdList;

    if (index < 0) {
      temp = temp.map((item: any) => ({ ...item, checked: setAll || false }));
    } else {
      if (
        temp.filter((item: any) => item.checked).length === 1 &&
        temp[index].checked
      ) {
        temp = temp.map((item: any) => ({ ...item, checked: true }));
      } else {
        temp[index].checked = !temp[index].checked;
      }
    }
    setOrderStCdList([...temp]);
  };

  const checkOrder = (index: number, setAll?: boolean) => {
    let temp = orderList;

    if (index < 0) {
      temp = temp.map((item: any) => ({ ...item, checked: setAll || false }));
    } else {
      temp[index].checked = !temp[index].checked;
    }
    setOrderList([...temp]);
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const updateOrderSt = async (fromCd: string[], toCd: string) => {
    try {
      const checkedList = orderList.filter((item: any) => item.checked);
      if (checkedList.length < 1) {
        makeMsg('주문정보를 선택하세요.', 'warning');
        return;
      }

      for (let i = 0; i < checkedList.length; i++) {
        const order = checkedList[i];
        if (fromCd.findIndex((cd: string) => cd === order.orderStCd) < 0) {
          makeMsg(
            '변경하려는 단계로 이동할 수 없는\n단계의 주문이 포함되어 있습니다.\n선택한 주문목록을 다시 확인해주세요.',
            'warning',
          );
          return;
        }
      }

      // 1. 배송준비중 : 즉시
      // 2. 배송중 : 송장번호, 택배사
      // 3. 배송완료 : 즉시
      // 4. 취소요청 : 사유, 환불계좌, 환불은행, 환불자명
      // 5. 입점사 취소승인 또는 취소요청건이 카드인경우 : 즉시
      // 6. 마스터 취소승인 : 즉시
      if (
        toCd === 'ORDST11' || // 2. 배송중 : 즉시
        toCd === 'ORDST13' || // 3. 배송완료 : 즉시
        (toCd === 'ORDST92' && fromCd.includes('ORDST91')) || // 5. 입점사 취소승인 : 즉시
        toCd === 'ORDST93' // 6. 마스터 취소승인 : 즉시
      ) {
        updateOrder(toCd);
      } else {
        setFromCd(fromCd);
        setToCd(toCd);
        setShowUpdatePopup(true);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const updateOrder = async (to?: string) => {
    try {
      // 1. 배송준비중 : 송장번호, 택배사
      // 2. 배송중
      // 3. 배송완료
      // 4. 취소요청 : 사유, 환불계좌, 환불은행, 환불자명
      // 5. 입점사 취소승인 또는 취소요청건이 카드인경우 : 즉시
      // 6. 마스터 취소승인
      const checkedList = orderList.filter((item: any) => item.checked);

      const data: any = {
        orderStCd: to ? to : toCd,
        orderIdList: checkedList.map((item: any) => item.orderId),
        updUserId: userInfo.userId,
      };

      console.log(data);

      if (!to) {
        if (toCd === 'ORDST12') {
          if (!deliveryComNm.replaceAll(' ', '')) {
            // 1. 배송준비중 : 송장번호, 택배사
            makeMsg('실제 배송 택배사를 입력하세요.', 'warning');
            return;
          }

          if (!deliveryNum.replaceAll(' ', '')) {
            makeMsg('송장번호를 입력하세요.', 'warning');
            return;
          }

          data.deliveryComNm = deliveryComNm;
          data.deliveryNum = deliveryNum;
        } else if (toCd === 'ORDST92' && !fromCd.includes('ORDST91')) {
          // 4. 취소요청 : 사유, 환불계좌, 환불은행, 환불자명
          if (
            !cancelRsn.replaceAll(' ', '') ||
            (checkedList[0].paymentCd === 'V' &&
              (!cancelBankAccount.replaceAll(' ', '') ||
                !cancelBankName.replaceAll(' ', '') ||
                !cancelBankHolderName.replaceAll(' ', '')))
          ) {
            makeMsg(
              '취소요청을 하실 경우, 반드시 모든 환불정보를 입력해야합니다.',
              'warning',
            );
            return;
          }

          data.cancelRsn = cancelRsn;
          data.cancelBankAccount = cancelBankAccount;
          data.cancelBankName = cancelBankName;
          data.cancelBankHolderName = cancelBankHolderName;
        }
      }

      setIsLoading(true);

      const res = await orderApi.multiUpdate(token, data);
      if (res.data.rsltCd === '00') {
        makeMsg('저장되었습니다.', 'success');
        setShowUpdatePopup(false);
        fetchData();
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <OrderPresenter
        searchDv={searchDv}
        setSearchDv={setSearchDv}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        strDtm={strDtm}
        setStrDtm={setStrDtm}
        endDtm={endDtm}
        setEndDtm={setEndDtm}
        setSearchPeriod={setSearchPeriod}
        categoryLv1={categoryLv1}
        setCategoryLv1={setCategoryLv1}
        categoryLv2={categoryLv2}
        setCategoryLv2={setCategoryLv2}
        categoryLv3={categoryLv3}
        setCategoryLv3={setCategoryLv3}
        categoryLv4={categoryLv4}
        setCategoryLv4={setCategoryLv4}
        categoryLv1List={categoryLv1List}
        categoryLv2List={categoryLv2List}
        categoryLv3List={categoryLv3List}
        categoryLv4List={categoryLv4List}
        orderStCdList={orderStCdList}
        checkOrderStCd={checkOrderStCd}
        searchData={searchData}
        page={page}
        handlePageChange={handlePageChange}
        orderList={orderList}
        totalCount={totalCount}
        checkOrder={checkOrder}
        showPopup={showPopup}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        popupDv={popupDv}
        setPopupDv={setPopupDv}
        pageOffset={pageOffset}
        setPageOffset={setPageOffset}
        pageOffsetList={pageOffsetList}
        updateOrderSt={updateOrderSt}
        userInfo={userInfo}
        showUpdatePopup={showUpdatePopup}
        setShowUpdatePopup={setShowUpdatePopup}
        cancelRsn={cancelRsn}
        setCancelRsn={setCancelRsn}
        cancelBankAccount={cancelBankAccount}
        setCancelBankAccount={setCancelBankAccount}
        cancelBankName={cancelBankName}
        setCancelBankName={setCancelBankName}
        cancelBankHolderName={cancelBankHolderName}
        setCancelBankHolderName={setCancelBankHolderName}
        deliveryComNm={deliveryComNm}
        setDeliveryComNm={setDeliveryComNm}
        deliveryNum={deliveryNum}
        setDeliveryNum={setDeliveryNum}
        toCd={toCd}
        updateOrder={updateOrder}
        getMyOrderOnly={getMyOrderOnly}
        setGetMyOrderOnly={setGetMyOrderOnly}
        handleCopyClipBoard={handleCopyClipBoard}
      />
    </>
  );
};

OrderContainer.defaultProps = {};

export default OrderContainer;
