import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import MyInfoPresenter from './MyInfoPresenter';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import { userApi } from 'api/api-user';

const MyInfoContainer = () => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConf, setNewPasswordConf] = useState<string>('');
  const [openUpdatePasswordPopup, setOpenUpdatePasswordPopup] =
    useState<boolean>(false);

  useEffect(() => {
    setPassword('');
    setNewPassword('');
    setNewPasswordConf('');
  }, [openUpdatePasswordPopup]);

  const updatePassword = async () => {
    try {
      if (!password) {
        makeMsg('기존 비밀번호를 입력하세요.', 'warning');
        return;
      }

      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          newPassword,
        )
      ) {
        makeMsg(
          '비밀번호는 8~16자리 영문 대소문자, 숫자, 특수문자 조합으로 입력해주세요.',
          'warning',
        );
        return;
      }

      if (!newPasswordConf || newPassword !== newPasswordConf) {
        makeMsg('비밀번호를 다시 확인해주세요.', 'warning');
        return;
      }

      setIsLoading(true);

      const data = {
        userId: userInfo.userId,
        password: password,
        newPassword: newPassword,
      };

      const res = await userApi.update(token, data);

      if (res.data.rsltCd === '00') {
        makeMsg('비밀번호가 변경되었습니다', 'success');
        setOpenUpdatePasswordPopup(false);
      } else if (res.data.rsltCd === '05') {
        makeMsg('기존 비밀번호를 다시 확인해주세요.', 'error');
      } else {
        makeMsg('네트워크 에러가 발생하였습니다.', 'error');
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 에러가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <MyInfoPresenter
        userInfo={userInfo}
        password={password}
        setPassword={setPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        newPasswordConf={newPasswordConf}
        setNewPasswordConf={setNewPasswordConf}
        openUpdatePasswordPopup={openUpdatePasswordPopup}
        setOpenUpdatePasswordPopup={setOpenUpdatePasswordPopup}
        updatePassword={updatePassword}
      />
    </>
  );
};

MyInfoContainer.defaultProps = {};

export default MyInfoContainer;
