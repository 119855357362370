import React, { Dispatch } from 'react';
import * as s from './HeaderStyled';
import { Link, NavigateFunction } from 'react-router-dom';

import { FaCartArrowDown, FaCartPlus, FaUserCircle } from 'react-icons/fa';
import { GiCartwheel, GiHamburgerMenu } from 'react-icons/gi';
import { MdClose } from 'react-icons/md';
import {
  AiOutlineLogout,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShopping,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from 'react-icons/ai';
import theme from '../styles/theme';

interface HeaderProps {
  currentFocus: number;
  showMobileSidebar: boolean;
  showLoginModal: boolean;
  userInfo: any;
  comInfo: any;

  setCurrentFocus: Dispatch<React.SetStateAction<number>>;
  setShowMobileSidebar: Dispatch<React.SetStateAction<boolean>>;
  setShowLoginModal: Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
}

const HeaderPresenter = (props: HeaderProps) => {
  return (
    <>
      <s.Container expanded={props.currentFocus !== -1}>
        <s.LogoArea to="/">
          <s.LogoImg src={props.comInfo.logo} />
        </s.LogoArea>

        {/* Web */}
        <s.TopMenuArea>
          <s.TopMenuItem
            to="/mypage/2"
            focused={props.currentFocus === 0}
            onMouseEnter={() => props.setCurrentFocus(0)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            <AiOutlineShoppingCart size={'2rem'} color={theme.color.black} />
          </s.TopMenuItem>
          <s.TopMenuItem
            to="/mypage/1"
            focused={props.currentFocus === 1}
            onMouseEnter={() => props.setCurrentFocus(1)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            <AiOutlineUser size={'2rem'} color={theme.color.black} />
          </s.TopMenuItem>
          <s.TopMenuDiv />
          <s.TopMenuItem
            to="/"
            onClick={() => props.logout()}
            focused={props.currentFocus === 2}
            onMouseEnter={() => props.setCurrentFocus(2)}
            onMouseLeave={() => props.setCurrentFocus(-1)}
          >
            <AiOutlineLogout size={'2rem'} color={theme.color.black} />
          </s.TopMenuItem>
        </s.TopMenuArea>
      </s.Container>
    </>
  );
};

export default HeaderPresenter;
