import styled from 'styled-components';
import theme from 'components/styles/theme';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const BodyArea = styled.div`
  width: 100%;
  display: flex;
  gap: 3rem;
  margin-bottom: 5rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const CartItemListArea = styled.div`
  width: 65rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 95%;
    padding: 2rem 0;
    margin: 0 auto;
    flex-wrap: wrap;
  }
`;

export const SectionHeader = styled.div<{ padding: string; border: string }>`
  font-size: 1.6rem;
  color: ${theme.color.black};
  font-weight: 500;
  text-align: left;
  border-bottom: ${(props) => props.border};
  padding: ${(props) => props.padding};
  position: relative;
`;

export const MultiButtonWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  height: 4rem;
  width: 100%;
`;

export const SelectAllButton = styled.div<{ active: boolean }>`
  font-size: 1.2rem;
  color: ${(props) => (props.active ? '#ffffff' : theme.color.secondary)};
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 1.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${theme.color.secondary};
  background-color: ${(props) =>
    props.active ? theme.color.secondary : '#ffffff'};
  border-radius: 0.3rem;
`;

export const MultiDeleteButton = styled.div`
  font-size: 1.2rem;
  color: #fe4545;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 1.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #fe4545;
  border-radius: 0.3rem;
`;

export const NoItem = styled.div`
  width: 100%;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.8rem;
  color: ${theme.color.lightGrey};
  border-bottom: 1px solid ${theme.color.lightGrey};
`;

export const LinkButton = styled(Link)`
  width: 14rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.primary};
  border-radius: 5rem;
  cursor: pointer;
`;

export const ProductItem = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  position: relative;
  border-bottom: 1px solid ${theme.color.lightGrey};
`;

export const ProductItemThumbnail = styled.img`
  width: 13rem;
  height: 13rem;
  object-fit: contain;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 20%;
    height: 100%;
  }
`;

export const ProductTextList = styled.div`
  width: 52rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

export const ProductNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  cursor: pointer;

  &:hover {
    color: ${theme.color.secondary};
  }

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const ModelNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  color: ${theme.color.lightGrey};
  font-weight: 500;
  text-align: left;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
    overflow: hidden;
  }
`;

export const BrandNm = styled.span`
  color: ${theme.color.primary};
  margin-right: 1rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const OptionNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  color: ${theme.color.secondary};
  font-weight: 500;
  text-align: left;
  margin-bottom: 6rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const SelectCountArea = styled.div`
  display: flex;
  position: absolute;
  left: 16rem;
  bottom: 2rem;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
    bottom: 1rem;
  }
`;

export const UpdateCartItemCount = styled.div`
  width: 5rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${theme.color.darkGrey};
  border: 1px solid ${theme.color.lightGrey};
  border-radius: 0.5rem;
  cursor: pointer;
`;

export const SelectCountTable = styled.table`
  height: 3rem;
  border-spacing: 0;
`;

export const SelectCountTbody = styled.tbody``;

export const SelectCountTr = styled.tr``;

export const SelectCountTd = styled.td<{
  isFirst?: boolean;
  isLast?: boolean;
}>`
  border: 1px solid ${theme.color.lightGrey};
  border-radius: ${(props) =>
    props.isFirst
      ? '0.5rem 0 0 0.5rem'
      : props.isLast
      ? '0 0.5rem 0.5rem 0'
      : '0'};
  padding: ${(props) =>
    !props.isFirst && !props.isLast ? '0.5rem 2rem' : '0'};
  font-size: 1.4rem;
  color: ${theme.color.black};
`;

export const SelectCountButton = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteCartItemButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
`;

export const TotalPrice = styled.div`
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 2rem;

  @media screen and (max-width: 800px) {
    position: relative;
    font-size: 1.6rem;
    margin-bottom: 3rem;
    text-align: right;
    width: 100%;
  }
`;

export const ItemCheckbox = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid ${theme.color.lightGrey};
  position: absolute;
  left: 1rem;
  top: 1rem;

  &:checked {
    background-color: ${theme.color.third};
  }
`;

export const SummaryArea = styled.div`
  width: 35rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 95%;
    margin: 0 auto;
  }
`;

export const SummaryTopBox = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 2px solid ${theme.color.black};
  display: flex;
  flex-direction: column;
`;

export const SummaryTopTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  margin-bottom: 2rem;
`;

export const SummaryTopBoxRow = styled.div`
  width: 100%;
  position: relative;
  font-size: 1.4rem;
  color: ${theme.color.black};
  text-align: left;
  padding: 0.5rem 0;
  font-weight: 500;
`;

export const SummaryTopBoxRowCount = styled.div`
  position: absolute;
  right: 0;
  top: 0.5rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: bold;
`;

export const SummaryTopBoxTotal = styled.div`
  width: 100%;
  position: relative;
  padding: 2.5rem 0 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-top: 1px solid ${theme.color.lightGrey};
  text-align: left;
  margin-top: 3rem;
  color: ${theme.color.secondary};
`;

export const SummaryTopBoxTotalCount = styled.div`
  position: absolute;
  right: 0;
  top: 2rem;
  font-size: 2rem;
  color: ${theme.color.secondary};
  font-weight: bold;
`;

export const AgreementBox = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  position: relative;
`;

export const AgreementIcon = styled.div`
  padding-top: 0.2rem;
  width: 2rem;
  height: 2.2rem;
  cursor: pointer;
`;

export const AgreementStatement = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: left;
  width: 28rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const OrderButton = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: ${theme.color.primary};
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.5rem;
`;
