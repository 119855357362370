import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { bannerApi } from 'api/api-banner';
import { productApi } from 'api/api-product';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import ProductListPresenter from './ProductListPresenter';

const ProductListContainer = () => {
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const navigate = useNavigate();
  const location = useLocation();

  const [keyword, setKeyword] = useState<string>(location.state?.keyword || '');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('regDtm');
  const [banner1Preview, setBanner1Preview] = useState<string>('');
  const [banner2Preview, setBanner2Preview] = useState<string>('');
  const [banner3Preview, setBanner3Preview] = useState<string>('');
  const [banner1Keyword, setBanner1Keyword] = useState<string>('');
  const [banner2Keyword, setBanner2Keyword] = useState<string>('');
  const [banner3Keyword, setBanner3Keyword] = useState<string>('');

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageOffset = 16;
  const [productList, setProductList] = useState<any[]>([]);

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    searchData();
  }, [keyword]);

  useEffect(() => {
    searchData();
  }, [orderBy]);

  const getInitData = async () => {
    getBannerList();
  };

  const getBannerList = async () => {
    try {
      const data = {
        comId: comInfo.comId,
      };

      const res = await bannerApi.getList(data);
      if (res.data.rsltCd === '00') {
        const banner1 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 1,
        );

        const banner2 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 2,
        );

        const banner3 = res.data.data.bannerList.find(
          (banner: any) => Number(banner.index) === 3,
        );

        if (banner1) {
          setBanner1Preview(banner1.img);
          setBanner1Keyword(banner1.keyword);
        }

        if (banner2) {
          setBanner2Preview(banner2.img);
          setBanner2Keyword(banner2.keyword);
        }

        if (banner3) {
          setBanner3Preview(banner3.img);
          setBanner3Keyword(banner3.keyword);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data: any = {
        comId: comInfo.comId,
      };

      if (keyword) {
        data.keyword = keyword;
      }

      if (orderBy) {
        data.orderBy = orderBy;
      }

      data.sellExcept = 'N';

      data.page = page;
      data.pageOffset = pageOffset;

      const res = await productApi.getList(data);

      if (res.data.rsltCd === '00') {
        setProductList(
          res.data.data.productList.map((product: any) => ({
            ...product,
            productNm:
              product.productNm.length > 40
                ? product.productNm.substring(0, 35) + '...'
                : product.productNm,
          })),
        );
        setTotalCount(res.data.data.totalCount);
      } else {
        setProductList([]);
        setTotalCount(0);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('조회에 실패하였습니다.', 'error');
    }
  };

  const searchData = () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const searchByKeyword = () => {
    setKeyword(searchKeyword);
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const checkSearchEnter = (event: any) => {
    if (event.key === 'Enter') {
      searchByKeyword();
    }
  };

  const toDetail = (productId: any) => {
    navigate(`/product/${productId}`);
  };

  return (
    <>
      <ProductListPresenter
        keyword={keyword}
        setKeyword={setKeyword}
        userInfo={userInfo}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        banner1Preview={banner1Preview}
        banner2Preview={banner2Preview}
        banner3Preview={banner3Preview}
        banner1Keyword={banner1Keyword}
        banner2Keyword={banner2Keyword}
        banner3Keyword={banner3Keyword}
        totalCount={totalCount}
        productList={productList}
        page={page}
        handlePageChange={handlePageChange}
        pageOffset={pageOffset}
        searchData={searchData}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        searchByKeyword={searchByKeyword}
        checkSearchEnter={checkSearchEnter}
        toDetail={toDetail}
      />
    </>
  );
};

ProductListContainer.defaultProps = {};

export default ProductListContainer;
