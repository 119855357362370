import React, { Dispatch, SetStateAction } from 'react';
import * as s from './CartStyled';
import { Link } from 'react-router-dom';
import {
  AiFillCheckCircle,
  AiOutlineCheckCircle,
  AiOutlineShoppingCart,
} from 'react-icons/ai';
import theme from 'components/styles/theme';
import { makeMsg, priceToString } from 'util/util';
import { MdClose } from 'react-icons/md';
import { FaCheckCircle } from 'react-icons/fa';

interface CartProps {
  cartItemList: any[];
  setCartItemList: Dispatch<SetStateAction<any[]>>;
  changeOptionCount: (index: number, value: number) => void;
  goToProductDetail: (productId: number) => void;
  updateCartItem: (cartId: number) => void;
  deleteCartItem: (cartId: number) => void;
  multiDeleteCartItem: () => void;
  orderProduct: () => void;
}

const CartPresenter = (props: CartProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>장바구니</s.PageTitle>
        <s.PageIndicator>{'마이페이지 > 장바구니'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.BodyArea>
        <s.CartItemListArea>
          <s.SectionHeader
            padding="1rem 0 5rem"
            border={`2px solid ${theme.color.primary}`}
          >
            상품목록
            <s.MultiButtonWrapper>
              <s.SelectAllButton
                active={
                  props.cartItemList.length ===
                  props.cartItemList.filter((item: any) => item.checked).length
                }
                onClick={() => {
                  if (
                    props.cartItemList.length ===
                    props.cartItemList.filter((item: any) => item.checked)
                      .length
                  ) {
                    const temp = props.cartItemList.map((item: any) => ({
                      ...item,
                      checked: false,
                    }));
                    props.setCartItemList([...temp]);
                  } else {
                    const temp = props.cartItemList.map((item: any) => ({
                      ...item,
                      checked: true,
                    }));
                    props.setCartItemList([...temp]);
                  }
                }}
              >
                {props.cartItemList.length ===
                props.cartItemList.filter((item: any) => item.checked).length
                  ? '선택해제'
                  : '전체선택'}
              </s.SelectAllButton>
              <s.MultiDeleteButton onClick={() => props.multiDeleteCartItem()}>
                선택삭제
              </s.MultiDeleteButton>
            </s.MultiButtonWrapper>
          </s.SectionHeader>
          {props.cartItemList.length > 0 ? (
            props.cartItemList.map((product: any, index: number) => (
              <s.ProductItem>
                <s.ItemCheckbox
                  type="checkbox"
                  checked={product.checked}
                  onChange={() => {
                    const temp = props.cartItemList;
                    temp[index].checked = !temp[index].checked;
                    props.setCartItemList([...temp]);
                  }}
                />
                <s.DeleteCartItemButton
                  onClick={() => props.deleteCartItem(product.cartId)}
                >
                  <MdClose size={'100%'} color={theme.color.black} />
                </s.DeleteCartItemButton>
                <s.ProductItemThumbnail
                  src={product.thumbnail}
                  onClick={() => props.goToProductDetail(product.productId)}
                />
                <s.ProductTextList>
                  <s.ProductNm
                    onClick={() => props.goToProductDetail(product.productId)}
                  >
                    {product.productNm}
                  </s.ProductNm>
                  <s.ModelNm>
                    <s.BrandNm>{product.brandNm}</s.BrandNm>
                    {product.modelNm}
                  </s.ModelNm>
                  <s.OptionNm>
                    {product.optionNm} / 현재재고 : {product.stock}EA
                  </s.OptionNm>
                  <s.SelectCountArea>
                    <s.SelectCountTable>
                      <s.SelectCountTbody>
                        <s.SelectCountTr>
                          <s.SelectCountTd isFirst>
                            <s.SelectCountButton
                              onClick={() =>
                                Number(product.count) === 1
                                  ? makeMsg(
                                      '최소 1개 이상 구매해야 합니다.',
                                      'warning',
                                    )
                                  : props.changeOptionCount(
                                      index,
                                      Number(product.count) - 1,
                                    )
                              }
                            >
                              -
                            </s.SelectCountButton>
                          </s.SelectCountTd>
                          <s.SelectCountTd style={{ width: '5rem' }}>
                            {product.count}
                          </s.SelectCountTd>
                          <s.SelectCountTd isLast>
                            <s.SelectCountButton
                              onClick={() =>
                                product.count >= product.stock
                                  ? makeMsg(
                                      '재고수량보다 더 주문하실 수 없습니다.',
                                      'warning',
                                    )
                                  : props.changeOptionCount(
                                      index,
                                      Number(product.count) + 1,
                                    )
                              }
                            >
                              +
                            </s.SelectCountButton>
                          </s.SelectCountTd>
                        </s.SelectCountTr>
                      </s.SelectCountTbody>
                    </s.SelectCountTable>
                    <s.UpdateCartItemCount
                      onClick={() => props.updateCartItem(product.cartId)}
                    >
                      변경
                    </s.UpdateCartItemCount>
                  </s.SelectCountArea>
                  <s.TotalPrice>
                    {priceToString(
                      (Number(product.priceDiscount) +
                        Number(product.optionPrice)) *
                        Number(product.count),
                    )}{' '}
                    원
                  </s.TotalPrice>
                </s.ProductTextList>
              </s.ProductItem>
            ))
          ) : (
            <s.NoItem>
              <AiOutlineShoppingCart
                size={'5rem'}
                color={theme.color.lightGrey}
              />{' '}
              장바구니에 상품이 없습니다.
              <s.LinkButton to="/">쇼핑하러가기</s.LinkButton>
            </s.NoItem>
          )}
        </s.CartItemListArea>
        <s.SummaryArea>
          <s.SectionHeader padding="1rem 0" border="0">
            결제 금액
          </s.SectionHeader>
          <s.SummaryTopBox>
            <s.SummaryTopTitle>전체 합계</s.SummaryTopTitle>
            <s.SummaryTopBoxRow>
              제품 수
              <s.SummaryTopBoxRowCount>
                {priceToString(
                  props.cartItemList
                    .filter((item: any) => item.checked)
                    .reduce((a: any, b: any) => a + b.count, 0),
                )}
                EA
              </s.SummaryTopBoxRowCount>
            </s.SummaryTopBoxRow>
            <s.SummaryTopBoxRow>
              주문금액
              <s.SummaryTopBoxRowCount>
                {priceToString(
                  props.cartItemList
                    .filter((item: any) => item.checked)
                    .reduce(
                      (a: any, b: any) =>
                        a + (b.priceDiscount + b.optionPrice) * b.count,
                      0,
                    ),
                )}
                원
              </s.SummaryTopBoxRowCount>
            </s.SummaryTopBoxRow>
            <s.SummaryTopBoxRow>
              배송비
              <s.SummaryTopBoxRowCount>
                {priceToString(
                  props.cartItemList
                    .filter((item: any) => item.checked)
                    .reduce((a: any, b: any) => a + Number(b.deliveryPrice), 0),
                )}
                원
              </s.SummaryTopBoxRowCount>
            </s.SummaryTopBoxRow>
            <s.SummaryTopBoxTotal>
              최종 결제금액
              <s.SummaryTopBoxTotalCount>
                {priceToString(
                  Number(
                    props.cartItemList
                      .filter((item: any) => item.checked)
                      .reduce(
                        (a: any, b: any) =>
                          a + (b.priceDiscount + b.optionPrice) * b.count,
                        0,
                      ) +
                      Number(
                        props.cartItemList
                          .filter((item: any) => item.checked)
                          .reduce(
                            (a: any, b: any) => a + Number(b.deliveryPrice),
                            0,
                          ),
                      ),
                  ),
                )}
                원
              </s.SummaryTopBoxTotalCount>
            </s.SummaryTopBoxTotal>
          </s.SummaryTopBox>
          {Number(
            props.cartItemList
              .filter((item: any) => item.checked)
              .reduce(
                (a: any, b: any) =>
                  a + (b.priceDiscount + b.optionPrice) * b.count,
                0,
              ) +
              Number(
                props.cartItemList
                  .filter((item: any) => item.checked)
                  .reduce((a: any, b: any) => a + Number(b.deliveryPrice), 0),
              ),
          ) > 0 ? (
            <s.OrderButton onClick={() => props.orderProduct()}>
              {priceToString(
                Number(
                  props.cartItemList
                    .filter((item: any) => item.checked)
                    .reduce(
                      (a: any, b: any) =>
                        a + (b.priceDiscount + b.optionPrice) * b.count,
                      0,
                    ) +
                    Number(
                      props.cartItemList
                        .filter((item: any) => item.checked)
                        .reduce(
                          (a: any, b: any) => a + Number(b.deliveryPrice),
                          0,
                        ),
                    ),
                ),
              )}{' '}
              원 구매하기
            </s.OrderButton>
          ) : null}
        </s.SummaryArea>
      </s.BodyArea>
    </s.Container>
  );
};

export default CartPresenter;
