import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { comnCdApi } from 'api/api-comncd';
import { deliveryApi } from 'api/api-delivery';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';

import DeliveryConditionPresenter from './DeliveryConditionPresenter';

interface Props {
  setActive: Dispatch<SetStateAction<number>>;
  setCurrentCondition: Dispatch<SetStateAction<any>>;
}

const DeliveryConditionContainer = (props: Props) => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [page, setPage] = useState<number>(1);
  const [deliveryConditionNm, setDeliveryConditionNm] = useState<string>('');
  const pageOffset = 10;
  const [deliveryCompanyId, setDeliveryCompanyId] = useState<string>('');
  const [deliveryConditionTypeCd, setDeliveryConditionTypeCd] =
    useState<string>('');

  const [deliveryConditionTypeList, setDeliveryConditionTypeList] = useState<
    any[]
  >([]);
  const [deliveryCompanyList, setDeliveryCompanyList] = useState<any[]>([]);

  const [deliveryConditionList, setDeliveryConditionList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    getInitData();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  const getInitData = async () => {
    try {
      props.setCurrentCondition(null);
      const data = {
        upperComnCd: 'DLVTYPE',
      };

      const res = await comnCdApi.getList(data);
      if (res.data.rsltCd === '00') {
        setDeliveryConditionTypeList(
          res.data.data.comnCdList.filter(
            (item: any) =>
              item.comnCd === 'DLVTYPE01' || item.comnCd === 'DLVTYPE02',
          ),
        );
      }

      const deliveryCompanyData = {};
      const res2 = await deliveryApi.getCompanyList(deliveryCompanyData);
      if (res2.data.rsltCd === '00') {
        setDeliveryCompanyList(res2.data.data.deliveryCompanyList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const fetchData = async () => {
    try {
      const data: any = {
        page: page,
        deliveryConditionNm: deliveryConditionNm,
        deliveryCompanyId: deliveryCompanyId,
        deliveryConditionTypeCd: deliveryConditionTypeCd,
        userId: userInfo.userId,
        pageOffset: pageOffset,
      };

      setIsLoading(true);
      const res = await deliveryApi.getList(data);
      if (res.data.rsltCd === '00') {
        setDeliveryConditionList(res.data.data.deliveryConditionList);
        setTotalCount(res.data.data.totalCount);
      } else {
        setDeliveryConditionList([]);
        setTotalCount(0);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const search = async () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const goToUpdate = (condition: any) => {
    props.setCurrentCondition(condition);
    props.setActive(3);
  };

  return (
    <>
      <DeliveryConditionPresenter
        page={page}
        setPage={setPage}
        pageOffset={pageOffset}
        deliveryConditionNm={deliveryConditionNm}
        setDeliveryConditionNm={setDeliveryConditionNm}
        deliveryCompanyId={deliveryCompanyId}
        setDeliveryCompanyId={setDeliveryCompanyId}
        deliveryConditionTypeCd={deliveryConditionTypeCd}
        setDeliveryConditionTypeCd={setDeliveryConditionTypeCd}
        deliveryConditionTypeList={deliveryConditionTypeList}
        deliveryCompanyList={deliveryCompanyList}
        totalCount={totalCount}
        deliveryConditionList={deliveryConditionList}
        search={search}
        handlePageChange={handlePageChange}
        goToUpdate={goToUpdate}
      />
    </>
  );
};

DeliveryConditionContainer.defaultProps = {};

export default DeliveryConditionContainer;
