import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  min-width: 192rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
  }
`;

export const OptionSelectWrapper = styled.div`
  width: 40rem;
  padding: 5rem 2rem 2rem;
  position: fixed;
  right: 30rem;
  top: 30rem;
  border: 1px solid ${theme.color.veryLightGrey};
  box-shadow: 0 0 0.3rem 0.3rem ${theme.color.veryLightGrey};
  z-index: 9999999;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 40rem;
  overflow-y: scroll;
`;

export const OptionItem = styled.div`
  width: 100%;
  height: 4rem;
  border: 1px solid ${theme.color.primary};
  cursor: pointer;
  border-radius: 0.5rem;
`;

export const ClosePopupButton = styled.div`
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const ContentArea = styled.div<{ openPopup: boolean }>`
  width: 120rem;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  ${(props) => (props.openPopup ? 'overflow-y: hidden' : null)};

  @media screen and (max-width: 800px) {
    width: 95%;
    margin: 2rem auto 5rem;
  }
`;

export const TopInfoArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  gap: 10rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    gap: 2rem;
  }
`;

export const TopInfoThumbnail = styled.img`
  width: 50rem;
  height: 50rem;
  object-fit: cover;

  @media screen and (max-width: 800px) {
    width: 100%;
    object-fit: contain;
    box-shadow: 0 0 0.3rem 0.3rem;
    ${theme.color.primary};
  }
`;

export const TopInfoTextWrapper = styled.div`
  width: 60rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;

export const TopInfoTitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  height: 7rem;
  width: 100%;

  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TopInfoTitleIconWrapper = styled.div`
  width: 15%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
`;

export const TopInfoTitleIcon = styled.div`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;

export const TopInfoTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${theme.color.black};
  width: 85%;
  text-align: left;
  line-height: 3.5rem;

  @media screen and (max-width: 800px) {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
`;

export const TopModelNmWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  height: 3rem;

  @media screen and (max-width: 800px) {
    height: auto;
  }
`;

export const TopModelNmIcon = styled.div`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  padding: 0.5rem;
`;

export const TopModelNm = styled.div`
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: ${theme.color.grey};

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
    line-height: 2.4rem;
  }
`;

export const PriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  justify-content: flex-end;
  height: 6rem;
`;

export const PriceText = styled.div`
  font-size: 1.6rem;
  color: ${theme.color.lightGrey};
  text-decoration: line-through;
`;

export const BigPriceWrapper = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 2.5rem;
`;

export const DiscountText = styled.div`
  color: #ff0000;
  font-weight: bold;
`;

export const PriceDiscountText = styled.div`
  color: ${theme.color.black};
`;

export const PriceDiscountSpan = styled.span`
  font-weight: bold;
`;

export const OptionWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  cursor: pointer;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  font-size: 1.4rem;
  color: ${theme.color.secondary};
`;

export const OptionLabel = styled.div`
  width: 20%;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;

  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
    width: 30%;
  }
`;

export const OptionSelect = styled.select`
  width: 80%;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.secondary};
  color: ${theme.color.black};
  appearance: none;
  z-index: 99999;
  background-color: transparent;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
    width: 70%;
  }
`;

export const OptionSelectChevron = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const DeliveryInfoWrapper = styled.div`
  height: 15rem;
  background-color: ${theme.color.veryLightGrey};
  padding: 1rem;
  width: 100%;
  margin: 1rem 0;
`;

export const DeliveryTable = styled.div`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const DeliveryTbody = styled.tbody``;

export const DeliveryTr = styled.tr``;

export const DeliveryTh = styled.th`
  font-size: 1.4rem;
  color: ${theme.color.darkGrey};
  width: 15%;
  padding: 1rem;
  vertical-align: top;
  font-weight: 600;

  @media screen and (max-width: 800px) {
    width: 25%;
    font-size: 1.2rem;
  }
`;

export const DeliveryTd = styled.td`
  font-size: 1.4rem;
  color: ${theme.color.black};
  width: 85%;
  padding: 1rem;
  font-weight: 400;
  text-align: left;
  vertical-align: top;

  @media screen and (max-width: 800px) {
    width: 75%;
    font-size: 1.2rem;
  }
`;

export const DeliveryTdText = styled.div`
  margin-bottom: 1rem;
  font-weight: 500;
`;

export const TopInfoGeneralRow = styled.div`
  display: flex;
  position: relative;
  height: 5rem;
  font-size: 1.6rem;
  color: ${theme.color.black};
  font-weight: bold;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 800px) {
    font-size: 1.24em;
  }
`;

export const TopInfoGeneralSmallText = styled.div`
  position: absolute;
  right: 0;
  font-size: 1.8rem;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const TopInfoGeneralText = styled.div`
  position: absolute;
  right: 0;
  font-size: 2.5rem;

  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
  }
`;

export const TopInfoGeneralDeleteRow = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 0;
  top: 1.5rem;
  cursor: pointer;
`;

export const TopInfoGeneralSelectCountTable = styled.table`
  position: absolute;
  right: 3rem;
  height: 3rem;
  border-spacing: 0;
`;

export const TopInfoGeneralSelectCountTbody = styled.tbody``;

export const TopInfoGeneralSelectCountTr = styled.tr``;

export const TopInfoGeneralSelectCountTd = styled.td<{
  isFirst?: boolean;
  isLast?: boolean;
}>`
  border: 1px solid ${theme.color.lightGrey};
  border-radius: ${(props) =>
    props.isFirst
      ? '0.5rem 0 0 0.5rem'
      : props.isLast
      ? '0 0.5rem 0.5rem 0'
      : '0'};
  padding: ${(props) =>
    !props.isFirst && !props.isLast ? '0.5rem 2rem' : '0'};
  font-size: 1.4rem;
  color: ${theme.color.black};

  @media screen and (max-width: 800px) {
    padding: ${(props) =>
      !props.isFirst && !props.isLast ? '0.5rem 0rem' : '0'};
    font-size: 1.2rem;
  }
`;

export const TopInfoGeneralSelectCountButton = styled.div`
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopInfoButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  height: 5rem;
  margin-top: 1.5rem;

  @media screen and (max-width: 800px) {
    height: 4rem;
  }
`;

export const TopInfoButton = styled.div<{ width: string; primary?: boolean }>`
  width: ${(props) => props.width};
  height: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.primary ? '#ffffff' : theme.color.black)};
  background-color: ${(props) =>
    props.primary ? theme.color.primary : '#ffffff'};
  border: ${(props) =>
    props.primary ? '0' : `1px solid ${theme.color.lightGrey}`};
  font-size: 1.6rem;
  font-weight: bold;

  @media screen and (max-width: 800px) {
    font-size: 1.4rem;
  }
`;

export const BottomInfoArea = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
`;

export const BottomTabWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid ${theme.color.black};
`;

export const BottomTabItem = styled.div<{ active: boolean }>`
  width: 15rem;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  font-weight: ${(props) => (props.active ? 'bold' : '500')};
  color: ${theme.color.black};
  border-bottom: ${(props) =>
    props.active ? `3px solid ${theme.color.black}` : '3px solid transparent'};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const QuillWrapper = styled.div`
  width: 100%;

  .ql-container.ql-snow {
    border: 0;
  }
`;

export const BottomContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
`;

export const BottomContentTitle = styled.div`
  padding: 2rem;
  font-size: 1.8rem;
  font-weight: 500;
  color: ${theme.color.black};
  display: flex;
  border-bottom: 1px solid ${theme.color.black};
  position: relative;
`;

export const BottomContentButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 0rem;
  border-radius: 5rem;
  width: 15rem;
  padding: 1rem;
  font-size: 1.8rem;
  background-color: ${theme.color.black};
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 1.2rem;
    bottom: 1rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
`;

export const BottomContentTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const BottomContentTbody = styled.tbody``;

export const BottomContentTr = styled.tr`
  cursor: pointer;
`;

export const BottomContentTh = styled.th<{ align?: string; width?: string }>`
  padding: 2rem 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black}
  text-align: ${(props) => (props.align ? props.align : 'center')};
  background-color: ${theme.color.veryLightGrey};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-bottom: 1px solid ${theme.color.lightGrey};

  @media screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 1.2rem;
    width: 25%;
  }
`;

export const BottomContentTd = styled.td<{ align?: string; width?: string }>`
  padding: 2rem 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black}
  text-align: ${(props) => (props.align ? props.align : 'left')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-bottom: 1px solid ${theme.color.lightGrey};

  @media screen and (max-width: 800px) {
    padding: 1rem;
    font-size: 1.2rem;
    width: 75%;
  }
`;

export const BottomContentTdLabel = styled.div<{ done: boolean }>`
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: ${(props) =>
    props.done ? theme.color.black : theme.color.grey};
  border-radius: 0.5rem;
  width: 8rem;
`;

export const QnaPopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999999999;
`;

export const QnaPopup = styled.div`
  width: 60rem;
  max-height: 65rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  position: relative;
  z-index: 99999999999999;
  gap: 1rem;
  overflow-y: auto;
`;

export const PopupTitle = styled.div`
  width: 100%;
  padding: 2rem 0 1rem;
  font-size: 2rem;
  color: ${theme.color.black};
  text-align: left;
  border-bottom: 2px solid ${theme.color.black};
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const PopupRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const PopupSelect = styled.select`
  width: 100%;
  border: 1px solid ${theme.color.black};
  border-radius: 0.5rem;
  color: ${theme.color.black};
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  appearance: none;
`;

export const PopupOptionSelectChevron = styled.div`
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
`;

export const PopupInput = styled.input`
  width: 100%;
  border: 1px solid ${theme.color.black};
  border-radius: 0.5rem;
  color: ${theme.color.black};
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  placeholder-color: ${theme.color.veryLightGrey};
`;

export const PopupTextarea = styled.textarea`
  width: 100%;
  resize: none;
  height: 20rem;
  overflow-y: auto;
  color: ${theme.color.black};
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  placeholder-color: ${theme.color.veryLightGrey};
  border: 1px solid ${theme.color.black};
  border-radius: 0.5rem;
  line-height: 2rem;
`;

export const PopupTextLimit = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 1rem;
  color: ${theme.color.grey};
`;

export const PopupButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 4rem 0 2rem;
  align-items: center;
  justify-content: center;
`;

export const PopupButton = styled.div`
  padding: 1rem 3rem;
  background-color: ${theme.color.black};
  color: #ffffff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  border-radius: 5rem;
`;

export const GetMoreButton = styled.div`
  margin: 2rem auto;
  font-size: 1.4rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const QuestionTextArea = styled.div`
  background-color: ${theme.color.veryLightGrey};
  color: ${theme.color.black};
  font-weight: 500;
  padding: 3rem 1rem;
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

export const ReplyTextArea = styled.div`
  background-color: ${theme.color.third};
  color: ${theme.color.black};
  font-weight: 500;
  padding: 3rem 1rem;
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

export const ReplyTimestamp = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: ${theme.color.darkGrey};
  font-size: 1.2rem;
`;
