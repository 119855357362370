import { notLoginedApi, loginedApi } from './api-base';

export const qnaApi = {
  getList: (Data: any) => notLoginedApi.post('/prod/qna/getList', Data),

  insert: (token: any, Data: any) =>
    loginedApi.post('/prod/qna/insert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
