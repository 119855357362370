import React, { Dispatch, SetStateAction } from 'react';
import * as s from './BannerStyled';
import { Link } from 'react-router-dom';

interface BannerProps {
  banner1Img: any;
  setBanner1Img: Dispatch<SetStateAction<any>>;
  banner2Img: any;
  setBanner2Img: Dispatch<SetStateAction<any>>;
  banner3Img: any;
  setBanner3Img: Dispatch<SetStateAction<any>>;
  banner1Preview: string;
  banner2Preview: string;
  banner3Preview: string;

  banner1Keyword: string;
  setBanner1Keyword: Dispatch<SetStateAction<string>>;
  banner2Keyword: string;
  setBanner2Keyword: Dispatch<SetStateAction<string>>;
  banner3Keyword: string;
  setBanner3Keyword: Dispatch<SetStateAction<string>>;
  saveBannerInfo: (index: number) => void;
}

const BannerPresenter = (props: BannerProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>메인배너관리</s.PageTitle>
        <s.PageIndicator>{'배너관리 > 배너관리'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.GeneralInfoArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }} align="center">
                위치
              </s.SectionTh>
              <s.SectionTh style={{ width: '40%' }} align="center">
                이미지
              </s.SectionTh>
              <s.SectionTh style={{ width: '30%' }} align="center">
                키워드
              </s.SectionTh>
              <s.SectionTh style={{ width: '10%' }} align="center">
                저장
              </s.SectionTh>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                좌측 메인 배너
                <br />
                (750*500)
              </s.SectionTh>
              <s.SectionTd width="40%">
                <s.GeneralInfoTdVerticalWrapper>
                  <s.GeneralInfoBanner1Preview
                    src={props.banner1Preview}
                    contain={props.banner1Preview === '/img/empty.png'}
                  />
                  <s.GeneralInfoInput
                    type="file"
                    width="25rem"
                    onChange={(e) => {
                      e.target.files && props.setBanner1Img(e.target.files[0]);
                    }}
                    accept=".png,.jpg,.jpeg,.webP"
                  />
                </s.GeneralInfoTdVerticalWrapper>
              </s.SectionTd>
              <s.SectionTd width="30%">
                <s.GeneralInfoInput
                  width="25rem"
                  type="text"
                  value={props.banner1Keyword}
                  onChange={(e) => props.setBanner1Keyword(e.target.value)}
                  maxLength={200}
                />
              </s.SectionTd>
              <s.SectionTd width="10%">
                <s.UpdateBannerButton onClick={() => props.saveBannerInfo(1)}>
                  저장
                </s.UpdateBannerButton>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                우측 상단 배너
                <br />
                (440*245)
              </s.SectionTh>
              <s.SectionTd width="40%">
                <s.GeneralInfoTdVerticalWrapper>
                  <s.GeneralInfoBanner2Preview
                    src={props.banner2Preview}
                    contain={props.banner2Preview === '/img/empty.png'}
                  />
                  <s.GeneralInfoInput
                    type="file"
                    width="25rem"
                    onChange={(e) => {
                      e.target.files && props.setBanner2Img(e.target.files[0]);
                    }}
                    accept=".png,.jpg,.jpeg,.webP"
                  />
                </s.GeneralInfoTdVerticalWrapper>
              </s.SectionTd>
              <s.SectionTd width="30%">
                <s.GeneralInfoInput
                  width="25rem"
                  type="text"
                  value={props.banner2Keyword}
                  onChange={(e) => props.setBanner2Keyword(e.target.value)}
                  maxLength={200}
                />
              </s.SectionTd>
              <s.SectionTd width="10%">
                <s.UpdateBannerButton onClick={() => props.saveBannerInfo(2)}>
                  저장
                </s.UpdateBannerButton>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                우측 상단 배너
                <br />
                (440*245)
              </s.SectionTh>
              <s.SectionTd width="40%">
                <s.GeneralInfoTdVerticalWrapper>
                  <s.GeneralInfoBanner2Preview
                    src={props.banner3Preview}
                    contain={props.banner3Preview === '/img/empty.png'}
                  />
                  <s.GeneralInfoInput
                    type="file"
                    width="25rem"
                    onChange={(e) => {
                      e.target.files && props.setBanner3Img(e.target.files[0]);
                    }}
                    accept=".png,.jpg,.jpeg,.webP"
                  />
                </s.GeneralInfoTdVerticalWrapper>
              </s.SectionTd>
              <s.SectionTd width="30%">
                <s.GeneralInfoInput
                  width="25rem"
                  type="text"
                  value={props.banner3Keyword}
                  onChange={(e) => props.setBanner3Keyword(e.target.value)}
                  maxLength={200}
                />
              </s.SectionTd>
              <s.SectionTd width="10%">
                <s.UpdateBannerButton onClick={() => props.saveBannerInfo(3)}>
                  저장
                </s.UpdateBannerButton>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.GeneralInfoArea>
    </s.Container>
  );
};

export default BannerPresenter;
