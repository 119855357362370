import { notLoginedApi, loginedApi } from './api-base';

export const orderApi = {
  multiInsert: (token: any, Data: any) =>
    loginedApi.post('/order/mgnt/multiInsert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  getList: (token: any, Data: any) =>
    loginedApi.post('/order/mgnt/getList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  multiUpdate: (token: any, Data: any) =>
    loginedApi.post('/order/mgnt/multiUpdate', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
