import React, { Dispatch, SetStateAction } from 'react';
import * as s from './OrderStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import 'components/styles/Paging.css';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import theme from 'components/styles/theme';
import { priceToString } from 'util/util';
import moment from 'moment';
import { MdClose } from 'react-icons/md';
import { isMobile } from 'react-device-detect';

interface OrderProps {
  page: number;
  handlePageChange: (page: number) => void;
  totalCount: number;
  orderList: any[];
  showDetailPopup: boolean;
  selectedOrder: any;
  setSelectedOrder: Dispatch<SetStateAction<any>>;
  updatingOrder: any;
  setUpdatingOrder: Dispatch<SetStateAction<any>>;
  pageOffset: number;

  showUpdatePopup: boolean;
  setShowUpdatePopup: Dispatch<SetStateAction<boolean>>;
  cancelRsn: string;
  setCancelRsn: Dispatch<SetStateAction<string>>;
  cancelBankAccount: string;
  setCancelBankAccount: Dispatch<SetStateAction<string>>;
  cancelBankName: string;
  setCancelBankName: Dispatch<SetStateAction<string>>;
  cancelBankHolderName: string;
  setCancelBankHolderName: Dispatch<SetStateAction<string>>;
  updateOrder: () => void;
}

const OrderPresenter = (props: OrderProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>주문내역</s.PageTitle>
        <s.PageIndicator>{'마이페이지 > 주문내역'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.BodyArea>
        <s.OrderTable>
          <s.OrderTbody>
            <s.OrderTr>
              <s.OrderTh style={{ width: '15%' }}>주문일시/주문번호</s.OrderTh>
              <s.OrderTh style={{ width: '45%' }}>상품정보</s.OrderTh>
              <s.OrderTh style={{ width: '10%' }}>수량</s.OrderTh>
              <s.OrderTh style={{ width: '15%' }}>주문금액</s.OrderTh>
              <s.OrderTh style={{ width: '25%' }}>진행상태</s.OrderTh>
            </s.OrderTr>
            {props.orderList.length > 0 ? (
              props.orderList.map((order: any, index: any) => (
                <s.OrderTr>
                  <s.OrderTd align="center">
                    <s.OrderTdWrapper>
                      <s.OrderTdText color={theme.color.lightGrey}>
                        {moment(order.regDtm).format('YYYY.MM.DD.')}
                      </s.OrderTdText>
                      <s.OrderTdText>{order.orderNum}</s.OrderTdText>
                      <s.OrderTdButton
                        onClick={() => props.setSelectedOrder(order)}
                      >
                        상세보기
                      </s.OrderTdButton>
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                  <s.OrderTd align="left">
                    <s.OrderTdWrapper direction="row">
                      <s.OrderTdImg src={order.thumbnail} />
                      <s.OrderTdProductInfo>
                        <s.OrderTdProductNm>
                          {`${order.productNm}_${order.optionNm}`}
                        </s.OrderTdProductNm>
                        <s.OrderTdModelNm>{order.modelNm}</s.OrderTdModelNm>
                        <s.OrderTdProductPrice>
                          판매가 :
                          {priceToString(
                            order.priceDiscount + order.optionPrice,
                          )}{' '}
                          원
                        </s.OrderTdProductPrice>
                      </s.OrderTdProductInfo>
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                  <s.OrderTd align="center">
                    {priceToString(order.count)} EA
                  </s.OrderTd>
                  <s.OrderTd align="center">
                    {priceToString(order.totalCost)} 원
                    <br />
                    (배송 {priceToString(order.priceDelivery)} 원)
                  </s.OrderTd>
                  <s.OrderTd align="center" isLast>
                    <s.OrderTdWrapper>
                      <s.OrderTdText>{order.buyerOrderStNm}</s.OrderTdText>
                      {order.orderStCd === 'ORDST12' ? (
                        <>
                          <s.OrderTdText>{order.deliveryComNm}</s.OrderTdText>
                          <s.OrderTdText>({order.deliveryNum})</s.OrderTdText>
                        </>
                      ) : null}
                      {order.orderStCd === 'ORDST01' ||
                      order.orderStCd === 'ORDST02' ||
                      order.orderStCd === 'ORDST11' ||
                      order.orderStCd === 'ORDST12' ? (
                        <s.OrderTdButton
                          background={theme.color.primary}
                          onClick={() =>
                            props.setUpdatingOrder({
                              ...order,
                              toCd: 'ORDST91',
                            })
                          }
                        >
                          취소요청
                        </s.OrderTdButton>
                      ) : order.orderStCd === 'ORDST13' ? (
                        <s.OrderTdButton
                          background={theme.color.primary}
                          onClick={() =>
                            props.setUpdatingOrder({
                              ...order,
                              toCd: 'ORDST14',
                            })
                          }
                        >
                          구매확정
                        </s.OrderTdButton>
                      ) : null}
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                </s.OrderTr>
              ))
            ) : (
              <s.OrderTr>
                <s.OrderTd align="center" colSpan={5} isLast>
                  <s.OrderTdWrapper>
                    <s.NoItem>
                      <AiOutlineShoppingCart
                        size={'5rem'}
                        color={theme.color.lightGrey}
                      />{' '}
                      아직 주문하신 내역이 없어요.
                      <s.LinkButton to="/">쇼핑하러가기</s.LinkButton>
                    </s.NoItem>
                  </s.OrderTdWrapper>
                </s.OrderTd>
              </s.OrderTr>
            )}
          </s.OrderTbody>
        </s.OrderTable>
      </s.BodyArea>
      {props.orderList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
      {props.showDetailPopup ? (
        <s.OrderDetailBackground>
          <s.OrderDetailPopup>
            <s.ClosePopupButton onClick={() => props.setSelectedOrder(null)}>
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>주문상세</s.PopupTitle>
            <s.OrderDetailTableTitle>구매내역</s.OrderDetailTableTitle>
            <s.OrderTable>
              <s.OrderTbody>
                <s.OrderTr>
                  <s.OrderTh style={{ width: '15%' }}>
                    주문일시/주문번호
                  </s.OrderTh>
                  <s.OrderTh style={{ width: '45%' }}>상품정보</s.OrderTh>
                  <s.OrderTh style={{ width: '10%' }}>수량</s.OrderTh>
                  <s.OrderTh style={{ width: '15%' }}>주문금액</s.OrderTh>
                  <s.OrderTh style={{ width: '25%' }}>진행상태</s.OrderTh>
                </s.OrderTr>
                <s.OrderTr>
                  <s.OrderTd align="center">
                    <s.OrderTdWrapper>
                      <s.OrderTdText color={theme.color.lightGrey}>
                        {moment(props.selectedOrder?.regDtm).format(
                          'YYYY.MM.DD.',
                        )}
                      </s.OrderTdText>
                      <s.OrderTdText>
                        {props.selectedOrder?.orderNum}
                      </s.OrderTdText>
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                  <s.OrderTd align="left">
                    <s.OrderTdWrapper direction="row">
                      <s.OrderTdImg src={props.selectedOrder?.thumbnail} />
                      <s.OrderTdProductInfo>
                        <s.OrderTdProductNm>
                          {`${props.selectedOrder?.productNm}_${props.selectedOrder?.optionNm}`}
                        </s.OrderTdProductNm>
                        <s.OrderTdModelNm>
                          {props.selectedOrder?.modelNm}
                        </s.OrderTdModelNm>
                        <s.OrderTdProductPrice>
                          판매가 :
                          {priceToString(
                            props.selectedOrder?.priceDiscount +
                              props.selectedOrder?.optionPrice,
                          )}{' '}
                          원
                        </s.OrderTdProductPrice>
                      </s.OrderTdProductInfo>
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                  <s.OrderTd align="center">
                    {priceToString(props.selectedOrder?.count)} EA
                  </s.OrderTd>
                  <s.OrderTd align="center">
                    {priceToString(props.selectedOrder?.totalCost)} 원
                    <br />
                    (배송 {priceToString(
                      props.selectedOrder?.priceDelivery,
                    )}{' '}
                    원)
                  </s.OrderTd>
                  <s.OrderTd align="center" isLast>
                    <s.OrderTdWrapper>
                      <s.OrderTdText>
                        {props.selectedOrder?.buyerOrderStNm}
                      </s.OrderTdText>
                      {props.selectedOrder?.orderStCd === 'ORDST01' ||
                      props.selectedOrder?.orderStCd === 'ORDST02' ||
                      props.selectedOrder?.orderStCd === 'ORDST11' ||
                      props.selectedOrder?.orderStCd === 'ORDST12' ? (
                        <s.OrderTdButton
                          background={theme.color.primary}
                          onClick={() =>
                            props.setUpdatingOrder({
                              ...props.selectedOrder,
                              toCd: 'ORDST91',
                            })
                          }
                        >
                          취소요청
                        </s.OrderTdButton>
                      ) : props.selectedOrder?.orderStCd === 'ORDST13' ? (
                        <s.OrderTdButton
                          background={theme.color.primary}
                          onClick={() =>
                            props.setUpdatingOrder({
                              ...props.selectedOrder,
                              toCd: 'ORDST14',
                            })
                          }
                        >
                          구매확정
                        </s.OrderTdButton>
                      ) : null}
                    </s.OrderTdWrapper>
                  </s.OrderTd>
                </s.OrderTr>
              </s.OrderTbody>
            </s.OrderTable>

            <s.OrderDetailTableTitle>수령인 정보</s.OrderDetailTableTitle>
            <s.OrderTable>
              <s.OrderTbody>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                      border: 0,
                    }}
                  >
                    수령인
                  </s.OrderTh>
                  <s.OrderTd
                    align="left"
                    isLast
                    style={{ border: 0, padding: 0 }}
                  >
                    {props.selectedOrder?.receiverNm}
                  </s.OrderTd>
                </s.OrderTr>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                      border: 0,
                    }}
                  >
                    연락처
                  </s.OrderTh>
                  <s.OrderTd
                    align="left"
                    isLast
                    style={{ border: 0, padding: 0 }}
                  >
                    {props.selectedOrder?.receiverMobno}
                  </s.OrderTd>
                </s.OrderTr>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                      border: 0,
                    }}
                  >
                    주소
                  </s.OrderTh>
                  <s.OrderTd
                    align="left"
                    isLast
                    style={{ border: 0, padding: 0 }}
                  >{`(${props.selectedOrder?.deliveryZipcode}) ${props.selectedOrder?.deliveryAddr}`}</s.OrderTd>
                </s.OrderTr>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                    }}
                  >
                    배송요청사항
                  </s.OrderTh>
                  <s.OrderTd align="left" isLast style={{ padding: 0 }}>
                    {props.selectedOrder?.orderRequest}
                  </s.OrderTd>
                </s.OrderTr>
              </s.OrderTbody>
            </s.OrderTable>

            <s.OrderDetailTableTitle>결제 정보</s.OrderDetailTableTitle>
            <s.OrderTable>
              <s.OrderTbody>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                      border: 0,
                    }}
                  >
                    결제수단
                  </s.OrderTh>
                  <s.OrderTd
                    align="left"
                    isLast
                    style={{ border: 0, padding: 0 }}
                  >
                    {props.selectedOrder?.paymentCd === 'V'
                      ? '가상계좌' +
                        ' (' +
                        props.selectedOrder?.vbankName +
                        ' ' +
                        props.selectedOrder?.vbankAccount +
                        ')'
                      : props.selectedOrder?.paymentCd === 'C'
                      ? '신용카드'
                      : props.selectedOrder?.paymentCd === 'K'
                      ? '카카오페이'
                      : props.selectedOrder?.paymentCd === 'N'
                      ? '네이버페이'
                      : props.selectedOrder?.paymentCd === 'T'
                      ? '토스페이'
                      : null}
                  </s.OrderTd>
                </s.OrderTr>
                <s.OrderTr>
                  <s.OrderTh
                    style={{
                      width: '15%',
                      color: theme.color.grey,
                      fontSize: '1.2rem',
                      textAlign: 'left',
                    }}
                  >
                    결제금액
                  </s.OrderTh>
                  <s.OrderTd
                    align="left"
                    isLast
                    style={{ padding: '0 0 1rem' }}
                  >
                    {priceToString(props.selectedOrder?.totalCost)} 원
                    <br />
                    (배송 {priceToString(
                      props.selectedOrder?.priceDelivery,
                    )}{' '}
                    원)
                  </s.OrderTd>
                </s.OrderTr>
              </s.OrderTbody>
            </s.OrderTable>
          </s.OrderDetailPopup>
        </s.OrderDetailBackground>
      ) : null}

      {props.showUpdatePopup ? (
        <s.PopupBackground>
          <s.OrderUpdatePopup>
            <s.ClosePopupButton onClick={() => props.setUpdatingOrder(null)}>
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>
              {props.updatingOrder?.toCd === 'ORDST14'
                ? '구매확정'
                : '취소요청'}
            </s.PopupTitle>
            {props.updatingOrder?.toCd === 'ORDST14' ? null : (
              <s.OrderPopupTable>
                <s.OrderPopupTbody>
                  <s.OrderPopupTr>
                    <s.OrderPopupTh style={{ width: '20rem' }}>
                      취소사유
                    </s.OrderPopupTh>
                    <s.OrderPopupTd style={{ width: '56rem' }}>
                      <s.GeneralInfoInput
                        type="text"
                        placeholder="취소사유를 입력하세요"
                        value={props.cancelRsn}
                        onChange={(e) => props.setCancelRsn(e.target.value)}
                        maxLength={100}
                      />
                    </s.OrderPopupTd>
                  </s.OrderPopupTr>
                  {props.updatingOrder?.paymentCd === 'V' ? (
                    <>
                      <s.OrderPopupTr>
                        <s.OrderPopupTh style={{ width: '20rem' }}>
                          환불은행
                        </s.OrderPopupTh>
                        <s.OrderPopupTd style={{ width: '56rem' }}>
                          <s.GeneralInfoInput
                            type="text"
                            placeholder="환불은행을 입력하세요"
                            value={props.cancelBankName}
                            onChange={(e) =>
                              props.setCancelBankName(e.target.value)
                            }
                            maxLength={50}
                          />
                        </s.OrderPopupTd>
                      </s.OrderPopupTr>
                      <s.OrderPopupTr>
                        <s.OrderPopupTh style={{ width: '20rem' }}>
                          환불계좌번호
                        </s.OrderPopupTh>
                        <s.OrderPopupTd style={{ width: '56rem' }}>
                          <s.GeneralInfoInput
                            type="text"
                            placeholder="계좌번호를 입력하세요"
                            value={props.cancelBankAccount}
                            onChange={(e) =>
                              props.setCancelBankAccount(e.target.value)
                            }
                            maxLength={50}
                          />
                        </s.OrderPopupTd>
                      </s.OrderPopupTr>
                      <s.OrderPopupTr>
                        <s.OrderPopupTh style={{ width: '20rem' }}>
                          환불계좌소유자
                        </s.OrderPopupTh>
                        <s.OrderPopupTd style={{ width: '56rem' }}>
                          <s.GeneralInfoInput
                            type="text"
                            placeholder="계좌소유자명을 입력하세요"
                            value={props.cancelBankHolderName}
                            onChange={(e) =>
                              props.setCancelBankHolderName(e.target.value)
                            }
                            maxLength={50}
                          />
                        </s.OrderPopupTd>
                      </s.OrderPopupTr>
                    </>
                  ) : null}
                </s.OrderPopupTbody>
              </s.OrderPopupTable>
            )}

            <s.SaveButton onClick={() => props.updateOrder()}>
              변경
            </s.SaveButton>
          </s.OrderUpdatePopup>
        </s.PopupBackground>
      ) : null}
    </s.Container>
  );
};

export default OrderPresenter;
