import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  min-width: 192rem;
  position: relative;
  height: 100vh;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 1400px) {
    height: auto;
    min-width: 0rem;
    width: 100vw;
  }
`;

export const HeaderItem = styled.div`
  width: 70%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const LogoArea = styled(Link)`
  width: 15rem;
  height: 8rem;
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const SignUpFormWrapper = styled.div`
  width: 70rem;
  padding: 5rem 2rem;
  margin: 5rem auto;
  border-radius: 2rem;
  border: 1px solid ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  @media screen and (max-width: 800px) {
    width: 95%;
  }
`;

export const SignUpFormTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const InputGroupLabel = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${theme.color.black};
`;

export const InputGroupInput = styled.input`
  width: 100%;
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  placeholder-color: ${theme.color.lightGrey};
  color: ${theme.color.black};
  border: 1px solid ${theme.color.lightGrey};

  &:focus {
    border: 1px solid ${theme.color.primary};

    background-color: ${theme.color.secondary};
  }
`;

export const InputGroupInputMiddle = styled.input`
  width: 45%;
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  placeholder-color: ${theme.color.lightGrey};
  color: ${theme.color.black};
  border: 1px solid ${theme.color.lightGrey};

  &:focus {
    border: 1px solid ${theme.color.primary};
    background-color: ${theme.color.secondary};
  }
`;

export const InputGroupMiddlePlainText = styled.div`
  width: 55%;
  font-size: 1.4rem;
  color: ${theme.color.black};
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

export const InputGroupHorizontalGroup = styled.div`
  width: 100%;
  gap: 1%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const InputGroupInputShort = styled.input`
  width: 30%;
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  font-size: 1.4rem;
  placeholder-color: ${theme.color.lightGrey};
  color: ${theme.color.black};
  border: 1px solid ${theme.color.lightGrey};

  &:focus {
    border: 1px solid ${theme.color.primary};
    background-color: ${theme.color.secondary};
  }
`;

export const InputGroupSelectShort = styled.select`
  width: 22%;
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  height: 4rem;
  font-size: 1.4rem;
  placeholder-color: ${theme.color.lightGrey};
  color: ${theme.color.black};
  border: 1px solid ${theme.color.lightGrey};

  &:focus {
    border: 1px solid ${theme.color.primary};
    background-color: ${theme.color.secondary};
  }
`;

export const InputGroupButtonShort = styled.div`
  width: 33%;
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  height: 4rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
  background-color: ${theme.color.grey};
  cursor: pointer;
`;

export const ContractWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  border: 1px solid ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  border-radius: 2rem;

  @media screen and (max-width: 800px) {
    padding: 1rem;
  }
`;

export const ContractAgreeAllRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 1rem 2rem 1.5rem;
  border-bottom: 1px soLid ${theme.color.lightGrey};
  margin-bottom: 1rem;
`;

export const ContractAgreeRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 2rem;
  position: relative;
`;

export const ContractAgreeCheckbox = styled.input`
  width: 2rem;
  height: 2rem;
  border-radius: 0.2rem;
  border: 1px solid ${theme.color.lightGrey};

  &:checked {
    background-color: ${theme.color.secondary};
    border: 1px solid ${theme.color.primary};
  }
`;

export const ContractAgreeCheckboxLabel = styled.label`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${theme.color.black};

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const ContractAgreeCheckboxLabelRequired = styled.span`
  color: #ff4545;
  font-weight: bold;
  margin-right: 0.5rem;
`;

export const ContractDetailButton = styled.div`
  width: 4rem;
  height: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 0rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 5rem auto;
  gap: 2rem;
`;

export const ButtonPrimary = styled.div`
  background-color: ${theme.color.primary};
  color: #ffffff;
  cursor: pointer;
  padding: 1.5rem 3rem;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 5rem;
  width: 15rem;
`;

export const ButtonSecondary = styled.div`
  background-color: #ffffff;
  color: ${theme.color.black};
  border: 1px solid ${theme.color.grey};
  border-radius: 5rem;
  cursor: pointer;
  padding: 1.5rem 3rem;
  font-size: 1.6rem;
  font-weight: 500;
  width: 15rem;
`;

export const AddressTdInputButton = styled.div`
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  background-color: ${theme.color.primary};
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`;

export const AddressTdInput = styled.input<{ width?: string }>`
  padding: 1rem 2rem;
  width: ${(props) => (props.width ? props.width : '40rem')};
  font-size: 1.4rem;
  border: 1px solid ${theme.color.lightGrey};
  border-radius: 5rem;
`;

export const AddressPopupWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 10%;
  width: 50rem;
  height: 70rem;
  z-index: 9999999999;
  border: 1px solid ${theme.color.lightGrey};
  box-shadow: 0 0 0.3rem 0.3rem ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const AddressPopupTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${theme.color.primary};
  font-weight: 500;
  padding: 1rem;
  text-align: left;
`;

export const AddressPopupCloseButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;
