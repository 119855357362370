import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import HeaderPresenter from './HeaderPresenter';

import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';

const HeaderContainer = () => {
  const [currentFocus, setCurrentFocus] = useState<number>(-1);
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [token, setToken] = useRecoilState(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const logout = () => {
    setIsLoading(true);
    setUserInfo({
      userId: '',
      userNm: '',
      userEmail: '',
      userTypeCd: '',
    });
    setToken(null);
    setShowLoginModal(false);
    setShowMobileSidebar(false);
    makeMsg('로그아웃 되었습니다', 'success');
    setIsLoading(false);
  };

  return (
    <>
      <HeaderPresenter
        currentFocus={currentFocus}
        showMobileSidebar={showMobileSidebar}
        showLoginModal={showLoginModal}
        userInfo={userInfo}
        comInfo={comInfo}
        setCurrentFocus={setCurrentFocus}
        setShowMobileSidebar={setShowMobileSidebar}
        setShowLoginModal={setShowLoginModal}
        logout={logout}
      />
    </>
  );
};

HeaderContainer.defaultProps = {};

export default HeaderContainer;
