import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  min-width: 192rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const TopSelectArea = styled.div`
  width: 100%;
  height: 5rem;
  min-width: 192rem;
  margin: 0 auto 0.5rem;
  text-align: center;
  box-shadow: 0 0.5rem 0.3rem 0.3rem #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TopItemWrapper = styled.div`
  width: 130rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TopSelectItem = styled.div<{ focused: boolean }>`
  width: 15rem;
  height: 100%;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.focused ? theme.color.primary : theme.color.black};
  border-bottom: ${(props) =>
    props.focused ? `0.3rem solid ${theme.color.primary}` : null};
  cursor: pointer;
`;

export const ContentArea = styled.div`
  width: 100%;
  min-height: 60rem;
`;
