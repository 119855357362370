import React, { Dispatch, SetStateAction } from 'react';
import * as s from './UpsertProductStyled';
import { Link } from 'react-router-dom';
import { makeMsg, priceToString } from 'util/util';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageResize } from 'quill-image-resize-module-ts';
import { Quill } from 'react-quill';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
import Pagination from 'react-js-pagination';
import { FaMinusCircle } from 'react-icons/fa';
const Size = Quill.import('attributors/style/size');
Size.whitelist = [
  false,
  '9px',
  '10px',
  '11px',
  '12px',
  '16px',
  '18px',
  '20px',
  '22px',
  '26px',
  '28px',
];
Quill.register('modules/ImageResize', ImageResize);
Quill.register(Size, true);

interface UpsertProductProps {
  userInfo: any;
  categoryLv1List: any[];
  categoryLv2List: any[];
  categoryLv3List: any[];
  categoryLv4List: any[];
  categoryLv1: any;
  categoryLv2: any;
  categoryLv3: any;
  categoryLv4: any;
  setCategoryLv1: Dispatch<SetStateAction<any>>;
  setCategoryLv2: Dispatch<SetStateAction<any>>;
  setCategoryLv3: Dispatch<SetStateAction<any>>;
  setCategoryLv4: Dispatch<SetStateAction<any>>;

  connectedCategoryList: any[];
  setConnectedCategoryList: Dispatch<SetStateAction<any[]>>;

  addCategory: () => void;
  removeCategory: (idx: number) => void;

  sellYn: string;
  setSellYn: Dispatch<SetStateAction<string>>;
  bestYn: string;
  setBestYn: Dispatch<SetStateAction<string>>;
  productNm: string;
  setProductNm: Dispatch<SetStateAction<string>>;
  modelNm: string;
  setModelNm: Dispatch<SetStateAction<string>>;
  brandNm: string;
  setBrandNm: Dispatch<SetStateAction<string>>;
  keyword: string;
  setKeyword: Dispatch<SetStateAction<string>>;
  thumbnail: any;
  setThumbnail: Dispatch<SetStateAction<any>>;
  thumbnailPreview: string;
  price: number;
  setPrice: Dispatch<SetStateAction<number>>;
  priceDiscount: number;
  setPriceDiscount: Dispatch<SetStateAction<number>>;
  priceSupply: number;
  setPriceSupply: Dispatch<SetStateAction<number>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;

  isOpenOptionPopup: boolean;
  setIsOpenOptionPopup: Dispatch<SetStateAction<boolean>>;
  isOpenDeliveryPopup: boolean;
  setIsOpenDeliveryPopup: Dispatch<SetStateAction<boolean>>;

  deliveryPage: number;
  setDeliveryPage: Dispatch<SetStateAction<number>>;
  deliveryPageOffset: number;
  deliveryConditionNm: string;
  setDeliveryConditionNm: Dispatch<SetStateAction<string>>;
  deliveryCompanyId: string;
  setDeliveryCompanyId: Dispatch<SetStateAction<string>>;
  deliveryConditionTypeCd: string;
  setDeliveryConditionTypeCd: Dispatch<SetStateAction<string>>;
  deliveryConditionTypeList: any[];
  deliveryCompanyList: any[];
  deliveryTotalCount: number;
  deliveryConditionList: any[];
  handleDeliveryPageChange: (page: number) => void;
  deliverySearch: () => void;
  deliveryCondition: any;
  setDeliveryCondition: Dispatch<SetStateAction<any>>;
  options: any[];
  setOptions: Dispatch<SetStateAction<any[]>>;
  confirmedOptions: any[];
  setConfirmedOptions: Dispatch<SetStateAction<any[]>>;
  openOptionPopup: () => void;
  closeOptionPopup: () => void;
  saveOption: () => void;
  saveProduct: () => void;
  prevProduct: any;
  deleteProduct: () => void;
}

const UpsertProductPresenter = (props: UpsertProductProps) => {
  const modules = {
    toolbar: [
      // [{ font: [] }],
      [{ header: [1, 2, false] }],
      // [
      //   {
      //     size: Size.whitelist,
      //     // size: [],
      //   },
      // ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],

      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
    ImageResize: {
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
  };

  const formats = [
    //'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>상품등록</s.PageTitle>
        <s.PageIndicator>{'상품관리 > 상품등록'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.CategoryArea>
        <s.SectionTitle>- 카테고리 연결</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh center={true} color={'#ffffff'}>
                대분류
              </s.SectionTh>
              <s.SectionTh center={true} color={'#ffffff'}>
                중분류
              </s.SectionTh>
              <s.SectionTh center={true} color={'#ffffff'}>
                소분류
              </s.SectionTh>
              <s.SectionTh center={true} color={'#ffffff'}>
                세분류
              </s.SectionTh>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTd
                noPad={true}
                style={{ height: '15rem', width: '25%' }}
              >
                <s.CategoryItemWrapper>
                  {props.categoryLv1List.map((category: any) => (
                    <s.CategoryItem
                      selected={
                        props.categoryLv1?.categoryCd === category.categoryCd
                      }
                      onClick={() => props.setCategoryLv1(category)}
                    >
                      {category.categoryNm}
                    </s.CategoryItem>
                  ))}
                </s.CategoryItemWrapper>
              </s.SectionTd>
              <s.SectionTd
                noPad={true}
                style={{ height: '15rem', width: '25%' }}
              >
                <s.CategoryItemWrapper>
                  {props.categoryLv2List.map((category: any) => (
                    <s.CategoryItem
                      selected={
                        props.categoryLv2?.categoryCd === category.categoryCd
                      }
                      onClick={() => props.setCategoryLv2(category)}
                    >
                      {category.categoryNm}
                    </s.CategoryItem>
                  ))}
                </s.CategoryItemWrapper>
              </s.SectionTd>
              <s.SectionTd
                noPad={true}
                style={{ height: '15rem', width: '25%' }}
              >
                <s.CategoryItemWrapper>
                  {props.categoryLv3List.map((category: any) => (
                    <s.CategoryItem
                      selected={
                        props.categoryLv3?.categoryCd === category.categoryCd
                      }
                      onClick={() => props.setCategoryLv3(category)}
                    >
                      {category.categoryNm}
                    </s.CategoryItem>
                  ))}
                </s.CategoryItemWrapper>
              </s.SectionTd>
              <s.SectionTd
                noPad={true}
                style={{ height: '15rem', width: '25%' }}
              >
                <s.CategoryItemWrapper>
                  {props.categoryLv4List.map((category: any) => (
                    <s.CategoryItem
                      selected={
                        props.categoryLv4?.categoryCd === category.categoryCd
                      }
                      onClick={() => props.setCategoryLv4(category)}
                    >
                      {category.categoryNm}
                    </s.CategoryItem>
                  ))}
                </s.CategoryItemWrapper>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>

        <s.AddCategoryButton onClick={() => props.addCategory()}>
          ⬇ 선택추가
        </s.AddCategoryButton>
        <s.SectionTitle>- 선택된 카테고리</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '8%' }} center color={'#ffffff'}>
                대표설정
              </s.SectionTh>
              <s.SectionTh style={{ width: '84%' }} center color={'#ffffff'}>
                연결된 카테고리
              </s.SectionTh>
              <s.SectionTh style={{ width: '8%' }} center color={'#ffffff'}>
                연결해제
              </s.SectionTh>
            </s.SectionTr>
            {props.connectedCategoryList.length > 0 ? (
              props.connectedCategoryList.map((category: any, index: any) => (
                <s.SectionTr>
                  <s.SectionTd>
                    <s.CategoryMainCheckbox
                      type="checkbox"
                      checked={category.mainCategory === 'Y'}
                      onChange={() => {
                        const temp = props.connectedCategoryList;
                        temp[index].mainCategory =
                          temp[index].mainCategory === 'Y' ? 'N' : 'Y';
                        props.setConnectedCategoryList([...temp]);
                      }}
                    />
                  </s.SectionTd>
                  <s.SectionTd align="left">
                    {category.categoryString}
                  </s.SectionTd>
                  <s.SectionTd>
                    <s.CategoryListButton
                      onClick={() => props.removeCategory(index)}
                    >
                      연결해제
                    </s.CategoryListButton>
                  </s.SectionTd>
                </s.SectionTr>
              ))
            ) : (
              <s.SectionTr>
                <s.SectionTd colSpan={3}>
                  연결할 카테고리를 선택해주세요.
                </s.SectionTd>
              </s.SectionTr>
            )}
          </s.SectionTbody>
        </s.SectionTable>
      </s.CategoryArea>

      <s.GeneralInfoArea>
        <s.SectionTitle>- 진열 및 판매상태</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>판매상태</s.SectionTh>
              <s.SectionTd style={{ width: '90%' }}>
                <s.GeneralInfoTdWrapper>
                  <s.GeneralInfoInputWrapper>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'Y'}
                      onChange={() => props.setSellYn('Y')}
                    ></s.GeneralInfoRadio>
                    <s.GeneralInfoLabel>판매함</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'N'}
                      onChange={() => props.setSellYn('N')}
                    ></s.GeneralInfoRadio>
                    <s.GeneralInfoLabel>판매안함</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.sellYn === 'R'}
                      onChange={() => props.setSellYn('R')}
                    ></s.GeneralInfoRadio>
                    <s.GeneralInfoLabel>품절</s.GeneralInfoLabel>
                  </s.GeneralInfoInputWrapper>
                </s.GeneralInfoTdWrapper>
              </s.SectionTd>
            </s.SectionTr>
            {props.userInfo.userTypeCd === 'MASTERADMIN' ||
            props.userInfo.userTypeCd === 'ADMIN' ? (
              <s.SectionTr>
                <s.SectionTh style={{ width: '10%' }}>
                  Best상품 노출 여부
                </s.SectionTh>
                <s.SectionTd style={{ width: '90%' }}>
                  <s.GeneralInfoTdWrapper>
                    <s.GeneralInfoInputWrapper>
                      <s.GeneralInfoRadio
                        type="radio"
                        checked={props.bestYn === 'Y'}
                        onClick={() => props.setBestYn('Y')}
                      ></s.GeneralInfoRadio>
                      <s.GeneralInfoLabel>노출</s.GeneralInfoLabel>
                      <s.GeneralInfoRadio
                        type="radio"
                        checked={props.bestYn === 'N'}
                        onClick={() => props.setBestYn('N')}
                      ></s.GeneralInfoRadio>
                      <s.GeneralInfoLabel>노출안함</s.GeneralInfoLabel>
                    </s.GeneralInfoInputWrapper>
                  </s.GeneralInfoTdWrapper>
                </s.SectionTd>
              </s.SectionTr>
            ) : null}
          </s.SectionTbody>
        </s.SectionTable>
        <s.SectionTitle>- 상품 기본정보</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '20%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>상품명
              </s.SectionTh>
              <s.SectionTd style={{ width: '80%' }} noPad align={'left'}>
                <s.GeneralInfoInput
                  type="text"
                  value={props.productNm}
                  onChange={(e) => props.setProductNm(e.target.value)}
                  maxLength={200}
                  placeholder={'ex) 55"TV'}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '20%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>모델명
              </s.SectionTh>
              <s.SectionTd style={{ width: '80%' }} noPad align={'left'}>
                <s.GeneralInfoInput
                  type="text"
                  value={props.modelNm}
                  onChange={(e) => props.setModelNm(e.target.value)}
                  maxLength={200}
                  placeholder={'ex) TVEP01ZN'}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '20%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>브랜드명
              </s.SectionTh>
              <s.SectionTd style={{ width: '80%' }} noPad align={'left'}>
                <s.GeneralInfoInput
                  type="text"
                  value={props.brandNm}
                  onChange={(e) => props.setBrandNm(e.target.value)}
                  width={'10rem'}
                  maxLength={200}
                  placeholder={'ex) LG'}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '20%' }}>검색키워드</s.SectionTh>
              <s.SectionTd style={{ width: '80%' }} noPad align={'left'}>
                <s.GeneralInfoInput
                  type="text"
                  value={props.keyword}
                  onChange={(e) => props.setKeyword(e.target.value)}
                  maxLength={400}
                  placeholder={'ex) 56인치,TV,55인치,대형TV,LG,TVEP01ZN....'}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '20%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>대표이미지
              </s.SectionTh>
              <s.SectionTd style={{ width: '80%' }} align={'left'} noPad>
                <s.SectionTdText>
                  사이즈 : 500px * 500px의 정사각형 이미지를 사용해주세요.
                </s.SectionTdText>
                <s.SectionTdText>
                  용량 : 300kb보다 작은 이미지를 사용해주세요
                </s.SectionTdText>
                <s.SectionTdText>
                  파일형식 : JPG, JPEG, PNG에 한해 등록이 가능합니다.
                </s.SectionTdText>
                <s.GeneralInfoThumbnailPreview src={props.thumbnailPreview} />
                <s.GeneralInfoInput
                  type="file"
                  width="95%"
                  onChange={(e) => {
                    e.target.files && props.setThumbnail(e.target.files[0]);
                  }}
                  placeholder="썸네일 파일을 업로드하세요."
                  accept={'.jpg,.jpeg,.png,.webP'}
                />
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
        <s.SectionTitle>- 상품 판매정보</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>출하가
              </s.SectionTh>
              <s.SectionTd noPad>
                <s.GeneralInfoTdWrapper
                  style={{
                    alignItems: 'center',
                    gap: '1rem',
                    fontSize: '1.4rem',
                  }}
                >
                  <s.GeneralInfoInput
                    type="text"
                    width={'25rem'}
                    pattern="[0-9,]*"
                    value={priceToString(props.price)}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setPrice(
                            Number(e.target.value.replaceAll(',', '')),
                          )
                        : makeMsg('숫자만 입력 가능합니다.', 'warning')
                    }
                  />
                  원
                </s.GeneralInfoTdWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>판매가
              </s.SectionTh>
              <s.SectionTd noPad>
                <s.GeneralInfoTdWrapper
                  style={{
                    alignItems: 'center',
                    gap: '1rem',
                    fontSize: '1.4rem',
                  }}
                >
                  <s.GeneralInfoInput
                    type="text"
                    width={'25rem'}
                    pattern="[0-9,]*"
                    value={priceToString(props.priceDiscount)}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setPriceDiscount(
                            Number(e.target.value.replaceAll(',', '')),
                          )
                        : makeMsg('숫자만 입력 가능합니다.', 'warning')
                    }
                  />
                  원
                </s.GeneralInfoTdWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>공급가
              </s.SectionTh>
              <s.SectionTd noPad>
                <s.GeneralInfoTdWrapper
                  style={{
                    alignItems: 'center',
                    gap: '1rem',
                    fontSize: '1.4rem',
                  }}
                >
                  <s.GeneralInfoInput
                    type="text"
                    width={'25rem'}
                    pattern="[0-9,]*"
                    value={priceToString(props.priceSupply)}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setPriceSupply(
                            Number(e.target.value.replaceAll(',', '')),
                          )
                        : makeMsg('숫자만 입력 가능합니다.', 'warning')
                    }
                  />
                  원
                </s.GeneralInfoTdWrapper>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
        <s.SectionTitle>- 상세정보입력</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '10%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>
                제품상세 영역
              </s.SectionTh>
              <s.SectionTd noPad align="left">
                <s.GeneralInfoTdVerticalWrapper>
                  <s.SectionTdText
                    style={{
                      fontWeight: '500',
                      margin: '1rem',
                      fontSize: '1.4rem',
                    }}
                  >
                    ❖ 상품정보, 제품사양 및 주의사항, 인증정보, 반품교환정보
                    등을 노출하는 자리입니다.
                  </s.SectionTdText>
                  <s.SectionTdText style={{ margin: '0 1rem' }}>
                    1. HTML직접입력만 가능하고 등록할 내용이 없다면 등록하지
                    않아도 됩니다.
                  </s.SectionTdText>
                  <s.SectionTdText
                    style={{ color: '#ff0000', margin: '0 1rem' }}
                  >
                    2. 상품상세 외부 이미지 주소를 반드시 http://로 해주세요.
                  </s.SectionTdText>
                  <s.SectionTdText style={{ margin: '0 1rem' }}>
                    3. 이미지 크기 제한 : 개별 제한은 10mb, 전체 100mb 정도까지
                    등록 가능
                  </s.SectionTdText>
                  <s.QuillWrapper>
                    <ReactQuill
                      style={{ height: '45rem' }}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={props.content || ''}
                      placeholder={'글을 입력하세요.'}
                      onChange={(content, delta, source, editor) =>
                        props.setContent(editor.getHTML())
                      }
                    />
                  </s.QuillWrapper>
                </s.GeneralInfoTdVerticalWrapper>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
        <s.SectionTitle>- 옵션정보</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>옵션</s.SectionTh>
              <s.SectionTd>
                <s.OpenPopupButton onClick={() => props.openOptionPopup()}>
                  옵션관리
                </s.OpenPopupButton>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
        <s.SectionTitle>- 배송정책</s.SectionTitle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>배송정책</s.SectionTh>
              <s.SectionTd align="left">
                <s.OpenPopupButton
                  onClick={() => props.setIsOpenDeliveryPopup(true)}
                >
                  배송선택
                </s.OpenPopupButton>
                {props.deliveryCondition?.deliveryConditionId ? (
                  <s.SectionTdText>
                    {props.deliveryCondition.deliveryConditionNm} /{' '}
                    {priceToString(props.deliveryCondition.price)} 원 /{' '}
                    {props.deliveryCondition.deliveryCompanyNm} / 제주 :{' '}
                    {priceToString(props.deliveryCondition.additionalPriceJeju)}{' '}
                    원 / 도서산간 :{' '}
                    {priceToString(
                      props.deliveryCondition.additionalPriceMountain,
                    )}{' '}
                    원 <br /> 출고지 : {props.deliveryCondition.outputAddress}{' '}
                    <br />
                    반품/교환 : {props.deliveryCondition.returnChangeAddress}
                  </s.SectionTdText>
                ) : null}
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.GeneralInfoArea>
      <s.ButtonWrapper>
        <s.SaveButton onClick={() => props.saveProduct()}>저장</s.SaveButton>
        {props.prevProduct?.productId ? (
          <s.SaveButton onClick={() => props.deleteProduct()}>
            상품 삭제
          </s.SaveButton>
        ) : null}
      </s.ButtonWrapper>
      {props.isOpenOptionPopup ? (
        <s.PopupArea>
          <s.ClosePopupButton onClick={() => props.closeOptionPopup()}>
            <MdClose size={'100%'} color={theme.color.black} />
          </s.ClosePopupButton>
          <s.PopupTitle>옵션설정</s.PopupTitle>
          <s.SectionTable>
            <s.SectionTbody>
              <s.SectionTr>
                <s.SectionTh style={{ width: '15%' }}>옵션</s.SectionTh>
                <s.SectionTd>
                  <s.OpenPopupButton
                    style={{ marginBottom: '1rem' }}
                    onClick={() => {
                      const temp = props.options;
                      temp.push({
                        optionNm: '',
                        price: '0',
                        priceSupply: '0',
                        stock: '0',
                      });
                      props.setOptions([...temp]);
                    }}
                  >
                    추가하기
                  </s.OpenPopupButton>
                  {props.options.map((option: any, index: number) => (
                    <s.SectionTable>
                      <s.RemoveOptionButton
                        onClick={() => {
                          const temp = props.options;
                          temp.splice(index, 1);
                          props.setOptions([...temp]);
                        }}
                      >
                        <FaMinusCircle
                          color={theme.color.orange}
                          size={'100%'}
                        />
                      </s.RemoveOptionButton>
                      <s.SectionTbody>
                        <s.SectionTh style={{ width: '15%' }}>
                          옵션명
                        </s.SectionTh>
                        <s.SectionTd width="35%" align="left" noPad>
                          <s.GeneralInfoInput
                            type="text"
                            value={option.optionNm}
                            width="15rem"
                            onChange={(e) => {
                              const temp = props.options;
                              temp[index].optionNm = e.target.value;
                              props.setOptions([...temp]);
                            }}
                          />
                        </s.SectionTd>
                        <s.SectionTh style={{ width: '15%' }}>
                          옵션값
                        </s.SectionTh>
                        <s.SectionTd width="35%" align="left">
                          <s.GeneralInfoInputWrapper>
                            공급가
                            <s.GeneralInfoInput
                              type="text"
                              pattern="[0-9,]*"
                              width="15rem"
                              value={priceToString(
                                Number(
                                  String(option.priceSupply).replaceAll(
                                    ',',
                                    '',
                                  ),
                                ),
                              )}
                              onChange={(e) => {
                                if (e.target.validity.valid) {
                                  const temp = props.options;
                                  temp[index].priceSupply = String(
                                    e.target.value.replaceAll(',', ''),
                                  );
                                  props.setOptions([...temp]);
                                } else {
                                  makeMsg('숫자만 입력하세요.', 'warning');
                                }
                              }}
                            />
                          </s.GeneralInfoInputWrapper>
                          <s.GeneralInfoInputWrapper>
                            판매가
                            <s.GeneralInfoInput
                              type="text"
                              pattern="[0-9,]*"
                              width="15rem"
                              value={priceToString(
                                Number(
                                  String(option.price).replaceAll(',', ''),
                                ),
                              )}
                              onChange={(e) => {
                                if (e.target.validity.valid) {
                                  const temp = props.options;
                                  temp[index].price = String(
                                    e.target.value.replaceAll(',', ''),
                                  );
                                  props.setOptions([...temp]);
                                } else {
                                  makeMsg('숫자만 입력하세요.', 'warning');
                                }
                              }}
                            />
                          </s.GeneralInfoInputWrapper>
                          <s.GeneralInfoInputWrapper>
                            재고
                            <s.GeneralInfoInput
                              type="text"
                              pattern="[0-9,]*"
                              width="15rem"
                              value={priceToString(
                                Number(
                                  String(option.stock)?.replaceAll(',', ''),
                                ),
                              )}
                              onChange={(e) => {
                                if (e.target.validity.valid) {
                                  const temp = props.options;
                                  temp[index].stock = String(
                                    e.target.value.replaceAll(',', ''),
                                  );
                                  props.setOptions([...temp]);
                                } else {
                                  makeMsg('숫자만 입력하세요.', 'warning');
                                }
                              }}
                            />
                          </s.GeneralInfoInputWrapper>
                        </s.SectionTd>
                      </s.SectionTbody>
                    </s.SectionTable>
                  ))}
                </s.SectionTd>
              </s.SectionTr>
            </s.SectionTbody>
          </s.SectionTable>
          <s.ButtonWrapper>
            <s.SaveButton onClick={() => props.saveOption()}>저장</s.SaveButton>
          </s.ButtonWrapper>
        </s.PopupArea>
      ) : null}

      {props.isOpenDeliveryPopup ? (
        <s.PopupArea>
          <s.ClosePopupButton
            onClick={() => props.setIsOpenDeliveryPopup(false)}
          >
            <MdClose size={'100%'} color={theme.color.black} />
          </s.ClosePopupButton>

          <s.PopupTitle>배송정책선택</s.PopupTitle>
          <s.SectionTable>
            <s.SectionTbody>
              <s.SectionTr>
                <s.SectionTh style={{ width: '15%' }}>배송조건명</s.SectionTh>
                <s.SectionTd noPad align="left" width="85%">
                  <s.GeneralInfoInput
                    type="text"
                    value={props.deliveryConditionNm}
                    onChange={(e) =>
                      props.setDeliveryConditionNm(e.target.value)
                    }
                    placeholder="배송조건명을 입력하세요."
                  />
                </s.SectionTd>
              </s.SectionTr>
              <s.SectionTr>
                <s.SectionTh style={{ width: '15%' }}>택배사</s.SectionTh>
                <s.SectionTd noPad align="left" width="85%">
                  <s.GeneralInfoSelect
                    width={'20rem'}
                    value={props.deliveryCompanyId}
                    onChange={(e) => props.setDeliveryCompanyId(e.target.value)}
                  >
                    <option value="">선택</option>
                    {props.deliveryCompanyList.map((company: any) => (
                      <option value={company.deliveryCompanyId}>
                        {company.deliveryCompanyNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                </s.SectionTd>
              </s.SectionTr>
              <s.SectionTr>
                <s.SectionTh style={{ width: '15%' }}>배송조건유형</s.SectionTh>
                <s.SectionTd noPad align="left" width="85%">
                  <s.GeneralInfoSelect
                    width={'20rem'}
                    value={props.deliveryConditionTypeCd}
                    onChange={(e) =>
                      props.setDeliveryConditionTypeCd(e.target.value)
                    }
                  >
                    <option value="">선택</option>
                    {props.deliveryConditionTypeList.map((condition: any) => (
                      <option value={condition.comnCd}>
                        {condition.comnCdNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                </s.SectionTd>
              </s.SectionTr>
            </s.SectionTbody>
          </s.SectionTable>
          <s.ButtonWrapper>
            <s.SaveButton onClick={() => props.deliverySearch()}>
              조회
            </s.SaveButton>
          </s.ButtonWrapper>
          <s.DataArea>
            <s.SectionTable>
              <s.SectionTbody>
                <s.SectionTr>
                  <s.SectionTh center>배송비조건명</s.SectionTh>
                  <s.SectionTh center>배송비유형</s.SectionTh>
                  <s.SectionTh center>배송비</s.SectionTh>
                  <s.SectionTh center>지역별배송비(제주)</s.SectionTh>
                  <s.SectionTh center>지역별배송비(도서산간)</s.SectionTh>
                  <s.SectionTh center>배송사</s.SectionTh>
                </s.SectionTr>
                {props.deliveryConditionList.length < 1 ? (
                  <s.SectionTr>
                    <s.SectionTd colSpan={7} style={{ height: '35rem' }}>
                      조회값이 없습니다.
                    </s.SectionTd>
                  </s.SectionTr>
                ) : (
                  props.deliveryConditionList.map((condition: any) => (
                    <s.SectionTr
                      onClick={() => {
                        props.setDeliveryCondition(condition);
                        props.setIsOpenDeliveryPopup(false);
                      }}
                      hoverActive
                    >
                      <s.SectionTd>{condition.deliveryConditionNm}</s.SectionTd>
                      <s.SectionTd>
                        {condition.deliveryConditionTypeNm}
                      </s.SectionTd>
                      <s.SectionTd>
                        {condition.price && Number(condition.price) !== 0
                          ? priceToString(condition.price)
                          : '무료'}
                      </s.SectionTd>
                      <s.SectionTd>
                        {condition.additionalPriceJeju
                          ? priceToString(condition.additionalPriceJeju)
                          : '없음'}
                      </s.SectionTd>
                      <s.SectionTd>
                        {condition.additionalPriceMountain
                          ? priceToString(condition.additionalPriceMountain)
                          : '없음'}
                      </s.SectionTd>
                      <s.SectionTd>{condition.deliveryCompanyNm}</s.SectionTd>
                    </s.SectionTr>
                  ))
                )}
              </s.SectionTbody>
            </s.SectionTable>
          </s.DataArea>
          {props.deliveryConditionList.length < 1 ? null : (
            <s.PaginationWrapper>
              <Pagination
                activePage={props.deliveryPage}
                itemsCountPerPage={props.deliveryPageOffset}
                totalItemsCount={props.deliveryTotalCount}
                pageRangeDisplayed={5}
                firstPageText={''}
                lastPageText={''}
                prevPageText={'◀'}
                nextPageText={'▶'}
                onChange={props.handleDeliveryPageChange}
              />
            </s.PaginationWrapper>
          )}
        </s.PopupArea>
      ) : null}
    </s.Container>
  );
};

export default UpsertProductPresenter;
