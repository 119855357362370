import { notLoginedApi, loginedApi } from './api-base';

export const userApi = {
  login: (Data: any) => notLoginedApi.post('/pd/user/mgnt/login', Data),

  insert: (Data: any) => notLoginedApi.post('/pd/user/mgnt/insert', Data),

  getList: (token: any, Data: any) =>
    loginedApi.post('/pd/user/mgnt/getList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  update: (token: any, Data: any) =>
    loginedApi.post('/pd/user/mgnt/update', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
