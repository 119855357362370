import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  // min-width: 192rem;
  position: relative;
  height: 100vh;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  left: 40rem;
  top: 20rem;
  width: 60rem;
  height: 65rem;
  border-radius: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.veryLightGrey};

  @media screen and (max-width: 800px) {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 95%;
  }
`;

export const LogoArea = styled.img`
  width: 15rem;
  height: 8rem;
  object-fit: contain;
`;

export const LoginTitle = styled.div`
  font-size: 6rem;
  color: ${theme.color.black};
  font-weight: 500;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`;

export const LoginSubtitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: 300;
  margin: 1rem auto 2rem;
`;

export const LoginInputWrapper = styled.div`
  width: 90%;
  border-radius: 5rem;
  border: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem 2rem;
  background-color: #ffffff;
  margin: 0.5rem 0;
`;

export const LoginInputLabel = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 1.6rem;
  color: #9d9d9d;
  font-weight: 400;
  background-color: #ffffff;
`;

export const LoginInput = styled.input`
  width: 80%;
  border: 0px;
  font-size: 1.6rem;
  color: ${theme.color.black};
  padding: 1rem;
  background-color: #ffffff;
`;

export const LoginButton = styled.div`
  width: 90%;
  border-radius: 5rem;
  background-color: ${theme.color.primary};
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  font-size: 1.6rem;
  margin: 3rem 0;
`;

export const SignUpButton = styled(Link)`
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: 500;
`;
