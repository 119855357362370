import React from 'react';
import * as s from './SignUpStyled';
import { Link } from 'react-router-dom';

interface SignUpProps {
  comInfo: any;
}

const SignUpPresenter = (props: SignUpProps) => {
  return (
    <s.Container>
      <s.BackgroundImage src={props.comInfo.background} />
      <s.BackgroundImageWrapper>
        <s.LogoArea src={props.comInfo.logo} />
        <s.ButtonRow>
          <s.ButtonItem>
            <s.ButtonTitle>기업 회원가입 신청</s.ButtonTitle>
            <s.ButtonTexts>
              중소기업 복지 플랫폼을 이용을 원하시는
              <br />
              기업의 대표 또는 복지관련 담당자만 신청이 가능합니다.
            </s.ButtonTexts>
            <s.RegisterButton to="/signUpForm/SUP">신청하기</s.RegisterButton>
          </s.ButtonItem>
          <s.ButtonItem>
            <s.ButtonTitle>개인 회원가입 신청</s.ButtonTitle>
            <s.ButtonTexts>
              중소기업 복지 플랫폼을 이용을 원하시는
              <br />
              기업의 대표 또는 복지관련 담당자만 신청이 가능합니다.
            </s.ButtonTexts>
            <s.RegisterButton to="/signUpForm/BUY">신청하기</s.RegisterButton>
          </s.ButtonItem>
        </s.ButtonRow>
      </s.BackgroundImageWrapper>
    </s.Container>
  );
};

export default SignUpPresenter;
