import { notLoginedApi, loginedApi } from './api-base';

export const productApi = {
  getList: (Data: any) => notLoginedApi.post('/prod/mgnt/getList', Data),

  detail: (Data: any) => notLoginedApi.post('/prod/mgnt/detail', Data),

  insert: (token: any, Data: any) =>
    loginedApi.post('/prod/mgnt/insert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  update: (token: any, Data: any) =>
    loginedApi.post('/prod/mgnt/update', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  delete: (token: any, Data: any) =>
    loginedApi.post('/prod/mgnt/deleteProduct', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
