import styled from 'styled-components';
import theme from '../../../../../components/styles/theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const MyPageTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const MyPageTbody = styled.tbody``;

export const MyPageTr = styled.tr`
  cursor: pointer;
`;

export const MyPageTh = styled.th<{ align?: string; width?: string }>`
  padding: 2rem 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black}
  text-align: ${(props) => (props.align ? props.align : 'center')};
  background-color: ${theme.color.veryLightGrey};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-bottom: 1px solid ${theme.color.lightGrey};

  @media screen and (max-width: 800px) {
    width: 25%;
    font-size: 1.2rem;
  }
`;

export const MyPageTd = styled.td<{ align?: string; width?: string }>`
  padding: 2rem 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black}
  text-align: ${(props) => (props.align ? props.align : 'left')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  border-bottom: 1px solid ${theme.color.lightGrey};

  @media screen and (max-width: 800px) {
    width: 75%;
    font-size: 1.2rem;
  }
`;

export const MyPageTdButton = styled.div`
  width: 15rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.primary};
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  border-radius: 5rem;
`;

export const UpdatePasswordBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const UpdatePasswordPopup = styled.div`
  width: 60rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 2rem;
  position: relative;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: 95%;
  }
`;

export const PopupTitle = styled.div`
  font-size: 1.6rem;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid ${theme.color.black};
  color: ${theme.color.black};
  padding: 1rem 0;
  width: 100%:
`;

export const ClosePopupButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const PopupRow = styled.div`
  width: 100%;
  display: flex;
`;

export const PopupRowLabel = styled.div`
  width: 12rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: ${theme.color.black};
  text-align: left;
  padding: 1rem 1rem 1rem 0;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
    width: 30%;
  }
`;

export const PopupRowInput = styled.input`
  width: 48rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
  placeholder-color: ${theme.color.lightGrey};
  border: 1px solid ${theme.color.black};
  border-radius: 0.5rem;

  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
    width: 70%;
  }
`;

export const PopupButton = styled.div`
  width: 15rem;
  padding: 1rem;
  margin: 2rem auto 0;
  cursor: pointer;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  color: #ffffff;
  background-color: ${theme.color.black};
  border-radius: 5rem;
`;
