import React, { Dispatch, SetStateAction } from 'react';
import * as s from './DeliveryConditionStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import 'components/styles/Paging.css';
import { priceToString } from 'util/util';

interface DeliveryConditionProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  pageOffset: number;
  deliveryConditionNm: string;
  setDeliveryConditionNm: Dispatch<SetStateAction<string>>;
  deliveryCompanyId: string;
  setDeliveryCompanyId: Dispatch<SetStateAction<string>>;
  deliveryConditionTypeCd: string;
  setDeliveryConditionTypeCd: Dispatch<SetStateAction<string>>;
  deliveryConditionTypeList: any[];
  deliveryCompanyList: any[];
  totalCount: number;
  deliveryConditionList: any[];
  handlePageChange: (page: number) => void;
  search: () => void;
  goToUpdate: (condition: any) => void;
}

const DeliveryConditionPresenter = (props: DeliveryConditionProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>배송조건목록</s.PageTitle>
        <s.PageIndicator>
          {'기본설정관리 > 배송조건 > 배송조건목록'}
        </s.PageIndicator>
      </s.PageHeaderArea>
      <s.SearchArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>배송조건명</s.SectionTh>
              <s.SectionTd noPad align="left" width="85%">
                <s.GeneralInfoInput
                  type="text"
                  value={props.deliveryConditionNm}
                  onChange={(e) => props.setDeliveryConditionNm(e.target.value)}
                  placeholder="배송조건명을 입력하세요."
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>택배사</s.SectionTh>
              <s.SectionTd noPad align="left" width="85%">
                <s.GeneralInfoSelect
                  width={'20rem'}
                  value={props.deliveryCompanyId}
                  onChange={(e) => props.setDeliveryCompanyId(e.target.value)}
                >
                  <option value="">선택</option>
                  {props.deliveryCompanyList.map((company: any) => (
                    <option value={company.deliveryCompanyId}>
                      {company.deliveryCompanyNm}
                    </option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>배송조건유형</s.SectionTh>
              <s.SectionTd noPad align="left" width="85%">
                <s.GeneralInfoSelect
                  width={'20rem'}
                  value={props.deliveryConditionTypeCd}
                  onChange={(e) =>
                    props.setDeliveryConditionTypeCd(e.target.value)
                  }
                >
                  <option value="">선택</option>
                  {props.deliveryConditionTypeList.map((condition: any) => (
                    <option value={condition.comnCd}>
                      {condition.comnCdNm}
                    </option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
        <s.SaveButton onClick={() => props.search()}>조회</s.SaveButton>
      </s.SearchArea>

      <s.DataArea>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh center>배송비조건명</s.SectionTh>
              <s.SectionTh center>배송비유형</s.SectionTh>
              <s.SectionTh center>배송비</s.SectionTh>
              <s.SectionTh center>지역별배송비(제주)</s.SectionTh>
              <s.SectionTh center>지역별배송비(도서산간)</s.SectionTh>
              <s.SectionTh center>배송사</s.SectionTh>
            </s.SectionTr>
            {props.deliveryConditionList.length < 1 ? (
              <s.SectionTr>
                <s.SectionTd colSpan={7} style={{ height: '50rem' }}>
                  조회값이 없습니다.
                </s.SectionTd>
              </s.SectionTr>
            ) : (
              props.deliveryConditionList.map((condition: any) => (
                <s.SectionTr
                  onClick={() => props.goToUpdate(condition)}
                  hoverActive
                >
                  <s.SectionTd>{condition.deliveryConditionNm}</s.SectionTd>
                  <s.SectionTd>{condition.deliveryConditionTypeNm}</s.SectionTd>
                  <s.SectionTd>
                    {condition.price && Number(condition.price) !== 0
                      ? priceToString(condition.price)
                      : '무료'}
                  </s.SectionTd>
                  <s.SectionTd>
                    {condition.additionalPriceJeju
                      ? priceToString(condition.additionalPriceJeju)
                      : '없음'}
                  </s.SectionTd>
                  <s.SectionTd>
                    {condition.additionalPriceMountain
                      ? priceToString(condition.additionalPriceMountain)
                      : '없음'}
                  </s.SectionTd>
                  <s.SectionTd>{condition.deliveryCompanyNm}</s.SectionTd>
                </s.SectionTr>
              ))
            )}
          </s.SectionTbody>
        </s.SectionTable>
      </s.DataArea>
      {props.deliveryConditionList.length < 1 ? null : (
        <s.PaginationWrapper>
          <Pagination
            activePage={props.page}
            itemsCountPerPage={props.pageOffset}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={5}
            firstPageText={''}
            lastPageText={''}
            prevPageText={'◀'}
            nextPageText={'▶'}
            onChange={props.handlePageChange}
          />
        </s.PaginationWrapper>
      )}
    </s.Container>
  );
};

export default DeliveryConditionPresenter;
