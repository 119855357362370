import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import MyPagePresenter from './MyPagePresenter';

const MyPageContainer = () => {
  const params = useParams();
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const token = useRecoilValue(recoilItem.token);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [activeScreen, setActiveScreen] = useState<number>(
    Number(params.activeScreen) || 1,
  );

  const navigate = useNavigate();

  useEffect(() => {
    setActiveScreen(Number(params.activeScreen));
  }, [params.activeScreen]);

  const goToProductList = (keyword?: string) => {
    navigate('/', {
      state: {
        keyword: keyword,
      },
    });
  };

  const checkSearchEnter = (event: any) => {
    if (event.key === 'Enter') {
      goToProductList(searchKeyword);
    }
  };

  return (
    <>
      <MyPagePresenter
        userInfo={userInfo}
        goToProductList={goToProductList}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        checkSearchEnter={checkSearchEnter}
        activeScreen={activeScreen}
        setActiveScreen={setActiveScreen}
      />
    </>
  );
};

MyPageContainer.defaultProps = {};

export default MyPageContainer;
