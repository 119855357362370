import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { productApi } from 'api/api-product';
import { qnaApi } from 'api/api-qna';
import { reviewApi } from 'api/api-review';
import recoilItem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from 'sweetalert';

import ProductDetailPresenter from './ProductDetailPresenter';
import { cartApi } from 'api/api-cart';
import {
  ButtonList,
  ButtonOptions,
} from 'sweetalert/typings/modules/options/buttons';

const ProductDetailContainer = () => {
  const params = useParams();
  const navigate = useNavigate();

  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [product, setProduct] = useState<any>(null);
  const [deliveryCondition, setDeliveryCondition] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [openQnaPopup, setOpenQnaPopup] = useState<boolean>(false);

  const [qnaType, setQnaType] = useState<string>('');
  const [qnaTitle, setQnaTitle] = useState<string>('');
  const [qnaContent, setQnaContent] = useState<string>('');
  const [qnaList, setQnaList] = useState<any[]>([]);
  const [qnaPage, setQnaPage] = useState<number>(1);
  const qnaPageOffset = 5;
  const [reviewList, setReviewList] = useState<any[]>([]);
  const [reviewPage, setReviewPage] = useState<number>(1);
  const reviewPageOffset = 5;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  useEffect(() => {
    setQnaType('');
    setQnaTitle('');
    setQnaContent('');
  }, [openQnaPopup]);

  useEffect(() => {
    getQnaList();
  }, [qnaPage]);

  useEffect(() => {
    getReviewList();
  }, [reviewPage]);

  useEffect(() => {
    if (activeTab === 1 || activeTab === 4) {
      setReviewPage(0);
      setReviewList([]);
      setQnaPage(0);
      setQnaList([]);
    } else if (activeTab === 2) {
      setReviewPage(0);
      setReviewList([]);
      setQnaPage(1);
    } else if (activeTab === 3) {
      setQnaPage(0);
      setQnaList([]);
      setReviewPage(1);
    }
  }, [activeTab]);

  const getQnaList = async () => {
    try {
      const data = {
        comId: comInfo.comId,
        productId: params.productId,
        page: qnaPage,
        pageOffset: qnaPageOffset,
      };

      setIsLoading(true);

      const res = await qnaApi.getList(data);
      if (res.data.rsltCd === '00') {
        if (qnaPage < 2) {
          setQnaList(
            res.data.data.qnaList.map((qna: any) => ({ ...qna, open: false })),
          );
        } else {
          setQnaList([
            ...qnaList,
            ...res.data.data.qnaList.map((qna: any) => ({
              ...qna,
              open: false,
            })),
          ]);
        }
      } else {
        if (qnaPage > 1) {
          makeMsg('마지막입니다.', 'warning');
        }
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const getReviewList = async () => {
    try {
      const data = {
        comId: comInfo.comId,
        productId: params.productId,
        page: reviewPage,
        pageOffset: reviewPageOffset,
      };
      setIsLoading(true);

      const res = await reviewApi.getList(data);
      if (res.data.rsltCd === '00') {
        if (reviewPage < 2) {
          setReviewList(
            res.data.data.reviewList.map((review: any) => ({
              ...review,
              open: false,
            })),
          );
        } else {
          setReviewList([
            ...reviewList,
            ...res.data.data.reviewList.map((review: any) => ({
              ...review,
              open: false,
            })),
          ]);
        }
      } else {
        if (reviewPage > 1) {
          makeMsg('마지막입니다.', 'warning');
        }
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = {
        productId: params.productId,
      };

      const res = await productApi.detail(data);
      if (res.data.rsltCd === '00') {
        setProduct(res.data.data.product);
        setDeliveryCondition(res.data.data.deliveryCondition);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      navigate('/');
    }
  };

  const addOptionProduct = (optionId: number) => {
    console.log(selectedOptions);
    if (!optionId) {
      return;
    } else if (
      selectedOptions.findIndex((option: any) => option.optionId === optionId) >
      -1
    ) {
      makeMsg('이미 추가된 상품입니다.', 'warning');
      return;
    } else {
      const temp = selectedOptions;
      temp.push({
        ...product.options.find((option: any) => option.optionId === optionId),
        orderCount: '1',
      });
      setSelectedOptions([...temp]);
    }
  };

  const removeOptionProduct = (index: number) => {
    const temp = selectedOptions;
    temp.splice(index, 1);
    setSelectedOptions([...temp]);
  };

  const changeOptionCount = (index: number, value: number) => {
    const temp = selectedOptions;
    temp[index].orderCount = value;
    setSelectedOptions([...temp]);
  };

  const registerQna = async () => {
    try {
      if (!qnaType) {
        makeMsg('문의유형을 선택하세요.', 'warning');
        return;
      }

      if (!qnaTitle) {
        makeMsg('문의제목을 입력하세요.', 'warning');
        return;
      }

      if (!qnaContent) {
        makeMsg('문의내용을 입력하세요.', 'warning');
        return;
      }
      setIsLoading(true);

      const data = {
        qnaType: qnaType,
        qnaTitle: qnaTitle,
        qnaContent: qnaContent,
        productId: params.productId,
        userId: userInfo.userId,
      };

      const res = await qnaApi.insert(token, data);
      if (res.data.rsltCd === '00') {
        makeMsg('문의가 등록되었습니다.', 'success');
        setOpenQnaPopup(false);
        setQnaPage(1);
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const addToCart = async () => {
    try {
      setIsLoading(true);
      const data = {
        userId: userInfo.userId,
        productList: selectedOptions.map((option: any) => ({
          productId: product.productId,
          optionId: option.optionId,
          count: option.orderCount,
        })),
      };

      const res = await cartApi.multiInsert(token, data);
      setIsLoading(false);
      if (res.data.rsltCd === '00') {
        let chk = false;
        const confirmButton: ButtonOptions = {
          text: '장바구니로 이동',
          value: 'Y',
          visible: true,
        };

        const cancelButton: ButtonOptions = {
          text: '계속 쇼핑하기',
          value: 'N',
          visible: true,
        };

        await swal({
          text: '장바구니에 등록되었습니다.\n장바구니로 이동하시겠습니까?',
          buttons: {
            confirmButton,
            cancelButton,
          },
        }).then((value) => {
          switch (value) {
            case 'Y':
              chk = true;
              break;
            default:
              chk = false;
              break;
          }
        });
        if (chk) {
          navigate('/mypage/2');
        }
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const orderProduct = async () => {
    try {
      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '상품을 구매하시겠습니까?',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        navigate('/order', {
          state: {
            itemList: selectedOptions.map((option: any) => ({
              ...product,
              ...option,
              optionPrice: option.price,
              deliveryPrice: deliveryCondition.price,
              count: option.orderCount,
            })),
          },
        });
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <ProductDetailPresenter
        product={product}
        deliveryCondition={deliveryCondition}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        addOptionProduct={addOptionProduct}
        removeOptionProduct={removeOptionProduct}
        changeOptionCount={changeOptionCount}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        openQnaPopup={openQnaPopup}
        setOpenQnaPopup={setOpenQnaPopup}
        qnaList={qnaList}
        setQnaList={setQnaList}
        qnaTitle={qnaTitle}
        setQnaTitle={setQnaTitle}
        qnaType={qnaType}
        setQnaType={setQnaType}
        qnaContent={qnaContent}
        setQnaContent={setQnaContent}
        registerQna={registerQna}
        qnaPage={qnaPage}
        setQnaPage={setQnaPage}
        reviewList={reviewList}
        setReviewList={setReviewList}
        reviewPage={reviewPage}
        setReviewPage={setReviewPage}
        addToCart={addToCart}
        orderProduct={orderProduct}
      />
    </>
  );
};

ProductDetailContainer.defaultProps = {};

export default ProductDetailContainer;
