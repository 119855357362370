import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProductDetailStyled';
import { Link } from 'react-router-dom';
import {
  FaChevronDown,
  FaChevronRight,
  FaChevronUp,
  FaHeart,
  FaInfo,
  FaLink,
  FaTrash,
} from 'react-icons/fa';
import theme from 'components/styles/theme';
import {
  AiFillInfoCircle,
  AiOutlineDelete,
  AiOutlineHeart,
  AiOutlineInbox,
  AiOutlineInfo,
  AiOutlineLink,
} from 'react-icons/ai';
import { makeMsg, priceToString } from 'util/util';
import { MdClose } from 'react-icons/md';
import { GiTrashCan } from 'react-icons/gi';
import ReactQuill from 'react-quill';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

interface ProductDetailProps {
  product: any;
  deliveryCondition: any;
  selectedOptions: any[];
  setSelectedOptions: Dispatch<SetStateAction<any[]>>;
  addOptionProduct: (optionId: number) => void;
  removeOptionProduct: (index: number) => void;
  changeOptionCount: (index: number, value: number) => void;

  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;

  openQnaPopup: boolean;
  setOpenQnaPopup: Dispatch<SetStateAction<boolean>>;
  qnaList: any[];
  setQnaList: Dispatch<SetStateAction<any[]>>;
  qnaTitle: string;
  setQnaTitle: Dispatch<SetStateAction<string>>;
  qnaType: string;
  setQnaType: Dispatch<SetStateAction<string>>;
  qnaContent: string;
  setQnaContent: Dispatch<SetStateAction<string>>;
  registerQna: () => void;
  qnaPage: number;
  setQnaPage: Dispatch<SetStateAction<number>>;

  reviewList: any[];
  setReviewList: Dispatch<SetStateAction<any[]>>;
  reviewPage: number;
  setReviewPage: Dispatch<SetStateAction<number>>;
  addToCart: () => void;
  orderProduct: () => void;
}

const ProductDetailPresenter = (props: ProductDetailProps) => {
  return (
    <s.Container>
      {props.openQnaPopup ? (
        <s.QnaPopupBackground>
          <s.QnaPopup>
            <s.ClosePopupButton onClick={() => props.setOpenQnaPopup(false)}>
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>문의하기</s.PopupTitle>

            <s.PopupRow>
              <s.PopupOptionSelectChevron>
                <FaChevronDown size={'100%'} color={theme.color.black} />
              </s.PopupOptionSelectChevron>
              <s.PopupSelect
                value={props.qnaType}
                onChange={(e) => props.setQnaType(e.target.value)}
              >
                <option value="">문의유형 선택</option>
                <option value="제품문의">제품문의</option>
                <option value="교환/반품문의">교환/반품문의</option>
                <option value="기타문의">기타문의</option>
              </s.PopupSelect>
            </s.PopupRow>
            <s.PopupRow>
              <s.PopupInput
                type="text"
                placeholder="문의제목을 입력하세요."
                value={props.qnaTitle}
                onChange={(e) => props.setQnaTitle(e.target.value)}
                maxLength={100}
              />
            </s.PopupRow>
            <s.PopupRow>
              <s.PopupTextarea
                placeholder={
                  '문의내용을 입력해주세요.\n개인정보 보호를 위해 개인정보, 이메일등은 남기지 말아주세요.\n욕설, 비방, 홍보 등 제품과 무관한 내용은 별도 통보 없이 삭제될 수 있습니다.'
                }
                value={props.qnaContent}
                onChange={(e) => props.setQnaContent(e.target.value)}
                maxLength={500}
              />
              <s.PopupTextLimit>
                {props.qnaContent.length}/500 자
              </s.PopupTextLimit>
            </s.PopupRow>
            <s.PopupButtonWrapper>
              <s.PopupButton onClick={() => props.registerQna()}>
                문의 등록하기
              </s.PopupButton>
            </s.PopupButtonWrapper>
            <s.PopupRow>
              {
                '❖ 문의하신 내역과 답변은 마이페이지 > 내 게시글 > Q&A 에서 확인 가능합니다.'
              }
            </s.PopupRow>
          </s.QnaPopup>
        </s.QnaPopupBackground>
      ) : null}
      <s.ContentArea openPopup={props.openQnaPopup}>
        <s.TopInfoArea>
          <s.TopInfoThumbnail src={props.product?.thumbnail} />
          <s.TopInfoTextWrapper>
            <s.TopInfoTitleWrapper>
              <s.TopInfoTitle>{props.product?.productNm}</s.TopInfoTitle>
              <s.TopInfoTitleIconWrapper>
                <s.TopInfoTitleIcon>
                  <AiOutlineHeart color={theme.color.darkGrey} size={'100%'} />
                </s.TopInfoTitleIcon>
                <s.TopInfoTitleIcon>
                  <AiOutlineLink color={theme.color.darkGrey} size={'100%'} />
                </s.TopInfoTitleIcon>
              </s.TopInfoTitleIconWrapper>
            </s.TopInfoTitleWrapper>

            <s.TopModelNmWrapper>
              <s.TopModelNmIcon>
                <AiFillInfoCircle size={'2rem'} color={theme.color.darkGrey} />
              </s.TopModelNmIcon>
              <s.TopModelNm>{props.product?.modelNm}</s.TopModelNm>
            </s.TopModelNmWrapper>

            <s.PriceWrapper>
              <s.PriceText>{priceToString(props.product?.price)}원</s.PriceText>
              <s.BigPriceWrapper>
                <s.DiscountText>
                  {Math.round(
                    ((props.product?.price - props.product?.priceDiscount) /
                      props.product?.price) *
                      100,
                  )}
                  %
                </s.DiscountText>
                <s.PriceDiscountText>
                  <s.PriceDiscountSpan>
                    {priceToString(props.product?.priceDiscount)}
                  </s.PriceDiscountSpan>
                  원
                </s.PriceDiscountText>
              </s.BigPriceWrapper>
            </s.PriceWrapper>
            <s.DeliveryInfoWrapper>
              <s.DeliveryTable>
                <s.DeliveryTbody>
                  <s.DeliveryTr>
                    <s.DeliveryTh>배송정보</s.DeliveryTh>
                    <s.DeliveryTd>
                      <s.DeliveryTdText>
                        {props.deliveryCondition?.deliveryConditionTypeNm}
                        {props.deliveryCondition?.deliveryConditionTypeCd ===
                        'DLVTYPE01'
                          ? ' (' +
                            priceToString(props.deliveryCondition?.price) +
                            ' 원)'
                          : null}
                      </s.DeliveryTdText>
                      <s.DeliveryTdText>
                        {props.deliveryCondition?.deliveryNotice}
                      </s.DeliveryTdText>
                    </s.DeliveryTd>
                  </s.DeliveryTr>
                  <s.DeliveryTr>
                    <s.DeliveryTh>반품교환</s.DeliveryTh>
                    <s.DeliveryTd>
                      <s.DeliveryTdText>
                        {props.deliveryCondition?.returnPrice ||
                        props.deliveryCondition?.changePrice
                          ? `고객부담 (반품 : ${priceToString(
                              props.deliveryCondition?.returnPrice,
                            )} 원 / 교환 : ${priceToString(
                              props.deliveryCondition?.changePrice,
                            )} 원)`
                          : '업체부담'}
                      </s.DeliveryTdText>
                    </s.DeliveryTd>
                  </s.DeliveryTr>
                </s.DeliveryTbody>
              </s.DeliveryTable>
            </s.DeliveryInfoWrapper>
            <s.OptionWrapper>
              <s.OptionLabel>상품선택</s.OptionLabel>
              <s.OptionSelectChevron>
                <FaChevronDown size={'100%'} color={theme.color.secondary} />
              </s.OptionSelectChevron>
              <s.OptionSelect
                onChange={(e) => props.addOptionProduct(Number(e.target.value))}
              >
                <option value="">선택</option>
                {props.product?.options.map((option: any) => (
                  <option
                    value={option?.optionId}
                    disabled={Number(option?.stock) === 0}
                  >
                    {`${option?.optionNm} (${priceToString(
                      Number(option?.price) +
                        Number(props.product?.priceDiscount),
                    )}원 / 재고수량 ${option?.stock}EA)`}
                  </option>
                ))}
              </s.OptionSelect>
            </s.OptionWrapper>
            {props.selectedOptions.map((selectedOption: any, index: number) => (
              <s.TopInfoGeneralRow>
                {selectedOption.optionNm}
                <s.TopInfoGeneralSelectCountTable>
                  <s.TopInfoGeneralSelectCountTbody>
                    <s.TopInfoGeneralSelectCountTr>
                      <s.TopInfoGeneralSelectCountTd isFirst>
                        <s.TopInfoGeneralSelectCountButton
                          onClick={() =>
                            Number(selectedOption.orderCount) === 1
                              ? makeMsg(
                                  '최소 1개 이상 구매해야 합니다.',
                                  'warning',
                                )
                              : props.changeOptionCount(
                                  index,
                                  Number(selectedOption.orderCount) - 1,
                                )
                          }
                        >
                          -
                        </s.TopInfoGeneralSelectCountButton>
                      </s.TopInfoGeneralSelectCountTd>
                      <s.TopInfoGeneralSelectCountTd
                        style={{ width: isMobile ? '4rem' : '5rem' }}
                      >
                        {selectedOption.orderCount}
                      </s.TopInfoGeneralSelectCountTd>
                      <s.TopInfoGeneralSelectCountTd isLast>
                        <s.TopInfoGeneralSelectCountButton
                          onClick={() =>
                            selectedOption.orderCount >= selectedOption.stock
                              ? makeMsg(
                                  '재고수량보다 더 주문하실 수 없습니다.',
                                  'warning',
                                )
                              : props.changeOptionCount(
                                  index,
                                  Number(selectedOption.orderCount) + 1,
                                )
                          }
                        >
                          +
                        </s.TopInfoGeneralSelectCountButton>
                      </s.TopInfoGeneralSelectCountTd>
                    </s.TopInfoGeneralSelectCountTr>
                  </s.TopInfoGeneralSelectCountTbody>
                </s.TopInfoGeneralSelectCountTable>
                <s.TopInfoGeneralDeleteRow
                  onClick={() => props.removeOptionProduct(index)}
                >
                  <AiOutlineDelete color={'#ff4545'} size={'100%'} />
                </s.TopInfoGeneralDeleteRow>
              </s.TopInfoGeneralRow>
            ))}
            {props.selectedOptions.length > 0 ? (
              <s.TopInfoGeneralRow>
                배송비
                <s.TopInfoGeneralSmallText>
                  {priceToString(
                    Number(props.deliveryCondition?.price) *
                      props.selectedOptions.length,
                  )}
                  원
                </s.TopInfoGeneralSmallText>
              </s.TopInfoGeneralRow>
            ) : null}

            <s.TopInfoGeneralRow>
              총 금액
              <s.TopInfoGeneralText>
                {props.selectedOptions.length > 0
                  ? priceToString(
                      props.selectedOptions.reduce(
                        (a: any, b: any) =>
                          a +
                          (Number(props.product?.priceDiscount) +
                            Number(b.price)) *
                            Number(b.orderCount),
                        0,
                      ),
                    )
                  : 0}
                원
              </s.TopInfoGeneralText>
            </s.TopInfoGeneralRow>
            <s.TopInfoButtonWrapper>
              <s.TopInfoButton width="6rem">
                <AiOutlineHeart size={'2rem'} color={theme.color.lightGrey} />
              </s.TopInfoButton>
              <s.TopInfoButton width="26rem" onClick={() => props.addToCart()}>
                장바구니 담기
              </s.TopInfoButton>
              <s.TopInfoButton
                width="26rem"
                primary
                onClick={() => props.orderProduct()}
              >
                구매하기
              </s.TopInfoButton>
            </s.TopInfoButtonWrapper>
          </s.TopInfoTextWrapper>
        </s.TopInfoArea>
        <s.BottomInfoArea>
          <s.BottomTabWrapper>
            <s.BottomTabItem
              active={props.activeTab === 1}
              onClick={() => props.setActiveTab(1)}
            >
              제품 상세
            </s.BottomTabItem>
            <s.BottomTabItem
              active={props.activeTab === 2}
              onClick={() => props.setActiveTab(2)}
            >
              Q&A
            </s.BottomTabItem>
            <s.BottomTabItem
              active={props.activeTab === 3}
              onClick={() => props.setActiveTab(3)}
            >
              리뷰
            </s.BottomTabItem>
            <s.BottomTabItem
              active={props.activeTab === 4}
              onClick={() => props.setActiveTab(4)}
            >
              반품/교환정보
            </s.BottomTabItem>
          </s.BottomTabWrapper>
          {props.activeTab === 1 ? (
            <s.QuillWrapper>
              <ReactQuill
                value={props.product?.content || ''}
                readOnly={true}
                theme={'snow'}
                modules={{ toolbar: false }}
              />
            </s.QuillWrapper>
          ) : props.activeTab === 2 ? (
            <s.BottomContentWrapper>
              <s.BottomContentTitle>
                제품상세문의
                <s.BottomContentButton
                  onClick={() => props.setOpenQnaPopup(true)}
                >
                  문의하기
                </s.BottomContentButton>
              </s.BottomContentTitle>
              <s.BottomContentTable>
                <s.BottomContentTbody>
                  {props.qnaList.length > 0 ? (
                    props.qnaList.map((qna: any, index: any) => (
                      <>
                        <s.BottomContentTr
                          onClick={() => {
                            const temp = props.qnaList;
                            temp[index].open = !temp[index].open;
                            props.setQnaList([...temp]);
                          }}
                        >
                          <s.BottomContentTd width="8rem">
                            <s.BottomContentTdLabel done={qna.reply}>
                              {qna.reply ? '답변완료' : '답변대기'}
                            </s.BottomContentTdLabel>
                          </s.BottomContentTd>
                          <s.BottomContentTd width="15%">
                            {qna.qnaType}
                          </s.BottomContentTd>
                          <s.BottomContentTd
                            width="55%"
                            style={{ textAlign: 'left' }}
                          >
                            {qna.qnaTitle}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="15%">
                            {qna.buyerNm}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="15%">
                            {moment(qna.regDtm).format('YYYY.MM.DD')}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="5%">
                            {qna.open ? (
                              <FaChevronUp
                                size={'2rem'}
                                color={theme.color.black}
                              />
                            ) : (
                              <FaChevronDown
                                size={'2rem'}
                                color={theme.color.black}
                              />
                            )}
                          </s.BottomContentTd>
                        </s.BottomContentTr>
                        {qna.open ? (
                          <s.BottomContentTr>
                            <s.BottomContentTd colSpan={6}>
                              <s.QuestionTextArea>
                                {qna.qnaContent}
                              </s.QuestionTextArea>
                              {qna.reply ? (
                                <s.ReplyTextArea>
                                  {qna.reply}
                                  <s.ReplyTimestamp>
                                    {moment(qna.replyDtm).format(
                                      'YYYY-MM-DD HH:mm',
                                    )}
                                  </s.ReplyTimestamp>
                                </s.ReplyTextArea>
                              ) : null}
                            </s.BottomContentTd>
                          </s.BottomContentTr>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <s.BottomContentTr>
                      <s.BottomContentTd
                        colSpan={6}
                        style={{ padding: '12rem' }}
                      >
                        등록된 문의가 없습니다.
                      </s.BottomContentTd>
                    </s.BottomContentTr>
                  )}
                </s.BottomContentTbody>
              </s.BottomContentTable>
              <s.GetMoreButton
                onClick={() => props.setQnaPage(props.qnaPage + 1)}
              >
                더보기{' '}
                <FaChevronDown color={theme.color.lightGrey} size={'1rem'} />
              </s.GetMoreButton>
            </s.BottomContentWrapper>
          ) : props.activeTab === 3 ? (
            <s.BottomContentWrapper>
              <s.BottomContentTitle>리뷰</s.BottomContentTitle>
              <s.BottomContentTable>
                <s.BottomContentTbody>
                  {props.reviewList.length > 0 ? (
                    props.reviewList.map((review: any, index: any) => (
                      <>
                        <s.BottomContentTr
                          onClick={() => {
                            const temp = props.reviewList;
                            temp[index].open = !temp[index].open;
                            props.setReviewList([...temp]);
                          }}
                        >
                          <s.BottomContentTd
                            width="55%"
                            style={{ textAlign: 'left' }}
                          >
                            {review.reviewTitle}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="15%">
                            {review.buyerNm}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="15%">
                            {moment(review.regDtm).format('YYYY.MM.DD')}
                          </s.BottomContentTd>
                          <s.BottomContentTd width="5%">
                            {review.open ? (
                              <FaChevronUp
                                size={'2rem'}
                                color={theme.color.black}
                              />
                            ) : (
                              <FaChevronDown
                                size={'2rem'}
                                color={theme.color.black}
                              />
                            )}
                          </s.BottomContentTd>
                        </s.BottomContentTr>
                        {review.open ? (
                          <s.BottomContentTr>
                            <s.BottomContentTd colSpan={6}>
                              <s.QuestionTextArea>
                                {review.reviewContent}
                              </s.QuestionTextArea>
                              {review.reply ? (
                                <s.ReplyTextArea>
                                  {review.reply}
                                  <s.ReplyTimestamp>
                                    {moment(review.replyDtm).format(
                                      'YYYY-MM-DD HH:mm',
                                    )}
                                  </s.ReplyTimestamp>
                                </s.ReplyTextArea>
                              ) : null}
                            </s.BottomContentTd>
                          </s.BottomContentTr>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <s.BottomContentTr>
                      <s.BottomContentTd
                        colSpan={6}
                        style={{ padding: '12rem' }}
                      >
                        등록된 리뷰가 없습니다.
                      </s.BottomContentTd>
                    </s.BottomContentTr>
                  )}
                </s.BottomContentTbody>
              </s.BottomContentTable>
              <s.GetMoreButton
                onClick={() => props.setReviewPage(props.reviewPage + 1)}
              >
                더보기{' '}
                <FaChevronDown color={theme.color.lightGrey} size={'1rem'} />
              </s.GetMoreButton>
            </s.BottomContentWrapper>
          ) : props.activeTab === 4 ? (
            <s.BottomContentWrapper>
              <s.BottomContentTitle>반품/교환</s.BottomContentTitle>
              <s.BottomContentTable>
                <s.BottomContentTbody>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">판매자</s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.sellerNm}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">
                      상호명/대표자
                    </s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.companyNm}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">
                      사업자 등록번호
                    </s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.companyRegno}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">
                      통신판매업 신고번호
                    </s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.telemarketingRegno}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">전화번호</s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.sellerMobno}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">
                      영업소재지
                    </s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.product?.companyAddr}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">반품주소</s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.deliveryCondition?.returnChangeAddress}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                  <s.BottomContentTr style={{ cursor: 'default' }}>
                    <s.BottomContentTh width="15%">교환주소</s.BottomContentTh>
                    <s.BottomContentTd
                      width="85%"
                      style={{ textAlign: 'left' }}
                    >
                      {props.deliveryCondition?.returnChangeAddress}
                    </s.BottomContentTd>
                  </s.BottomContentTr>
                </s.BottomContentTbody>
              </s.BottomContentTable>
            </s.BottomContentWrapper>
          ) : null}
        </s.BottomInfoArea>
      </s.ContentArea>
    </s.Container>
  );
};

export default ProductDetailPresenter;
