import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const token = atom({
  key: 'token',
  default: {
    accessToken: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export const isLoading = atom<boolean>({
  key: 'isLoading',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const userInfo = atom({
  key: 'userInfo',
  default: {
    userId: '',
    userNm: '',
    userMobno: '',
    userEmail: '',
    birthDt: '',
    userTypeCd: '',
    userStCd: '',
    comId: ',',
  },
  effects_UNSTABLE: [persistAtom],
});

export const comInfo = atom({
  key: 'comInfo',
  default: {
    comId: '',
    comNm: '',
    emailDomain: '',
    logo: '',
    background: '',
    address: '',
    regno: '',
    telemarketing: '',
    callCenter: '',
    helpEmail: '',
    representitive: '',
  },
  effects_UNSTABLE: [persistAtom],
});

export default {
  token,
  isLoading,
  userInfo,
  comInfo,
};
