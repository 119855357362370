import { Link, NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import theme from '../styles/theme';

// Common
export const Container = styled.div<{ expanded: boolean }>`
  width: 100%;
  height: 7rem;
  min-width: 192rem;
  margin: 0 auto 0.5rem;
  text-align: center;

  transition: 0.35s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const LogoArea = styled(NavLink)`
  width: 12rem;
  height: 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 8rem;
    height: 6rem;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;
  @media screen and (max-width: 800px) {
    width: 15rem;
  }
`;

// Web
export const TopMenuArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  min-width: 90rem;
  margin: 0 0 0 10%;

  @media screen and (max-width: 800px) {
    min-width: 0;
    margin: 0;
  }
`;

export const TopMenuItem = styled(NavLink)<{ focused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 100%;
  margin: 0 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  transition: 0.15s ease-out;

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

export const TopMenuDiv = styled.div`
  width: 5rem;
  height: 50%;
  position: relative;

  &:before {
    content: '';
    display: flex;
    width: 0.1rem;
    height: 2rem;
    position: absolute;
    left: 50%;
    top: 0.8rem;
    background-color: ${theme.color.lightGrey};
  }

  @media screen and (max-width: 800px) {
    width: 2rem;
  }
`;

export const UserIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  background-color: #ababab;
  cursor: pointer;
  border-radius: 5rem;
`;

export const WebHoverArea = styled.div<{ expanded: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.expanded ? '15rem' : '0rem')};
  overflow: hidden;
  width: 100%;
  box-shadow: 0 0.3rem 0.3rem 0 #dedede;
  background-color: #ffffff;
  z-index: 999;

  transition: 0.2s linear;
  top: 7rem;
`;

export const WebSubMenuItemCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 12rem;
  height: 100%;
  margin: 0 1rem;
`;

export const SubMenuLeftMargin = styled.div`
  width: 12rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SubMenuRightMargin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
`;

export const SubMenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 100;
  color: ${theme.color.black};
  margin: 1rem 0;

  &:hover {
    color: #0099f1;
    cursor: pointer;
  }
`;

export const LoginModal = styled.div<{ show: boolean; isLogin: boolean }>`
  position: absolute;
  top: 115%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15rem;
  padding: 0 1rem;
  height: ${(props) =>
    props.show ? (props.isLogin ? '12rem' : '7.5rem') : '0rem'};
  overflow: hidden;
  background-color: ${(props) => (props.show ? '#ffffff' : 'transparent')};
  border: 1px solid
    ${(props) => (props.show ? theme.color.primary : 'transparent')};
  transition: 0.25s ease-out;
  z-index: 9999;
`;

export const LoginModalText = styled.div`
  padding: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.primary};
`;

export const LoginModalItem = styled(NavLink)`
  padding: 1rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
  cursor: pointer;

  &:hover {
    color: ${theme.color.secondary};
  }
`;

// Mobile
export const BurgerIcon = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

export const ModalSidebarWrapper = styled.div<{ show: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${(props) => (props.show ? '1000' : '-1')};
  width: ${(props) => (props.show ? '100%' : '0')};
  height: ${(props) => (props.show ? '600vh' : '0vh')};
  opacity: ${(props) => (props.show ? '1' : '0')};

  transition: 0.15s ease-out;
`;

export const ModalBackdropArea = styled.div`
  width: 55%;
  background-color: transparent;
`;

export const MobileSidebar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background-color: #ffffff;
  width: 45%;
`;

export const MobileSidebarTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 7rem;
  width: 100%;
  background-color: #0099f1;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  z-index: 99999;
`;

export const MobileSidebarTopInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 1rem;
`;

export const MobileSidebarTopNaviWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.5rem;
`;

export const MobileSidebarTopNavi = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
`;

export const MobileSidebarTopTextInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: #ffffff;
`;

export const MobileSidebarCloseButton = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0.5rem;
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MobileSidebarTopMenu = styled.div<{ focused: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  height: 3rem;
  width: 100%;
  z-index: 99999;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.focused ? '1px solid #0099F1' : '1px solid #ABABAB'};
  color: ${(props) => (props.focused ? '#0099F1' : theme.color.black)};
  position: relative;
`;

export const MobileSidebarTopMenuIcon = styled.div`
  position: absolute;
  right: 0;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileSidebarSubMenuWrapper = styled.div<{ focused: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.focused ? 'auto' : '0')};
  margin: ${(props) => (props.focused ? '1rem auto' : '0')};
  background-color: #f6f6f6;
  overflow: hidden;
  transition: 0.35s ease-out;
  padding: ${(props) => (props.focused ? '1rem 0' : '0')};
`;

export const MobileSidebarSubMenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1rem;
  font-size: 1rem;
  margin: 0.3rem 0 0.3rem 1rem;
`;
