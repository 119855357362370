import React, { Dispatch, SetStateAction } from 'react';
import * as s from './SignUpFormStyled';
import { FaChevronRight } from 'react-icons/fa';
import theme from 'components/styles/theme';
import { makeMsg } from 'util/util';

interface SignUpFormProps {
  comInfo: any;

  isGetAuthNum: boolean;
  id: string;
  setId: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  passwordConf: string;
  setPasswordConf: Dispatch<SetStateAction<string>>;
  userNm: string;
  setUserNm: Dispatch<SetStateAction<string>>;
  birthDate: string;
  setBirthDate: Dispatch<SetStateAction<string>>;
  mobno1: string;
  setMobno1: Dispatch<SetStateAction<string>>;
  mobno2: string;
  setMobno2: Dispatch<SetStateAction<string>>;
  mobno3: string;
  setMobno3: Dispatch<SetStateAction<string>>;
  userEmail: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
  agreeA: boolean;
  setAgreeA: Dispatch<SetStateAction<boolean>>;
  agree1: boolean;
  setAgree1: Dispatch<SetStateAction<boolean>>;
  agree2: boolean;
  setAgree2: Dispatch<SetStateAction<boolean>>;
  agree3: boolean;
  setAgree3: Dispatch<SetStateAction<boolean>>;
  agree4: boolean;
  setAgree4: Dispatch<SetStateAction<boolean>>;
  agree5: boolean;
  setAgree5: Dispatch<SetStateAction<boolean>>;
  signUp: () => void;
  cancelSignUp: () => void;
  userTypeCd: any;

  companyNm: string;
  setCompanyNm: Dispatch<SetStateAction<string>>;
  companyRegno1: string;
  setCompanyRegno1: Dispatch<SetStateAction<string>>;
  companyRegno2: string;
  setCompanyRegno2: Dispatch<SetStateAction<string>>;
  companyRegno3: string;
  setCompanyRegno3: Dispatch<SetStateAction<string>>;
  companyAddrZipcode: string;
  companyAddr: string;
  setCompanyAddr: Dispatch<SetStateAction<string>>;
  telemarketingRegno: string;
  setTelemarketingRegno: Dispatch<SetStateAction<string>>;
  onChangeOpenPost: () => void;
}

const SignUpFormPresenter = (props: SignUpFormProps) => {
  return (
    <s.Container>
      <s.HeaderItem>
        <s.LogoArea to="/">
          <s.LogoImg src={props.comInfo.logo} />
        </s.LogoArea>
      </s.HeaderItem>
      <s.SignUpFormWrapper>
        <s.SignUpFormTitle>
          {props.comInfo.comNm} 복지몰로 복지 혜택 받아보세요!
        </s.SignUpFormTitle>
        <s.InputGroup>
          <s.InputGroupLabel>아이디</s.InputGroupLabel>
          <s.InputGroupInput
            type="text"
            placeholder="4~20자리, 영문 대소문자, 숫자 조합"
            value={props.id}
            onChange={(e) => props.setId(e.target.value)}
            maxLength={20}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputGroupLabel>비밀번호</s.InputGroupLabel>
          <s.InputGroupInput
            type="password"
            placeholder="8~16자리, 영문 대소문자, 숫자, 특수문자 조합"
            value={props.password}
            onChange={(e) => props.setPassword(e.target.value)}
          />
          <s.InputGroupInput
            type="password"
            placeholder="비밀번호를 다시 입력해주세요."
            value={props.passwordConf}
            onChange={(e) => props.setPasswordConf(e.target.value)}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputGroupLabel>이름</s.InputGroupLabel>
          <s.InputGroupInput
            type="text"
            placeholder="이름 입력"
            value={props.userNm}
            onChange={(e) => props.setUserNm(e.target.value)}
            maxLength={30}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputGroupLabel>생년월일</s.InputGroupLabel>
          <s.InputGroupInput
            type="text"
            placeholder="YYYYMMDD 형식으로 입력"
            pattern="[0-9]*"
            value={props.birthDate}
            onChange={(e) =>
              e.target.validity.valid
                ? props.setBirthDate(e.target.value)
                : makeMsg('숫자만 입력해주세요.', 'warning')
            }
            maxLength={8}
          />
        </s.InputGroup>
        <s.InputGroup>
          <s.InputGroupLabel>핸드폰번호</s.InputGroupLabel>
          <s.InputGroupHorizontalGroup>
            <s.InputGroupSelectShort
              value={props.mobno1}
              onChange={(e) => props.setMobno1(e.target.value)}
            >
              <option value="010">010</option>
              <option value="011">011</option>
              <option value="012">012</option>
              <option value="017">017</option>
              <option value="019">019</option>
            </s.InputGroupSelectShort>
            <s.InputGroupInputShort
              type="text"
              value={props.mobno2}
              onChange={(e) =>
                e.target.validity.valid
                  ? props.setMobno2(e.target.value)
                  : makeMsg('숫자만 입력해주세요.', 'warning')
              }
              maxLength={4}
              pattern="[0-9]*"
            />
            <s.InputGroupInputShort
              type="text"
              value={props.mobno3}
              onChange={(e) =>
                e.target.validity.valid
                  ? props.setMobno3(e.target.value)
                  : makeMsg('숫자만 입력해주세요.', 'warning')
              }
              maxLength={4}
              pattern="[0-9]*"
            />
            {/* <s.InputGroupButtonShort onClick={() => console.log("인증번호")}>
              인증번호 전송
            </s.InputGroupButtonShort> */}
          </s.InputGroupHorizontalGroup>
        </s.InputGroup>
        <s.InputGroup>
          <s.InputGroupLabel>이메일</s.InputGroupLabel>
          <s.InputGroupHorizontalGroup>
            {props.comInfo.emailDomain ? (
              <>
                <s.InputGroupInputMiddle
                  type="text"
                  placeholder="gildong.hong"
                  value={props.userEmail}
                  onChange={(e) => props.setUserEmail(e.target.value)}
                  maxLength={30}
                />
                <s.InputGroupMiddlePlainText>
                  {props.comInfo.emailDomain}
                </s.InputGroupMiddlePlainText>
              </>
            ) : (
              <s.InputGroupInput
                type="text"
                placeholder="gildong.hong"
                value={props.userEmail}
                onChange={(e) => props.setUserEmail(e.target.value)}
                maxLength={50}
              />
            )}
          </s.InputGroupHorizontalGroup>
        </s.InputGroup>
        {props.userTypeCd === 'SUP' ? (
          <>
            <s.InputGroup>
              <s.InputGroupLabel>상호명</s.InputGroupLabel>
              <s.InputGroupHorizontalGroup>
                <s.InputGroupInput
                  type="text"
                  placeholder="상호명 입력"
                  value={props.companyNm}
                  onChange={(e) => props.setCompanyNm(e.target.value)}
                  maxLength={50}
                />
              </s.InputGroupHorizontalGroup>
            </s.InputGroup>
            <s.InputGroup>
              <s.InputGroupLabel>사업자등록번호</s.InputGroupLabel>
              <s.InputGroupHorizontalGroup>
                <s.InputGroupHorizontalGroup>
                  <s.InputGroupInputShort
                    type="text"
                    value={props.companyRegno1}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setCompanyRegno1(e.target.value)
                        : makeMsg('숫자만 입력해주세요.', 'warning')
                    }
                    maxLength={3}
                    pattern="[0-9]*"
                    style={{ width: '20%' }}
                  />
                  -
                  <s.InputGroupInputShort
                    type="text"
                    value={props.companyRegno2}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setCompanyRegno2(e.target.value)
                        : makeMsg('숫자만 입력해주세요.', 'warning')
                    }
                    maxLength={2}
                    pattern="[0-9]*"
                    style={{ width: '17%' }}
                  />
                  -
                  <s.InputGroupInputShort
                    type="text"
                    value={props.companyRegno3}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setCompanyRegno3(e.target.value)
                        : makeMsg('숫자만 입력해주세요.', 'warning')
                    }
                    maxLength={5}
                    pattern="[0-9]*"
                  />
                </s.InputGroupHorizontalGroup>
              </s.InputGroupHorizontalGroup>
            </s.InputGroup>
            <s.InputGroup>
              <s.InputGroupLabel>통신판매업 등록번호</s.InputGroupLabel>
              <s.InputGroupHorizontalGroup>
                <s.InputGroupInput
                  type="text"
                  placeholder="통신판매업 등록번호 입력"
                  value={props.telemarketingRegno}
                  onChange={(e) => props.setTelemarketingRegno(e.target.value)}
                  maxLength={50}
                />
              </s.InputGroupHorizontalGroup>
            </s.InputGroup>
            <s.InputGroup>
              <s.InputGroupLabel>회사주소</s.InputGroupLabel>

              <s.AddressTdInputButton onClick={() => props.onChangeOpenPost()}>
                우편번호찾기
              </s.AddressTdInputButton>
              <s.InputGroupHorizontalGroup>
                <s.AddressTdInput
                  disabled={true}
                  style={{ backgroundColor: '#DEDEDE' }}
                  value={props.companyAddrZipcode}
                  width="15rem"
                ></s.AddressTdInput>
                <s.AddressTdInput
                  value={props.companyAddr}
                  onChange={(e) => {
                    props.setCompanyAddr(e.target.value);
                  }}
                />
              </s.InputGroupHorizontalGroup>
            </s.InputGroup>
            <s.InputGroup></s.InputGroup>
          </>
        ) : null}
        <s.InputGroup>
          <s.InputGroupLabel>약관</s.InputGroupLabel>
          <s.ContractWrapper>
            <s.ContractAgreeAllRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgreeA(!props.agreeA)}
                checked={props.agreeA}
              />
              <s.ContractAgreeCheckboxLabel style={{ fontWeight: 'bold' }}>
                전체 동의
              </s.ContractAgreeCheckboxLabel>
            </s.ContractAgreeAllRow>
            <s.ContractAgreeRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgree1(!props.agree1)}
                checked={props.agree1}
              />
              <s.ContractAgreeCheckboxLabel>
                <s.ContractAgreeCheckboxLabelRequired>
                  {'(필수)'}
                </s.ContractAgreeCheckboxLabelRequired>
                개인정보 처리방침에 동의
              </s.ContractAgreeCheckboxLabel>
              <s.ContractDetailButton>
                <FaChevronRight color={theme.color.lightGrey} size={'100%'} />
              </s.ContractDetailButton>
            </s.ContractAgreeRow>
            <s.ContractAgreeRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgree2(!props.agree2)}
                checked={props.agree2}
              />
              <s.ContractAgreeCheckboxLabel>
                <s.ContractAgreeCheckboxLabelRequired>
                  {'(필수)'}
                </s.ContractAgreeCheckboxLabelRequired>
                개인정보 제2자 제공에 동의
              </s.ContractAgreeCheckboxLabel>
              <s.ContractDetailButton>
                <FaChevronRight color={theme.color.lightGrey} size={'100%'} />
              </s.ContractDetailButton>
            </s.ContractAgreeRow>
            <s.ContractAgreeRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgree3(!props.agree3)}
                checked={props.agree3}
              />
              <s.ContractAgreeCheckboxLabel>
                {'(선택) '}개인정보 처리방침에 동의
              </s.ContractAgreeCheckboxLabel>
              <s.ContractDetailButton>
                <FaChevronRight color={theme.color.lightGrey} size={'100%'} />
              </s.ContractDetailButton>
            </s.ContractAgreeRow>
            <s.ContractAgreeRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgree4(!props.agree4)}
                checked={props.agree4}
              />
              <s.ContractAgreeCheckboxLabel>
                {'(선택) '}개인정보 제2자 제공에 동의
              </s.ContractAgreeCheckboxLabel>
              <s.ContractDetailButton>
                <FaChevronRight color={theme.color.lightGrey} size={'100%'} />
              </s.ContractDetailButton>
            </s.ContractAgreeRow>
            <s.ContractAgreeRow>
              <s.ContractAgreeCheckbox
                type="checkbox"
                onClick={() => props.setAgree5(!props.agree5)}
                checked={props.agree5}
              />
              <s.ContractAgreeCheckboxLabel>
                {'(선택) '}개인정보 제2자 제공에 동의
              </s.ContractAgreeCheckboxLabel>
              <s.ContractDetailButton>
                <FaChevronRight color={theme.color.lightGrey} size={'100%'} />
              </s.ContractDetailButton>
            </s.ContractAgreeRow>
          </s.ContractWrapper>
        </s.InputGroup>
        <s.ButtonWrapper>
          <s.ButtonSecondary onClick={() => props.cancelSignUp()}>
            취소
          </s.ButtonSecondary>
          <s.ButtonPrimary onClick={() => props.signUp()}>
            가입하기
          </s.ButtonPrimary>
        </s.ButtonWrapper>
      </s.SignUpFormWrapper>
    </s.Container>
  );
};

export default SignUpFormPresenter;
