import { notLoginedApi, loginedApi } from './api-base';

export const deliveryApi = {
  getCompanyList: (Data: any) =>
    notLoginedApi.post('/prod/delivery/getCompanyList', Data),
  getList: (Data: any) => notLoginedApi.post('/prod/delivery/getList', Data),

  insert: (token: any, Data: any) =>
    loginedApi.post('/prod/delivery/insert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  update: (token: any, Data: any) =>
    loginedApi.post('/prod/delivery/update', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
