import { orderApi } from 'api/api-order';
import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';

import OrderPresenter from './OrderPresenter';

const OrderContainer = () => {
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const comInfo = useRecoilValue(recoilitem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilitem.isLoading);
  const token = useRecoilValue(recoilitem.token);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageOffset = 5;
  const [orderList, setOrderList] = useState<any[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [updatingOrder, setUpdatingOrder] = useState<any>(null);
  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState<boolean>(false);
  const [cancelRsn, setCancelRsn] = useState<string>('');
  const [cancelBankAccount, setCancelBankAccount] = useState<string>('');
  const [cancelBankName, setCancelBankName] = useState<string>('');
  const [cancelBankHolderName, setCancelBankHolderName] = useState<string>('');

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (selectedOrder?.orderId) {
      setShowDetailPopup(true);
    } else {
      setShowDetailPopup(false);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (updatingOrder?.orderId) {
      setShowUpdatePopup(true);
    } else {
      setCancelRsn('');
      setCancelBankAccount('');
      setCancelBankName('');
      setCancelBankHolderName('');
      setShowUpdatePopup(false);
    }
  }, [updatingOrder]);

  const fetchData = async () => {
    try {
      const data = {
        comId: comInfo.comId,
        userId: userInfo.userId,
        page: page,
        pageOffset: pageOffset,
      };

      const res = await orderApi.getList(token, data);

      if (res.data.rsltCd === '00') {
        setOrderList(res.data.data.orderList);
        setTotalCount(res.data.data.totalCount);
      } else {
        setOrderList([]);
        setTotalCount(0);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const handlePageChange = async (page: number) => {
    setPage(page);
  };

  const updateOrder = async () => {
    try {
      if (updatingOrder?.toCd === 'ORDST91') {
        if (!cancelRsn.replaceAll(' ', '')) {
          makeMsg('취소 사유를 반드시 적어주세요.', 'warning');
          return;
        }

        if (updatingOrder?.paymentCd === 'V') {
          if (
            !cancelBankAccount.replaceAll(' ', '') ||
            !cancelBankHolderName.replaceAll(' ', '') ||
            !cancelBankName.replaceAll(' ', '')
          ) {
            makeMsg('환불받으실 정보를 반드시 입력해주세요.', 'warning');
            return;
          }
        }
      } else {
        makeMsg('여기에 리뷰 내용에 대한게 들어가야합니다.', 'warning');
      }

      const data: any = {
        orderStCd: updatingOrder?.toCd,
        orderIdList: [updatingOrder.orderId],
        updUserId: userInfo.userId,
      };

      if (updatingOrder?.toCd === 'ORDST91') {
        data.cancelRsn = cancelRsn;
        data.cancelBankAccount = cancelBankAccount;
        data.cancelBankHolderName = cancelBankHolderName;
        data.cancelBankName = cancelBankName;
      } else {
        // 리뷰세팅
      }

      setIsLoading(true);
      const res = await orderApi.multiUpdate(token, data);
      if (res.data.rsltCd === '00') {
        makeMsg('저장되었습니다.', 'success');
        setUpdatingOrder(null);
        fetchData();
      } else {
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <OrderPresenter
        page={page}
        handlePageChange={handlePageChange}
        totalCount={totalCount}
        orderList={orderList}
        showDetailPopup={showDetailPopup}
        selectedOrder={selectedOrder}
        setSelectedOrder={setSelectedOrder}
        updatingOrder={updatingOrder}
        setUpdatingOrder={setUpdatingOrder}
        pageOffset={pageOffset}
        showUpdatePopup={showUpdatePopup}
        setShowUpdatePopup={setShowUpdatePopup}
        cancelRsn={cancelRsn}
        setCancelRsn={setCancelRsn}
        cancelBankAccount={cancelBankAccount}
        setCancelBankAccount={setCancelBankAccount}
        cancelBankName={cancelBankName}
        setCancelBankName={setCancelBankName}
        cancelBankHolderName={cancelBankHolderName}
        setCancelBankHolderName={setCancelBankHolderName}
        updateOrder={updateOrder}
      />
    </>
  );
};

OrderContainer.defaultProps = {};

export default OrderContainer;
