import React, { Dispatch, SetStateAction } from 'react';
import * as s from './UpsertDeliveryConditionStyled';
import { Link } from 'react-router-dom';
import theme from 'components/styles/theme';
import { makeMsg, priceToString } from 'util/util';

interface UpsertDeliveryConditionProps {
  deliveryConditionNm: string;
  setDeliveryConditionNm: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  price: string;
  setPrice: Dispatch<SetStateAction<string>>;
  etc1: string;
  setEtc1: Dispatch<SetStateAction<string>>;
  additionalPriceJeju: string;
  setAdditionalPriceJeju: Dispatch<SetStateAction<string>>;
  additionalPriceMountain: string;
  setAdditionalPriceMountain: Dispatch<SetStateAction<string>>;
  returnPrice: string;
  setReturnPrice: Dispatch<SetStateAction<string>>;
  changePrice: string;
  setChangePrice: Dispatch<SetStateAction<string>>;
  deliveryCompanyId: string;
  setDeliveryCompanyId: Dispatch<SetStateAction<string>>;
  deliveryNotice: string;
  setDeliveryNotice: Dispatch<SetStateAction<string>>;
  deliveryCompanyList: any[];
  deliveryConditionCdList: any[];
  deliveryConditionTypeCd: string;
  setDeliveryConditionTypeCd: Dispatch<SetStateAction<string>>;
  toList: () => void;
  onChangeOpenPost: (dv: number) => void;
  outputAddress: string;
  outputAddressDetail: string;
  setOutputAddressDetail: Dispatch<SetStateAction<string>>;
  returnChangeAddress: string;
  returnChangeAddressDetail: string;
  setReturnChangeAddressDetail: Dispatch<SetStateAction<string>>;
  save: () => void;
  showAdditionalPrice: boolean;
  setShowAdditionalPrice: Dispatch<SetStateAction<boolean>>;
}

const UpsertDeliveryConditionPresenter = (
  props: UpsertDeliveryConditionProps,
) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>배송조건등록/수정</s.PageTitle>
        <s.PageIndicator>
          {'기본설정관리 > 배송조건 > 배송조건등록/수정'}
        </s.PageIndicator>
      </s.PageHeaderArea>
      <s.ContentArea>
        <s.SectionTitle>- 배송지 조건등록</s.SectionTitle>
        <s.SectionSemititle>
          ❖배송설정을 하시면, 간편하게 상품배송 정보를 등록하실 수 있습니다.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;나의 배송설정은 여러 개 등록하여 선택할 수
          있습니다.
        </s.SectionSemititle>
        <s.SectionTable>
          <s.SectionTbody>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>
                배송조건명
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left" noPad>
                <s.GeneralInfoInput
                  type="text"
                  placeholder="ex) 기본배송조건"
                  width="20rem"
                  value={props.deliveryConditionNm}
                  onChange={(e) => props.setDeliveryConditionNm(e.target.value)}
                  maxLength={50}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>배송조건설명</s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left" noPad>
                <s.GeneralInfoInput
                  type="text"
                  placeholder="ex) 기본배송조건 설명"
                  value={props.description}
                  onChange={(e) => props.setDescription(e.target.value)}
                />
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>배송비유형
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left" noPad>
                <s.GeneralInfoSelect
                  width="20rem"
                  value={props.deliveryConditionTypeCd}
                  onChange={(e) =>
                    props.setDeliveryConditionTypeCd(e.target.value)
                  }
                >
                  <option value="">선택</option>
                  {props.deliveryConditionCdList.map((condition: any) => (
                    <option value={condition.comnCd}>
                      {condition.comnCdNm}
                    </option>
                  ))}
                </s.GeneralInfoSelect>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>배송비설정
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left" noPad>
                {props.deliveryConditionTypeCd === 'DLVTYPE01' ? (
                  <s.GeneralInfoInputWrapper
                    style={{ justifyContent: 'flex-start' }}
                  >
                    구매금액에 상관없이
                    <s.GeneralInfoInput
                      type="text"
                      pattern="[0-9,]*"
                      width={'20rem'}
                      value={priceToString(
                        Number(props.price.replaceAll(',', '')),
                      )}
                      onChange={(e) =>
                        e.target.validity.valid
                          ? props.setPrice(e.target.value.replaceAll(',', ''))
                          : makeMsg('숫자만 입력하세요.', 'warning')
                      }
                    />
                    원
                  </s.GeneralInfoInputWrapper>
                ) : props.deliveryConditionTypeCd === 'DLVTYPE02' ? (
                  <>무료</>
                ) : props.deliveryConditionTypeCd ===
                  'DLVTYPE03' ? null : props.deliveryConditionTypeCd ===
                  'DLVTYPE04' ? null : null}
              </s.SectionTd>
            </s.SectionTr>
            {/* <s.SectionTr>
              <s.SectionTh style={{ width: "15%" }}>배송비결제방법</s.SectionTh>
              <s.SectionTd style={{ width: "85%" }} align="left"></s.SectionTd>
            </s.SectionTr> */}
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                지역별 추가배송비
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left">
                <s.GeneralInfoTdVerticalWrapper>
                  <s.GeneralInfoInputWrapper>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={props.showAdditionalPrice}
                      onClick={() => props.setShowAdditionalPrice(true)}
                    />
                    <s.GeneralInfoLabel>있음</s.GeneralInfoLabel>
                    <s.GeneralInfoRadio
                      type="radio"
                      checked={!props.showAdditionalPrice}
                      onClick={() => props.setShowAdditionalPrice(false)}
                    />
                    <s.GeneralInfoLabel>없음</s.GeneralInfoLabel>
                  </s.GeneralInfoInputWrapper>
                  {props.showAdditionalPrice ? (
                    <s.SectionTable>
                      <s.SectionTbody>
                        <s.SectionTr>
                          <s.SectionTh style={{ width: '15%' }}>
                            제주
                          </s.SectionTh>
                          <s.SectionTd
                            style={{ width: '35%' }}
                            align="left"
                            noPad
                          >
                            <s.GeneralInfoInputWrapper
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <s.GeneralInfoInput
                                type="text"
                                pattern="[0-9,]*"
                                width={'10rem'}
                                value={priceToString(
                                  Number(
                                    props.additionalPriceJeju.replaceAll(
                                      ',',
                                      '',
                                    ),
                                  ),
                                )}
                                onChange={(e) =>
                                  e.target.validity.valid
                                    ? props.setAdditionalPriceJeju(
                                        e.target.value.replaceAll(',', ''),
                                      )
                                    : makeMsg('숫자만 입력하세요', 'warning')
                                }
                              />
                              원
                            </s.GeneralInfoInputWrapper>
                          </s.SectionTd>
                          <s.SectionTh style={{ width: '15%' }}>
                            도서산간
                          </s.SectionTh>
                          <s.SectionTd
                            style={{ width: '35%' }}
                            align={'left'}
                            noPad
                          >
                            <s.GeneralInfoInputWrapper
                              style={{ justifyContent: 'flex-start' }}
                            >
                              <s.GeneralInfoInput
                                type="text"
                                pattern="[0-9,]*"
                                width={'10rem'}
                                value={priceToString(
                                  Number(
                                    props.additionalPriceMountain.replaceAll(
                                      ',',
                                      '',
                                    ),
                                  ),
                                )}
                                onChange={(e) =>
                                  e.target.validity.valid
                                    ? props.setAdditionalPriceMountain(
                                        e.target.value.replaceAll(',', ''),
                                      )
                                    : makeMsg('숫자만 입력하세요', 'warning')
                                }
                              />
                              원
                            </s.GeneralInfoInputWrapper>
                          </s.SectionTd>
                        </s.SectionTr>
                        <s.SectionTr>
                          <s.SectionTd colSpan={4}>
                            <s.GeneralInfoTdVerticalWrapper>
                              <s.SectionTdText>
                                제주/도서산간 지역 배송지의 경우 구매자에게 추가
                                운송비를 부담하게 설정합니다.
                              </s.SectionTdText>
                              <s.SectionTdText>
                                배송비를 변경하여도 설정한 도서산간 배송비용은
                                변경되지 않습니다.
                              </s.SectionTdText>
                            </s.GeneralInfoTdVerticalWrapper>
                          </s.SectionTd>
                        </s.SectionTr>
                      </s.SectionTbody>
                    </s.SectionTable>
                  ) : null}
                </s.GeneralInfoTdVerticalWrapper>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>출고지 주소
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left">
                <s.AddressTdContent>
                  <s.AddressTdInputButton
                    onClick={() => props.onChangeOpenPost(0)}
                  >
                    우편번호찾기
                  </s.AddressTdInputButton>
                  <s.AddressTdRow>
                    <s.AddressTdInput
                      disabled={true}
                      style={{ backgroundColor: '#DEDEDE' }}
                      value={props.outputAddress}
                      width="15rem"
                    ></s.AddressTdInput>
                    <s.AddressTdInput
                      value={props.outputAddressDetail}
                      onChange={(e) => {
                        props.setOutputAddressDetail(e.target.value);
                      }}
                    />
                  </s.AddressTdRow>
                </s.AddressTdContent>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>반품/교환 배송비
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left">
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  반품배송비 왕복
                  <s.GeneralInfoInput
                    type="text"
                    pattern="[0-9,]*"
                    width={'20rem'}
                    value={priceToString(
                      Number(props.returnPrice.replaceAll(',', '')),
                    )}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setReturnPrice(
                            e.target.value.replaceAll(',', ''),
                          )
                        : makeMsg('숫자만 입력하세요', 'warning')
                    }
                  />
                  원
                </s.GeneralInfoInputWrapper>
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  교환배송비 왕복
                  <s.GeneralInfoInput
                    type="text"
                    pattern="[0-9,]*"
                    width={'20rem'}
                    value={priceToString(
                      Number(props.changePrice.replaceAll(',', '')),
                    )}
                    onChange={(e) =>
                      e.target.validity.valid
                        ? props.setChangePrice(
                            e.target.value.replaceAll(',', ''),
                          )
                        : makeMsg('숫자만 입력하세요', 'warning')
                    }
                  />
                  원
                </s.GeneralInfoInputWrapper>
                <s.SectionSemititle>
                  - 구매자가 반품/교환비를 오프라인으로 결제한 경우에는
                  정산금액에 반품/교환 비용이 포함되지 않습니다.
                </s.SectionSemititle>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>반품/교환지 주소
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left">
                <s.AddressTdContent>
                  <s.AddressTdInputButton
                    onClick={() => props.onChangeOpenPost(1)}
                  >
                    우편번호찾기
                  </s.AddressTdInputButton>
                  <s.AddressTdRow>
                    <s.AddressTdInput
                      disabled={true}
                      style={{ backgroundColor: '#DEDEDE' }}
                      value={props.returnChangeAddress}
                      width="15rem"
                    ></s.AddressTdInput>
                    <s.AddressTdInput
                      value={props.returnChangeAddressDetail}
                      onChange={(e) => {
                        props.setReturnChangeAddressDetail(e.target.value);
                      }}
                    />
                  </s.AddressTdRow>
                </s.AddressTdContent>
              </s.SectionTd>
            </s.SectionTr>
            <s.SectionTr>
              <s.SectionTh style={{ width: '15%' }}>
                <s.SectionThSpan>*</s.SectionThSpan>배송안내
              </s.SectionTh>
              <s.SectionTd style={{ width: '85%' }} align="left">
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  배송 택배사
                  <s.GeneralInfoSelect
                    width="20rem"
                    value={props.deliveryCompanyId}
                    onChange={(e) => props.setDeliveryCompanyId(e.target.value)}
                  >
                    <option value="">선택하세요</option>

                    {props.deliveryCompanyList?.map((company: any) => (
                      <option value={company.deliveryCompanyId}>
                        {company.deliveryCompanyNm}
                      </option>
                    ))}
                  </s.GeneralInfoSelect>
                </s.GeneralInfoInputWrapper>
                <s.GeneralInfoInputWrapper
                  style={{ justifyContent: 'flex-start' }}
                >
                  배송기간 안내
                  <s.GeneralInfoInput
                    type="text"
                    placeholder="ex) 배송까지 2~5일 소요됩니다."
                    value={props.deliveryNotice}
                    onChange={(e) => props.setDeliveryNotice(e.target.value)}
                  />
                </s.GeneralInfoInputWrapper>
              </s.SectionTd>
            </s.SectionTr>
          </s.SectionTbody>
        </s.SectionTable>
      </s.ContentArea>
      <s.ButtonWrapper>
        <s.TransactionButton
          color={theme.color.grey}
          onClick={() => props.toList()}
        >
          목록으로
        </s.TransactionButton>
        <s.TransactionButton
          color={theme.color.darkGrey}
          onClick={() => props.save()}
        >
          저장
        </s.TransactionButton>
      </s.ButtonWrapper>
    </s.Container>
  );
};

export default UpsertDeliveryConditionPresenter;
