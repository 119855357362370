import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

import FooterPresenter from './FooterPresenter';

const FooterContainer = () => {
  const comInfo = useRecoilValue(recoilItem.comInfo);

  return (
    <>
      <FooterPresenter comInfo={comInfo} />
    </>
  );
};

FooterContainer.defaultProps = {};

export default FooterContainer;
