import { notLoginedApi, loginedApi } from './api-base';

export const bannerApi = {
  getList: (Data: any) => notLoginedApi.post('/sys/banner/mgnt/getList', Data),

  upsert: (token: any, Data: any) =>
    loginedApi.post('/sys/banner/mgnt/upsert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
