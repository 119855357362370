import Axios, { AxiosInstance } from 'axios';
import { API_SERVER } from '../config/config';

import * as util from 'util/util';

export const notLoginedApi: AxiosInstance = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

export const loginedApi: AxiosInstance = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

loginedApi.interceptors.request.use(
  async function (config: any) {
    const token = {
      accessToken: config.headers['X-AUTH-TOKEN'],
    };
    const check = util.tokenCheck(token);

    if (check) {
      return config;
    } else {
      util.makeMsg('로그인이 필요한 서비스입니다.', 'etc');
      window.location.href = '/';
    }
  },
  function (error) {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  },
);
