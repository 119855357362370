import { cartApi } from 'api/api-cart';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ButtonOptions } from 'sweetalert/typings/modules/options/buttons';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from 'sweetalert';

import CartPresenter from './CartPresenter';

const CartContainer = () => {
  const token = useRecoilValue(recoilitem.token);
  const userInfo = useRecoilValue(recoilitem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilitem.isLoading);
  const navigate = useNavigate();

  const [cartItemList, setCartItemList] = useState<any[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = {
        userId: userInfo.userId,
      };

      const res = await cartApi.getList(token, data);

      console.log(res);

      if (res.data.rsltCd === '00') {
        setCartItemList(res.data.data.cartItemList);
      } else {
        setCartItemList([]);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const changeOptionCount = (index: number, value: number) => {
    const temp = cartItemList;
    temp[index].count = value;
    setCartItemList([...temp]);
  };

  const goToProductDetail = (productId: number) => {
    navigate(`/product/${productId}`);
  };

  const updateCartItem = async (cartId: number) => {
    try {
      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '수량을 수정하시겠습니까?',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        setIsLoading(true);
        const data = {
          cartId: cartId,
          userId: userInfo.userId,
          count: cartItemList.find((item: any) => item.cartId === cartId).count,
        };
        const res = await cartApi.update(token, data);

        if (res.data.rsltCd === '00') {
          makeMsg('수정되었습니다.', 'success');
          fetchData();
        } else {
          makeMsg('오류가 발생했습니다.', 'error');
        }

        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const deleteCartItem = async (cartId: number) => {
    try {
      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '장바구니에서 제거하시겠습니까?',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        setIsLoading(true);
        const data = {
          userId: userInfo.userId,
          cartIdList: [cartId],
        };

        const res = await cartApi.multiDelete(token, data);

        if (res.data.rsltCd === '00') {
          makeMsg('장바구니에서 제거하였습니다.', 'success');
          fetchData();
        } else {
          makeMsg('오류가 발생했습니다.', 'error');
        }

        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const multiDeleteCartItem = async () => {
    try {
      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '장바구니에서 제거하시겠습니까?',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        setIsLoading(true);
        const data = {
          userId: userInfo.userId,
          cartIdList: cartItemList
            .filter((item: any) => item.checked)
            .map((item: any) => item.cartId),
        };

        const res = await cartApi.multiDelete(token, data);

        if (res.data.rsltCd === '00') {
          makeMsg('장바구니에서 제거하였습니다.', 'success');
          fetchData();
        } else {
          makeMsg('오류가 발생했습니다.', 'error');
        }

        setIsLoading(false);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      setIsLoading(false);
    }
  };

  const orderProduct = async () => {
    try {
      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '상품을 구매하시겠습니까?',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        navigate('/order', {
          state: { itemList: cartItemList.filter((item: any) => item.checked) },
        });
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  return (
    <>
      <CartPresenter
        cartItemList={cartItemList}
        setCartItemList={setCartItemList}
        changeOptionCount={changeOptionCount}
        goToProductDetail={goToProductDetail}
        updateCartItem={updateCartItem}
        deleteCartItem={deleteCartItem}
        multiDeleteCartItem={multiDeleteCartItem}
        orderProduct={orderProduct}
      />
    </>
  );
};

CartContainer.defaultProps = {};

export default CartContainer;
