import React, { useState, useEffect } from 'react';

import ArticlePresenter from './ArticlePresenter';

const ArticleContainer = () => {
  return (
    <>
      <ArticlePresenter />
    </>
  );
};

ArticleContainer.defaultProps = {};

export default ArticleContainer;
