import theme from 'components/styles/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-width: 192rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
    min-height: 0;
  }
`;

export const ContentArea = styled.div`
  width: 120rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 5rem 0;
  margin: 0 auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const OrderTitle = styled.div`
  width: 100%;
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: bold;
  text-align: center;
`;

export const SectionWrapper = styled.div`
  display: flex;
  gap: 3rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const ItemListArea = styled.div`
  width: 70rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const SectionHeader = styled.div<{ border: string }>`
  font-size: 1.6rem;
  color: ${theme.color.black};
  font-weight: 500;
  text-align: left;
  border-bottom: ${(props) => props.border};
  padding: 1rem 0;
  position: relative;
`;

export const LinkButton = styled(Link)`
  width: 14rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.primary};
  border-radius: 5rem;
  cursor: pointer;
`;

export const ProductItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
`;

export const ProductItem = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  position: relative;
  border-bottom: 1px solid ${theme.color.lightGrey};
`;

export const ProductItemThumbnail = styled.img`
  width: 13rem;
  height: 13rem;
  object-fit: contain;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 20%;
    height: 100%;
  }
`;

export const ProductTextList = styled.div`
  width: 52rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

export const ProductNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  cursor: pointer;

  &:hover {
    color: ${theme.color.secondary};
  }

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const ModelNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  color: ${theme.color.lightGrey};
  font-weight: 500;
  text-align: left;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
    overflow: hidden;
  }
`;

export const BrandNm = styled.span`
  color: ${theme.color.primary};
  margin-right: 1rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const OptionNm = styled.div`
  width: 30rem;
  font-size: 1.4rem;
  color: ${theme.color.secondary};
  font-weight: 500;
  text-align: left;
  margin-bottom: 6rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
  }
`;

export const ItemCountArea = styled.div`
  display: flex;
  position: absolute;
  left: 16rem;
  bottom: 2rem;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    width: auto;
    font-size: 1.2rem;
    bottom: 1rem;
  }
`;

export const TotalPrice = styled.div`
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  right: 0;
  bottom: 2rem;

  @media screen and (max-width: 800px) {
    position: relative;
    font-size: 1.6rem;
    margin-bottom: 3rem;
    text-align: right;
    width: 100%;
  }
`;

export const OrderInfoTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: 6rem;
`;

export const OrderInfoTbody = styled.tbody``;

export const OrderInfoTr = styled.tr``;

export const OrderInfoTh = styled.th`
  padding: 2rem 0;
  width: 20rem;
  text-align: left;
  font-size: 1.4rem;
  color: ${theme.color.black};
  vertical-align: top;

  @media screen and (max-width: 800px) {
    width: 25%;
  }
`;

export const OrderInfoTd = styled.td<{ noPad?: boolean }>`
  padding: ${(props) => (props.noPad ? '0' : '2rem 0')};
  text-align: left;
  font-size: 1.4rem;
  color: ${theme.color.black};
  vertical-align: top;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 75%;
  }
`;

export const OrderInfoHorizontalWrapper = styled.div<{ margin?: boolean }>`
  width: 100%;
  display: flex;
  gap: 1rem;
  ${(props) => (props.margin ? 'margin-bottom: 1rem;' : null)}
`;

export const OrderInfoInput = styled.input<{
  backgroundColor?: string;
  margin?: boolean;
}>`
  padding: 1rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  width: 100%;
  ${(props) => (props.margin ? 'margin-bottom: 1rem;' : null)}
`;

export const OrderInfoSelect = styled.select<{
  backgroundColor?: string;
  margin?: boolean;
}>`
  padding: 1rem;
  border: 1px solid ${theme.color.lightGrey};
  color: ${theme.color.black};
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  width: 100%;
  ${(props) => (props.margin ? 'margin-bottom: 1rem;' : null)}
  appearance: none;
`;

export const PopupOptionSelectChevron = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 3.2rem;
`;

export const OrderInfoSpan = styled.span<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : theme.color.black)};
`;

export const PgListBox = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const PgButton = styled.div<{ active: boolean }>`
  width: 19rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${(props) =>
    props.active ? theme.color.secondary : theme.color.black};
  ${(props) =>
    props.active ? `box-shadow: 0 0 0.3rem 0.3rem ${theme.color.third};` : null}
  transition: 0.25s ease-out;
  border: ${(props) =>
    props.active
      ? `1px solid ${theme.color.third}`
      : `1px solid ${theme.color.lightGrey}`};
`;

export const PgIcon = styled.img`
  width: 6rem;
  height: 6rem;
  object-fit: contain;
`;

export const SummaryArea = styled.div`
  width: 47rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 95%;
    margin: 0 auto;
  }
`;

export const SummaryTopBox = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 2px solid ${theme.color.black};
  display: flex;
  flex-direction: column;
`;

export const SummaryTopTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  color: ${theme.color.black};
  text-align: left;
  margin-bottom: 2rem;
`;

export const SummaryTopBoxRow = styled.div`
  width: 100%;
  position: relative;
  font-size: 1.4rem;
  color: ${theme.color.black};
  text-align: left;
  padding: 0.5rem 0;
  font-weight: 500;
`;

export const SummaryTopBoxRowCount = styled.div`
  position: absolute;
  right: 0;
  top: 0.5rem;
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: bold;
`;

export const SummaryTopBoxTotal = styled.div`
  width: 100%;
  position: relative;
  padding: 2.5rem 0 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-top: 1px solid ${theme.color.lightGrey};
  text-align: left;
  margin-top: 3rem;
  color: ${theme.color.secondary};
`;

export const SummaryTopBoxTotalCount = styled.div`
  position: absolute;
  right: 0;
  top: 2rem;
  font-size: 2rem;
  color: ${theme.color.secondary};
  font-weight: bold;
`;

export const AgreementBox = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  position: relative;
`;

export const AgreementIcon = styled.div`
  padding-top: 0.2rem;
  width: 2rem;
  height: 2.2rem;
  cursor: pointer;
`;

export const AgreementStatement = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2rem;
  text-align: left;
  width: 28rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const OrderButton = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: ${theme.color.primary};
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0.5rem;
`;

export const OpenAddrSearchButton = styled.div`
  width: 10rem;
  padding: 1rem;
  font-size: 1.4rem;
  color: #ffffff;
  background-color: ${theme.color.primary};
  cursor: pointer;
  text-align: center;
  border-radius: 0.5rem;
`;

export const AddressPopupWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 10%;
  width: 50rem;
  height: 70rem;
  z-index: 9999999999;
  border: 1px solid ${theme.color.lightGrey};
  box-shadow: 0 0 0.3rem 0.3rem ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const AddressPopupTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${theme.color.primary};
  font-weight: 500;
  padding: 1rem;
  text-align: left;
`;

export const AddressPopupCloseButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const AddressTdContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const AddressTdInputButton = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.darkGrey};
  background-color: #ffffff;
  color: ${theme.color.black};
  font-size: 1.2rem;
  cursor: pointer;
`;

export const AddressTdRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const AddressTdInput = styled.input<{ width?: string }>`
  padding: 0.5rem;
  width: ${(props) => (props.width ? props.width : '35rem')};
  font-size: 1.2rem;
  border: 1px solid #ababab;
`;
