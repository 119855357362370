import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

import SignUpPresenter from './SignUpPresenter';

const SignUpContainer = () => {
  const comInfo = useRecoilValue(recoilItem.comInfo);
  return (
    <>
      <SignUpPresenter comInfo={comInfo} />
    </>
  );
};

SignUpContainer.defaultProps = {};

export default SignUpContainer;
