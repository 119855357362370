import styled from 'styled-components';
import theme from '../styles/theme';

export const Container = styled.div`
  min-width: 192rem;
  min-height: 15rem;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  background-color: ${theme.color.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
    flex-wrap: wrap;
  }
`;

export const LogoArea = styled.div`
  width: 12rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 12rem;
    height: 6rem;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 100%;
  object-fit: contain;

  @media screen and (max-width: 800px) {
    width: 12rem;
    height: 6rem;
  }
`;

export const TextArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5rem;
  gap: 0.4rem;

  @media screen and (max-width: 800px) {
    width: 80%;
    align-items: center;
    margin-left: 0;
  }
`;

export const BottomTextBright = styled.div`
  font-size: 1.1rem;
  color: ${theme.color.darkGrey};
  font-family: 'Noto Sans KR';
  margin: 0.2rem 0;
`;

export const BottomTextDark = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.black};
  font-family: 'Noto Sans KR';
  margin: 5px 0;
`;
