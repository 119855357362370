import theme from 'components/styles/theme';
import React, { SetStateAction } from 'react';
import { Dispatch } from 'react';
import { MdClass, MdClose } from 'react-icons/md';
import * as s from './MyInfoStyled';

interface MyInfoProps {
  userInfo: any;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  newPasswordConf: string;
  setNewPasswordConf: Dispatch<SetStateAction<string>>;

  openUpdatePasswordPopup: boolean;
  setOpenUpdatePasswordPopup: Dispatch<SetStateAction<boolean>>;
  updatePassword: () => void;
}

const MyInfoPresenter = (props: MyInfoProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>내 정보</s.PageTitle>
        <s.PageIndicator>{'마이페이지 > 내 정보'}</s.PageIndicator>
      </s.PageHeaderArea>
      <s.MyPageTable>
        <s.MyPageTbody>
          <s.MyPageTr>
            <s.MyPageTh width="20rem">이름</s.MyPageTh>
            <s.MyPageTd style={{ textAlign: 'left' }}>
              {props.userInfo.userNm}
            </s.MyPageTd>
          </s.MyPageTr>
          <s.MyPageTr>
            <s.MyPageTh width="20rem">이메일</s.MyPageTh>
            <s.MyPageTd style={{ textAlign: 'left' }}>
              {props.userInfo.userEmail}
            </s.MyPageTd>
          </s.MyPageTr>
          <s.MyPageTr>
            <s.MyPageTh width="20rem">전화번호</s.MyPageTh>
            <s.MyPageTd style={{ textAlign: 'left' }}>
              {props.userInfo.userMobno}
            </s.MyPageTd>
          </s.MyPageTr>
          <s.MyPageTr>
            <s.MyPageTh width="20rem">비밀번호변경</s.MyPageTh>
            <s.MyPageTd style={{ textAlign: 'left', padding: '0 1rem' }}>
              <s.MyPageTdButton
                onClick={() => props.setOpenUpdatePasswordPopup(true)}
              >
                변경
              </s.MyPageTdButton>
            </s.MyPageTd>
          </s.MyPageTr>
        </s.MyPageTbody>
      </s.MyPageTable>
      {props.openUpdatePasswordPopup ? (
        <s.UpdatePasswordBackground>
          <s.UpdatePasswordPopup>
            <s.ClosePopupButton
              onClick={() => props.setOpenUpdatePasswordPopup(false)}
            >
              <MdClose size={'100%'} color={theme.color.black} />
            </s.ClosePopupButton>
            <s.PopupTitle>비밀번호 변경</s.PopupTitle>
            <s.PopupRow>
              <s.PopupRowLabel>기존 비밀번호</s.PopupRowLabel>
              <s.PopupRowInput
                type="password"
                placeholder="기존비밀번호를 입력하세요"
                value={props.password}
                onChange={(e) => props.setPassword(e.target.value)}
              />
            </s.PopupRow>
            <s.PopupRow>
              <s.PopupRowLabel>신규 비밀번호</s.PopupRowLabel>
              <s.PopupRowInput
                type="password"
                placeholder="8~16자리, 영문 대소문자, 숫자, 특수문자 조합"
                value={props.newPassword}
                onChange={(e) => props.setNewPassword(e.target.value)}
              />
            </s.PopupRow>
            <s.PopupRow>
              <s.PopupRowLabel>비밀번호 확인</s.PopupRowLabel>
              <s.PopupRowInput
                type="password"
                placeholder="신규비밀번호를 다시 입력하세요"
                value={props.newPasswordConf}
                onChange={(e) => props.setNewPasswordConf(e.target.value)}
              />
            </s.PopupRow>
            <s.PopupButton onClick={() => props.updatePassword()}>
              변경하기
            </s.PopupButton>
          </s.UpdatePasswordPopup>
        </s.UpdatePasswordBackground>
      ) : null}
    </s.Container>
  );
};

export default MyInfoPresenter;
