import React from 'react';
import * as s from './ArticleStyled';
import { Link } from 'react-router-dom';

interface ArticleProps {}

const ArticlePresenter = (props: ArticleProps) => {
  return (
    <s.Container>
      <s.PageHeaderArea>
        <s.PageTitle>내 게시글</s.PageTitle>
        <s.PageIndicator>{'마이페이지 > 내 게시글'}</s.PageIndicator>
      </s.PageHeaderArea>
    </s.Container>
  );
};

export default ArticlePresenter;
