import React, { useState, useEffect } from 'react';

import ProductPresenter from './ProductPresenter';

const ProductContainer = () => {
  const [active, setActive] = useState<number>(0);
  const [currentCondition, setCurrentCondition] = useState<any>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  return (
    <>
      <ProductPresenter
        active={active}
        setActive={setActive}
        currentCondition={currentCondition}
        setCurrentCondition={setCurrentCondition}
        currentProduct={currentProduct}
        setCurrentProduct={setCurrentProduct}
      />
    </>
  );
};

ProductContainer.defaultProps = {};

export default ProductContainer;
