import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;

export const SectionTitle = styled.div`
  font-size: 1.4rem;
  color: ${theme.color.black};
  font-weight: 500;
  margin-top: 2rem;
`;

export const SectionTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`;

export const SectionTbody = styled.tbody``;

export const SectionTr = styled.tr``;

export const SectionTh = styled.th<{
  center?: boolean;
  color?: string;
  noPad?: boolean;
}>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  color: ${(props) => (props.color ? props.color : theme.color.black)};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  background-color: ${theme.color.grey};
  border: 1px solid ${theme.color.lightGrey};
`;

export const SectionTd = styled.td<{ noPad?: boolean; align?: string }>`
  padding: ${(props) => (props.noPad ? '0' : '1rem')};
  font-size: 1.2rem;
  color: ${theme.color.black};
  border: 1px solid ${theme.color.lightGrey};
  text-align: ${(props) => (props.align ? props.align : 'center')};
`;

export const SectionTdText = styled.div``;

export const SectionThSpan = styled.span`
  color: #ff0000;
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0;
  gap: 1rem;
`;

export const SectionSemititle = styled.div`
  color: ${theme.color.grey};
  font-size: 1.2rem;
  text-align: left;
  line-height: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin: 3rem auto;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
`;

export const TransactionButton = styled.div<{ color: string }>`
  padding: 1rem;
  width: 20rem;
  background-color: ${(props) => props.color};
  cursor: pointer;
  color: #ffffff;
  font-size: 1.4rem;
  border-radius: 0.3rem;
  font-weight: 600;
`;

export const GeneralInfoTdWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const GeneralInfoTdVerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const GeneralInfoInputWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

export const GeneralInfoRadio = styled.input``;

export const GeneralInfoLabel = styled.label`
  font-size: 1.4rem;
  color: ${theme.color.black};
`;

export const GeneralInfoInput = styled.input<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const GeneralInfoSelect = styled.select<{ width?: string }>`
  font-size: 1.2rem;
  padding: 0.5rem;
  border: 1px solid ${theme.color.lightGrey};
  width: ${(props) => (props.width ? props.width : '50rem;')};
  margin: 0.2rem;
`;

export const AddressPopupWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 10%;
  width: 50rem;
  height: 70rem;
  z-index: 9999999999;
  border: 1px solid ${theme.color.lightGrey};
  box-shadow: 0 0 0.3rem 0.3rem ${theme.color.lightGrey};
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const AddressPopupTitle = styled.div`
  width: 100%;
  font-size: 1.6rem;
  color: ${theme.color.primary};
  font-weight: 500;
  padding: 1rem;
  text-align: left;
`;

export const AddressPopupCloseButton = styled.div`
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;

export const AddressTdContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const AddressTdInputButton = styled.div`
  padding: 0.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.darkGrey};
  background-color: #ffffff;
  color: ${theme.color.black};
  font-size: 1.2rem;
  cursor: pointer;
`;

export const AddressTdRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const AddressTdInput = styled.input<{ width?: string }>`
  padding: 0.5rem;
  width: ${(props) => (props.width ? props.width : '35rem')};
  font-size: 1.2rem;
  border: 1px solid #ababab;
`;
