import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeMsg, readFileAsDataURL } from 'util/util';
import recoilItem from 'util/recoilitem';

import UpsertProductPresenter from './UpsertProductPresenter';
import { categoryApi } from 'api/api-category';
import { deliveryApi } from 'api/api-delivery';
import { comnCdApi } from 'api/api-comncd';
import { productApi } from 'api/api-product';

interface Props {
  setActive: Dispatch<SetStateAction<number>>;
  currentProduct: any;
}

const UpsertProductContainer = (props: Props) => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);

  const [prevProduct, setPrevProduct] = useState<any>(null);

  const [categoryLv1List, setCategoryLv1List] = useState<any[]>([]);
  const [categoryLv2List, setCategoryLv2List] = useState<any[]>([]);
  const [categoryLv3List, setCategoryLv3List] = useState<any[]>([]);
  const [categoryLv4List, setCategoryLv4List] = useState<any[]>([]);

  const [categoryLv1, setCategoryLv1] = useState<any>(null);
  const [categoryLv2, setCategoryLv2] = useState<any>(null);
  const [categoryLv3, setCategoryLv3] = useState<any>(null);
  const [categoryLv4, setCategoryLv4] = useState<any>(null);

  const [connectedCategoryList, setConnectedCategoryList] = useState<any[]>([]);
  const [sellYn, setSellYn] = useState<string>('Y');
  const [bestYn, setBestYn] = useState<string>('N');
  const [productNm, setProductNm] = useState<string>('');
  const [modelNm, setModelNm] = useState<string>('');
  const [brandNm, setBrandNm] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [thumbnailPreview, setThumbnailPreview] = useState<string>(
    '/img/no_preview.png',
  );
  const [price, setPrice] = useState<number>(0);
  const [priceDiscount, setPriceDiscount] = useState<number>(0);
  const [priceSupply, setPriceSupply] = useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [deliveryCondition, setDeliveryCondition] = useState<any>(null);

  // 배송 팝업
  const [isOpenOptionPopup, setIsOpenOptionPopup] = useState<boolean>(false);
  const [isOpenDeliveryPopup, setIsOpenDeliveryPopup] =
    useState<boolean>(false);

  const [deliveryPage, setDeliveryPage] = useState<number>(1);
  const deliveryPageOffset = 10;
  const [deliveryConditionNm, setDeliveryConditionNm] = useState<string>('');
  const [deliveryCompanyId, setDeliveryCompanyId] = useState<string>('');
  const [deliveryConditionTypeCd, setDeliveryConditionTypeCd] =
    useState<string>('');

  const [deliveryConditionTypeList, setDeliveryConditionTypeList] = useState<
    any[]
  >([]);
  const [deliveryCompanyList, setDeliveryCompanyList] = useState<any[]>([]);

  const [deliveryConditionList, setDeliveryConditionList] = useState<any[]>([]);
  const [deliveryTotalCount, setDeliveryTotalCount] = useState<number>(0);
  const [options, setOptions] = useState<any[]>([]);
  const [originalOptions, setOriginalOptions] = useState<any[]>([]);
  const [confirmedOptions, setConfirmedOptions] = useState<any[]>([]);

  useEffect(() => {
    fetchData(null, 1);
    getDeliveryInitData();
    if (props.currentProduct?.productId) {
      getProductDetail();
    }
  }, []);

  useEffect(() => {
    if (categoryLv1) {
      fetchData(categoryLv1.categoryCd, 2);
    }
  }, [categoryLv1]);

  useEffect(() => {
    if (categoryLv2) {
      fetchData(categoryLv2.categoryCd, 3);
    }
  }, [categoryLv2]);

  useEffect(() => {
    if (categoryLv3) {
      fetchData(categoryLv3.categoryCd, 4);
    }
  }, [categoryLv3]);

  useEffect(() => {
    setCategoryLv2(null);
    setCategoryLv3(null);
    setCategoryLv4(null);
    setCategoryLv2List([]);
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv1List]);

  useEffect(() => {
    setCategoryLv2(null);
    setCategoryLv3(null);
    setCategoryLv4(null);
    setCategoryLv3List([]);
    setCategoryLv4List([]);
  }, [categoryLv2List]);

  useEffect(() => {
    setCategoryLv3(null);
    setCategoryLv4(null);
    setCategoryLv4List([]);
  }, [categoryLv3List]);

  useEffect(() => {
    setCategoryLv4(null);
  }, [categoryLv4List]);

  useEffect(() => {
    if (thumbnail) {
      const reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      reader.onloadend = () => {
        setThumbnailPreview(String(reader.result));
      };
    }
  }, [thumbnail]);

  const getProductDetail = async () => {
    try {
      setIsLoading(true);
      const data = {
        productId: props.currentProduct?.productId,
      };

      const res = await productApi.detail(data);
      if (res.data.rsltCd === '00') {
        setPrevProduct(res.data.data.product);
        setConnectedCategoryList(res.data.data.product.category);
        setProductNm(res.data.data.product.productNm);
        setSellYn(res.data.data.product.sellYn);
        setBestYn(res.data.data.product.bestYn);
        setBrandNm(res.data.data.product.brandNm);
        setModelNm(res.data.data.product.modelNm);
        setKeyword(res.data.data.product.keyword);
        setThumbnailPreview(res.data.data.product.thumbnail);
        setPrice(res.data.data.product.price);
        setPriceDiscount(res.data.data.product.priceDiscount);
        setPriceSupply(res.data.data.product.priceSupply);
        setContent(res.data.data.product.content);
        setOriginalOptions(res.data.data.product.options);
        setConfirmedOptions(res.data.data.product.options);
        setDeliveryCondition(res.data.data.deliveryCondition);
      } else {
        setIsLoading(false);
        makeMsg('네트워크 오류가 발생했습니다.', 'error');
        props.setActive(0);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
      props.setActive(0);
    }
  };

  const fetchData = async (upperCategoryCd: string | null, level: number) => {
    try {
      const data = {
        upperCategoryCd: upperCategoryCd,
      };

      setIsLoading(true);
      const categoryRes = await categoryApi.getList(data);
      if (categoryRes.data.rsltCd === '00') {
        if (level === 1) {
          setCategoryLv1List(categoryRes.data.data.categoryList);
          setCategoryLv1(categoryRes.data.data.categoryList[0]);
        } else if (level === 2) {
          setCategoryLv2List(categoryRes.data.data.categoryList);
        } else if (level === 3) {
          setCategoryLv3List(categoryRes.data.data.categoryList);
        } else if (level === 4) {
          setCategoryLv4List(categoryRes.data.data.categoryList);
        }
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const addCategory = () => {
    const currentCategoryList = connectedCategoryList;
    if (categoryLv4) {
      if (
        currentCategoryList.findIndex(
          (category: any) => category.category === categoryLv4.categoryCd,
        ) > -1
      ) {
        makeMsg('이미 추가된 카테고리입니다.', 'warning');
        return;
      }
      currentCategoryList.push({
        mainCategory: 'N',
        category: categoryLv4.categoryCd,
        categoryString: `${categoryLv1.categoryNm} > ${categoryLv2.categoryNm} > ${categoryLv3.categoryNm} > ${categoryLv4.categoryNm}`,
        categoryLv1: categoryLv1?.categoryCd,
        categoryLv2: categoryLv2?.categoryCd,
        categoryLv3: categoryLv3?.categoryCd,
        categoryLv4: categoryLv4?.categoryCd,
      });
      setConnectedCategoryList([...currentCategoryList]);
    } else if (categoryLv3) {
      if (
        currentCategoryList.findIndex(
          (category: any) => category.category === categoryLv3.categoryCd,
        ) > -1
      ) {
        makeMsg('이미 추가된 카테고리입니다.', 'warning');
        return;
      }
      currentCategoryList.push({
        mainCategory: 'N',
        category: categoryLv3.categoryCd,
        categoryString: `${categoryLv1.categoryNm} > ${categoryLv2.categoryNm} > ${categoryLv3.categoryNm}`,
        categoryLv1: categoryLv1?.categoryCd,
        categoryLv2: categoryLv2?.categoryCd,
        categoryLv3: categoryLv3?.categoryCd,
        categoryLv4: categoryLv4?.categoryCd,
      });
      setConnectedCategoryList([...currentCategoryList]);
    } else if (categoryLv2) {
      if (
        currentCategoryList.findIndex(
          (category: any) => category.category === categoryLv2.categoryCd,
        ) > -1
      ) {
        makeMsg('이미 추가된 카테고리입니다.', 'warning');
        return;
      }
      currentCategoryList.push({
        mainCategory: 'N',
        category: categoryLv2.categoryCd,
        categoryString: `${categoryLv1.categoryNm} > ${categoryLv2.categoryNm}`,
        categoryLv1: categoryLv1?.categoryCd,
        categoryLv2: categoryLv2?.categoryCd,
        categoryLv3: categoryLv3?.categoryCd,
        categoryLv4: categoryLv4?.categoryCd,
      });
      setConnectedCategoryList([...currentCategoryList]);
    } else if (categoryLv1) {
      if (
        currentCategoryList.findIndex(
          (category: any) => category.category === categoryLv1.categoryCd,
        ) > -1
      ) {
        makeMsg('이미 추가된 카테고리입니다.', 'warning');
        return;
      }
      currentCategoryList.push({
        mainCategory: 'N',
        category: categoryLv1.categoryCd,
        categoryString: `${categoryLv1.categoryNm}`,
        categoryLv1: categoryLv1?.categoryCd,
        categoryLv2: categoryLv2?.categoryCd,
        categoryLv3: categoryLv3?.categoryCd,
        categoryLv4: categoryLv4?.categoryCd,
      });

      setConnectedCategoryList([...currentCategoryList]);
    } else {
      makeMsg('카테고리를 선택 후 추가하세요.', 'warning');
      return;
    }
  };

  const removeCategory = (idx: number) => {
    setConnectedCategoryList([
      ...connectedCategoryList.filter(
        (category: any, index: number) => index !== idx,
      ),
    ]);
  };

  const getDeliveryInitData = async () => {
    try {
      const data = {
        upperComnCd: 'DLVTYPE',
      };

      const res = await comnCdApi.getList(data);
      if (res.data.rsltCd === '00') {
        setDeliveryConditionTypeList(
          res.data.data.comnCdList.filter(
            (item: any) =>
              item.comnCd === 'DLVTYPE01' || item.comnCd === 'DLVTYPE02',
          ),
        );
      }

      const deliveryCompanyData = {};
      const res2 = await deliveryApi.getCompanyList(deliveryCompanyData);
      if (res2.data.rsltCd === '00') {
        setDeliveryCompanyList(res2.data.data.deliveryCompanyList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const fetchDeliveryData = async () => {
    try {
      const data: any = {
        page: deliveryPage,
        deliveryConditionNm: deliveryConditionNm,
        deliveryCompanyId: deliveryCompanyId,
        deliveryConditionTypeCd: deliveryConditionTypeCd,
        userId: userInfo.userId,
      };

      setIsLoading(true);
      const res = await deliveryApi.getList(data);
      if (res.data.rsltCd === '00') {
        setDeliveryConditionList(res.data.data.deliveryConditionList);
        setDeliveryTotalCount(res.data.data.totalCount);
      } else {
        setDeliveryConditionList([]);
        setDeliveryTotalCount(0);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const deliverySearch = async () => {
    if (deliveryPage === 1) {
      fetchDeliveryData();
    } else {
      setDeliveryPage(1);
    }
  };

  const handleDeliveryPageChange = async (page: number) => {
    setDeliveryPage(page);
  };

  const openOptionPopup = () => {
    setOptions([...confirmedOptions]);
    setIsOpenOptionPopup(true);
  };

  const closeOptionPopup = () => {
    setIsOpenOptionPopup(false);
  };

  const saveOption = () => {
    const tempOptions = options
      .map((option: any) =>
        !option.price ? { ...option, price: '0' } : { ...option },
      )
      .map((option: any) =>
        !option.priceSupply ? { ...option, priceSupply: '0' } : { ...option },
      )
      .map((option: any) =>
        !option.stock ? { ...option, stock: '0' } : { ...option },
      );

    if (
      tempOptions.findIndex(
        (option: any) =>
          Number(option.price) !== 0 &&
          (!option.price || !option.priceSupply || !option.optionNm),
      ) > -1
    ) {
      makeMsg('모든 값을 입력해주세요.', 'warning');
      return;
    }
    setConfirmedOptions([...tempOptions]);
    setOptions([]);
    setIsOpenOptionPopup(false);
  };

  useEffect(() => {
    fetchDeliveryData();
  }, [deliveryPage]);

  useEffect(() => {
    if (!isOpenDeliveryPopup) {
      setDeliveryConditionList([]);
      setDeliveryTotalCount(0);
      setDeliveryConditionNm('');
      setDeliveryConditionTypeCd('');
      setDeliveryCompanyId('');
      setDeliveryPage(1);
    }
  }, [isOpenDeliveryPopup]);

  const saveProduct = async () => {
    try {
      if (connectedCategoryList.length < 1) {
        makeMsg('카테고리는 반드시 한 개 이상 등록해주세요.', 'warning');
        return;
      }

      if (!productNm.replaceAll(' ', '')) {
        makeMsg('상품명을 입력하세요.', 'warning');
        return;
      }

      if (!modelNm.replaceAll(' ', '')) {
        makeMsg('모델명을 입력하세요.', 'warning');
        return;
      }

      if (!brandNm.replaceAll(' ', '')) {
        makeMsg('브랜드명을 입력하세요', 'warning');
        return;
      }

      if (!thumbnail && !props.currentProduct?.productId) {
        makeMsg('썸네일을 등록하세요.', 'warning');
        return;
      }

      if (!price) {
        makeMsg('출하가를 입력하세요.', 'warning');
        return;
      }

      if (!priceDiscount) {
        makeMsg('판매가를 입력하세요.', 'warning');
        return;
      }

      if (!priceSupply) {
        makeMsg('공급가를 입력하세요.', 'warning');
        return;
      }

      if (!content.replaceAll(' ', '')) {
        makeMsg('상세페이지를 등록하세요.', 'warning');
        return;
      }

      if (confirmedOptions.length < 1) {
        makeMsg('옵션은 반드시 한 개 이상 등록해주세요.', 'warning');
        return;
      }

      if (!deliveryCondition) {
        makeMsg('배송정책을 선택하세요.', 'warning');
        return;
      }

      setIsLoading(true);

      if (props.currentProduct?.productId) {
        const updateOptionList: any[] = [];
        const insertOptionList: any[] = [];
        const deleteOptionList: any[] = [];

        for (let i = 0; i < confirmedOptions.length; i++) {
          const cOption = confirmedOptions[i];
          // 1. no optionId ?
          if (!cOption.optionId) {
            insertOptionList.push(cOption);
          }
        }

        for (let i = 0; i < originalOptions.length; i++) {
          const oOption = originalOptions[i];
          // 1. in confirmed > update
          if (
            confirmedOptions.findIndex(
              (cOption: any) => cOption.optionId === oOption.optionId,
            ) > -1
          ) {
            updateOptionList.push(
              confirmedOptions.find(
                (cOption: any) => cOption.optionId === oOption.optionId,
              ),
            );
          } else {
            deleteOptionList.push(oOption);
          }
        }

        const data: any = {
          productId: props.currentProduct?.productId,
          productNm: productNm,
          modelNm: modelNm,
          brandNm: brandNm,
          price: price,
          priceDiscount: priceDiscount,
          priceSupply: priceSupply,
          bestYn: bestYn,
          sellYn: sellYn,
          keyword: keyword,
          content: content,
          deliveryConditionId: deliveryCondition.deliveryConditionId,
          updateOptions: updateOptionList,
          insertOptions: insertOptionList,
          deleteOptions: deleteOptionList,
          category: connectedCategoryList.map((category: any) => ({
            categoryCd: category.category,
            mainCategory: category.mainCategory,
            categoryLv1: category.categoryLv1,
            categoryLv2: category.categoryLv2,
            categoryLv3: category.categoryLv3,
            categoryLv4: category.categoryLv4,
          })),
          comId: comInfo.comId,
          userId: userInfo.userId,
        };

        if (thumbnail) {
          data.thumbnail = await readFileAsDataURL(thumbnail);
        }

        console.log(data);

        const updateRes = await productApi.update(token, data);
        console.log(updateRes);
        if (updateRes.data.rsltCd === '00') {
          makeMsg('저장되었습니다.', 'success');
          props.setActive(0);
        } else {
          makeMsg('오류가 발생하였습니다.', 'error');
        }
        setIsLoading(false);
      } else {
        const data: any = {
          productNm: productNm,
          modelNm: modelNm,
          brandNm: brandNm,
          price: price,
          priceDiscount: priceDiscount,
          priceSupply: priceSupply,
          bestYn: bestYn,
          sellYn: sellYn,
          keyword: keyword,
          content: content,
          deliveryConditionId: deliveryCondition.deliveryConditionId,
          options: confirmedOptions,
          category: connectedCategoryList.map((category: any) => ({
            categoryCd: category.category,
            mainCategory: category.mainCategory,
            categoryLv1: category.categoryLv1,
            categoryLv2: category.categoryLv2,
            categoryLv3: category.categoryLv3,
            categoryLv4: category.categoryLv4,
          })),
          comId: comInfo.comId,
          userId: userInfo.userId,
        };

        data.thumbnail = await readFileAsDataURL(thumbnail);

        const insertRes = await productApi.insert(token, data);

        if (insertRes.data.rsltCd === '00') {
          makeMsg('저장되었습니다.', 'success');
          props.setActive(0);
        } else {
          makeMsg('오류가 발생하였습니다.', 'error');
          return;
        }
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  const deleteProduct = async () => {
    try {
      const data = {
        productId: props.currentProduct?.productId,
        userId: userInfo.userId,
      };

      const res = await productApi.delete(token, data);

      if (res.data.rsltCd === '00') {
        makeMsg('삭제되었습니다.', 'success');

        setIsLoading(false);
        props.setActive(0);
      } else {
        makeMsg('권한이 없습니다.', 'error');
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 에러가 발생하였습니다.', 'error');
    }
  };

  return (
    <>
      <UpsertProductPresenter
        userInfo={userInfo}
        categoryLv1List={categoryLv1List}
        categoryLv2List={categoryLv2List}
        categoryLv3List={categoryLv3List}
        categoryLv4List={categoryLv4List}
        categoryLv1={categoryLv1}
        categoryLv2={categoryLv2}
        categoryLv3={categoryLv3}
        categoryLv4={categoryLv4}
        setCategoryLv1={setCategoryLv1}
        setCategoryLv2={setCategoryLv2}
        setCategoryLv3={setCategoryLv3}
        setCategoryLv4={setCategoryLv4}
        connectedCategoryList={connectedCategoryList}
        setConnectedCategoryList={setConnectedCategoryList}
        addCategory={addCategory}
        removeCategory={removeCategory}
        sellYn={sellYn}
        setSellYn={setSellYn}
        bestYn={bestYn}
        setBestYn={setBestYn}
        productNm={productNm}
        setProductNm={setProductNm}
        modelNm={modelNm}
        setModelNm={setModelNm}
        brandNm={brandNm}
        setBrandNm={setBrandNm}
        keyword={keyword}
        setKeyword={setKeyword}
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        thumbnailPreview={thumbnailPreview}
        price={price}
        setPrice={setPrice}
        priceDiscount={priceDiscount}
        setPriceDiscount={setPriceDiscount}
        priceSupply={priceSupply}
        setPriceSupply={setPriceSupply}
        content={content}
        setContent={setContent}
        isOpenOptionPopup={isOpenOptionPopup}
        setIsOpenOptionPopup={setIsOpenOptionPopup}
        isOpenDeliveryPopup={isOpenDeliveryPopup}
        setIsOpenDeliveryPopup={setIsOpenDeliveryPopup}
        deliveryPage={deliveryPage}
        setDeliveryPage={setDeliveryPage}
        deliveryConditionNm={deliveryConditionNm}
        setDeliveryConditionNm={setDeliveryConditionNm}
        deliveryCompanyId={deliveryCompanyId}
        setDeliveryCompanyId={setDeliveryCompanyId}
        deliveryConditionTypeCd={deliveryConditionTypeCd}
        setDeliveryConditionTypeCd={setDeliveryConditionTypeCd}
        deliveryConditionTypeList={deliveryConditionTypeList}
        deliveryCompanyList={deliveryCompanyList}
        deliveryTotalCount={deliveryTotalCount}
        deliveryConditionList={deliveryConditionList}
        deliverySearch={deliverySearch}
        handleDeliveryPageChange={handleDeliveryPageChange}
        deliveryCondition={deliveryCondition}
        setDeliveryCondition={setDeliveryCondition}
        options={options}
        setOptions={setOptions}
        confirmedOptions={confirmedOptions}
        setConfirmedOptions={setConfirmedOptions}
        openOptionPopup={openOptionPopup}
        closeOptionPopup={closeOptionPopup}
        saveOption={saveOption}
        saveProduct={saveProduct}
        prevProduct={prevProduct}
        deleteProduct={deleteProduct}
        deliveryPageOffset={deliveryPageOffset}
      />
    </>
  );
};

UpsertProductContainer.defaultProps = {};

export default UpsertProductContainer;
