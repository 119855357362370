import React, { Dispatch, SetStateAction } from 'react';
import * as s from './ProductStyled';
import { Link } from 'react-router-dom';

import ProductList from './list';
import UpsertProduct from './upsert';
import DeliveryCondition from './deliveryCondition';
import UpsertDeliveryCondition from './upsertDeliveryCondition';

interface ProductProps {
  active: number;
  setActive: Dispatch<SetStateAction<number>>;
  currentCondition: any;
  setCurrentCondition: Dispatch<SetStateAction<any>>;
  currentProduct: any;
  setCurrentProduct: Dispatch<SetStateAction<any>>;
}

const ProductPresenter = (props: ProductProps) => {
  return (
    <s.Container>
      <s.LeftMenu>
        <s.LeftMenuTitle>상품</s.LeftMenuTitle>
        <s.LeftMenuItem level={1}>상품관리</s.LeftMenuItem>
        <s.LeftMenuItem
          level={2}
          active={props.active === 0}
          onClick={() => props.setActive(0)}
        >
          - 상품리스트
        </s.LeftMenuItem>
        <s.LeftMenuItem
          level={2}
          active={props.active === 1}
          onClick={() => props.setActive(1)}
        >
          - 상품등록
        </s.LeftMenuItem>
        <s.LeftMenuItem level={1}>기본설정관리</s.LeftMenuItem>
        <s.LeftMenuItem
          level={2}
          active={props.active === 2}
          onClick={() => props.setActive(2)}
        >
          - 배송조건목록
        </s.LeftMenuItem>
        <s.LeftMenuItem
          level={2}
          active={props.active === 3}
          onClick={() => props.setActive(3)}
        >
          - 배송조건등록
        </s.LeftMenuItem>
      </s.LeftMenu>
      <s.ContentArea>
        {props.active === 0 ? (
          <ProductList
            setActive={props.setActive}
            setCurrentProduct={props.setCurrentProduct}
          />
        ) : props.active === 1 ? (
          <UpsertProduct
            setActive={props.setActive}
            currentProduct={props.currentProduct}
          />
        ) : props.active === 2 ? (
          <DeliveryCondition
            setActive={props.setActive}
            setCurrentCondition={props.setCurrentCondition}
          />
        ) : props.active === 3 ? (
          <UpsertDeliveryCondition
            setActive={props.setActive}
            currentCondition={props.currentCondition}
          />
        ) : null}
      </s.ContentArea>
    </s.Container>
  );
};

export default ProductPresenter;
