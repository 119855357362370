import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  min-width: 192rem;
  position: relative;
  height: 100vh;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    height: auto;
    min-width: 0;
    height: 100vh;
    width: 100%;
  }
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;

  @media screen and (max-width: 800px) {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const LogoArea = styled.img`
  width: 15rem;
  height: 8rem;
  object-fit: contain;
`;

export const ButtonRow = styled.div`
  display: flex;
  gap: 5rem;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

export const ButtonItem = styled.div`
  width: 50rem;
  height: 40rem;
  border-radius: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.color.veryLightGrey};
  position: relative;

  @media screen and (max-width: 800px) {
    width: 95%;
    height: auto;
    padding: 4rem 0;
    margin: 0 auto;
  }
`;

export const ButtonTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${theme.color.black};
`;

export const ButtonTexts = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  color: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 3rem;
  margin: 4rem 0 8rem;
`;

export const RegisterButton = styled(Link)`
  position: absolute;
  bottom: 3rem;
  border-radius: 5rem;
  background-color: ${theme.color.primary};
  color: #ffffff;
  cursor: pointer;
  width: 90%;
  font-size: 1.6rem;
  padding: 1.5rem;
`;
