import styled, { keyframes } from 'styled-components';
import theme from 'components/styles/theme';

export const waving = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 192rem;
  }
`;

export const waving2 = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -192rem;
  }
`;

export const Container = styled.div`
  width: 100%;
  min-width: 192rem;
  min-height: 60rem;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 800px) {
    min-width: 0;
  }
`;

export const TopSelectArea = styled.div`
  width: 100%;
  height: 5rem;
  min-width: 192rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 800px) {
    min-width: 0;
    margin-bottom: 5rem;
  }
`;
export const TopItemWrapper = styled.div`
  width: 120rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const TopSelectItem = styled.div<{ focused?: boolean }>`
  width: 10rem;
  height: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.focused ? theme.color.primary : theme.color.black};
  border-bottom: ${(props) =>
    props.focused
      ? `0.3rem solid ${theme.color.primary}`
      : '0.3rem solid transparent'};
  cursor: pointer;

  @media screen and (max-width: 800px) {
    width: 8rem;
  }
`;

export const TopRightArea = styled.div`
  display: flex;
  gap: 2rem;
  position: absolute;
  right: 0;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    bottom: -5rem;
    right: 1rem;
  }
`;

export const KeywordSearchArea = styled.div`
  width: 20rem;
  height: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    width: 15rem;
  }
`;

export const KeywordSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${theme.color.black};
  background-color: ${theme.color.veryLightGrey};
  placeholder-color: ${theme.color.grey};
  border-radius: 5rem;
  padding: 1rem 1.5rem;
  border: 0;
`;

export const KeywordSearchIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  cursor: pointer;
`;

export const TopOrderBySelect = styled.select`
  width: 10rem;
  height: 100%;
  border: 0;
  font-size: 1.4rem;
  padding: 1rem;

  @media screen and (max-width: 800px) {
    width: 8rem;
    font-size: 1.2rem;
  }
`;

export const BodyArea = styled.div`
  width: 120rem;
  display: flex;
  margin: 0 auto;
  min-height: 80rem;
  border-top: 1px solid ${theme.color.veryLightGrey};

  @media screen and (max-width: 800px) {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 0;
  }
`;

export const LeftArea = styled.div`
  width: 18rem;
  height: 100%;
  min-height: 80rem;
  display: flex;
  flex-direction: column;
  padding: 5rem 2rem 2rem 0rem;
  box-shadow: inset -0.3rem 0.3rem 0.3rem 0rem ${theme.color.veryLightGrey};
  gap: 2rem;
  align-items: flex-start;

  @media screen and (max-width: 800px) {
    width: 100%;
    min-height: 0;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
    box-shadow: none;
    border-bottom: 1px solid ${theme.color.veryLightGrey};
  }
`;

export const LeftTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: bold;
  margin-bottom: 4rem;
  text-align: left;
  width: 12rem;

  @media screen and (max-width: 800px) {
    width: 100%;
    padding: 2rem;
    margin: 2rem 0 0;
  }
`;

export const LeftItem = styled.div<{ active: boolean }>`
  font-size: 1.6rem;
  padding: 1rem 0;
  width: 12rem;
  text-align: left;
  border-bottom: ${(props) =>
    props.active
      ? `0.3rem solid ${theme.color.primary}`
      : '0.3rem solid transparent'};
  color: ${(props) => (props.active ? theme.color.primary : theme.color.black)};
  font-weight: ${(props) => (props.active ? '500' : '400')};
  cursor: pointer;
  @media screen and (max-width: 800px) {
    width: 20%;
    padding: 1rem;
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const RightArea = styled.div`
  width: 102rem;
  padding: 2rem 0 2rem 2rem;
  @media screen and (max-width: 800px) {
    width: 95%;
    padding: 0;
    margin: 3rem auto;
  }
`;
