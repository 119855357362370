import { notLoginedApi, loginedApi } from './api-base';

export const cartApi = {
  getList: (token: any, Data: any) =>
    loginedApi.post('/prod/cart/getList', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  multiInsert: (token: any, Data: any) =>
    loginedApi.post('/prod/cart/multiInsert', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  multiDelete: (token: any, Data: any) =>
    loginedApi.post('/prod/cart/multiDelete', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),

  update: (token: any, Data: any) =>
    loginedApi.post('/prod/cart/update', Data, {
      headers: {
        'X-AUTH-TOKEN': token.accessToken,
        'X-AUTH-REFRESH-TOKEN': token.refreshToken,
      },
    }),
};
