import styled from 'styled-components';
import theme from 'components/styles/theme';

export const Container = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  text-align: center;
`;

export const PageHeaderArea = styled.div`
  width: 100%;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.color.black};
  display: flex;
  position: relative;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  color: ${theme.color.black};
  font-weight: 500;
`;

export const PageIndicator = styled.div`
  font-size: 1.2rem;
  color: ${theme.color.grey};
  position: absolute;
  right: 0rem;
  bottom: 1.5rem;
`;
