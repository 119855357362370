import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ButtonOptions } from 'sweetalert/typings/modules/options/buttons';
import recoilitem from 'util/recoilitem';
import { makeMsg } from 'util/util';
import swal from 'sweetalert';
import * as s from './OrderStyled';

import OrderPresenter from './OrderPresenter';
import { MdClose } from 'react-icons/md';
import theme from 'components/styles/theme';
import DaumPostcode from 'react-daum-postcode';
import { pgList } from 'config/config';
import { v4 as uuidv4 } from 'uuid';
import { orderApi } from 'api/api-order';
import moment from 'moment';

declare const window: Window &
  typeof globalThis & {
    IMP: any;
  };

const OrderContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const userInfo = useRecoilValue(recoilitem.userInfo);
  const comInfo = useRecoilValue(recoilitem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilitem.isLoading);
  const token = useRecoilValue(recoilitem.token);

  const [itemList, setItemList] = useState<any[]>([]);
  const [agreePayment, setAgreePayment] = useState<boolean>(false);
  const [pg, setPg] = useState<number>(-1);
  const orderRequestList = [
    '배송 전 연락 바랍니다.',
    '파손위험이 있으니 조심히 다뤄주세요.',
    '부재시 경비실에 맡겨주세요.',
    '부재시 무인택배함에 맡겨주세요.',
    '부재시 문앞에 놓아주세요.',
    '부재시 핸드폰으로 연락주세요.',
    '기타',
  ];

  const [ordererEmail, setOrdererEmail] = useState<string>(userInfo.userEmail);
  const [receiverNm, setReceiverNm] = useState<string>('');
  const [receiverMobno, setReceiverMobno] = useState<string>('');
  const [orderRequest, setOrderRequest] = useState<string>('');
  const [orderRequestDetail, setOrderRequestDetail] = useState<string>('');
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [deliveryZipcode, setDeliveryZipcode] = useState<string>('');
  const [deliveryAddr, setDeliveryAddr] = useState<string>('');
  const [deliveryAddrDetail, setDeliveryAddrDetail] = useState<string>('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state?.itemList) {
      setItemList(location.state?.itemList);
    } else {
      makeMsg('주문할 상품을 선택해주세요.', 'warning');
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    console.log(itemList);
  }, [itemList]);

  const payment = async () => {
    try {
      window.IMP.request_pay(
        {
          // param
          pg: pgList[pg].code,
          pay_method: pgList[pg].payMethod,
          merchant_uid: String(comInfo.comId).padStart(4, '0') + uuidv4(),
          name: `${itemList[0].productNm} ${itemList[0].optionNm} 외 ${
            itemList.length - 1
          } 건`,
          amount: Number(
            itemList.reduce(
              (a: any, b: any) =>
                a + (b.priceDiscount + b.optionPrice) * b.count,
              0,
            ) +
              Number(
                itemList.reduce(
                  (a: any, b: any) => a + Number(b.deliveryPrice),
                  0,
                ),
              ),
          ),
          buyer_email: ordererEmail,
          buyer_name: userInfo.userNm,
          buyer_tel: userInfo.userMobno,
          buyer_addr: deliveryAddr + ' ' + deliveryAddrDetail,
          buyer_postcode: deliveryZipcode,
          vbank_due: moment().add('7', 'days').format('YYYYMMDD'),
        },
        (rsp: any) => {
          if (rsp.success) {
            saveOrder(rsp);
          } else {
            makeMsg(rsp.error_msg, 'warning');
          }
        },
      );
    } catch (e) {
      console.error(e);
      makeMsg('오류가 발생하였습니다.', 'error');
    }
  };

  const saveOrder = async (rsp: any) => {
    try {
      const data: any = {};
      const orderList: any = [];
      for (let i = 0; i < itemList.length; i++) {
        const item = itemList[i];
        const orderDto = {
          userId: userInfo.userId,
          productId: item.productId,
          optionId: item.optionId,
          count: item.count,
          deliveryZipcode: deliveryZipcode,
          deliveryAddr: deliveryAddr + ' ' + deliveryAddrDetail,
          receiverNm: receiverNm,
          receiverMobno: receiverMobno,
          orderRequest:
            orderRequest === '기타' ? orderRequestDetail : orderRequest,
          // orderStCd: pgList[pg].payMethod === 'vbank' ? 'ORDST01' : 'ORDST02',
          orderStCd: 'ORDST01',
          totalCost:
            (Number(item.priceDiscount) + Number(item.optionPrice)) *
              Number(item.count) +
            Number(item.deliveryPrice),
          merchantUid: rsp.merchant_uid,
          // paymentCd: pgList[pg].paymentCd,
          paymentCd: 'V',
          vbankValidDtm: rsp.vbank_date,
          vbankAccount: rsp.vbank_num,
          vbankName: rsp.vbank_name,
        };
        orderList.push(orderDto);
      }

      data.orderList = orderList;

      setIsLoading(true);
      const res = await orderApi.multiInsert(token, data);

      if (res.data.rsltCd === '00') {
        makeMsg('주문이 완료되었습니다.', 'success');
        setIsLoading(false);
        navigate('/mypage/3');
      } else {
        makeMsg('오류가 발생했습니다.', 'error');
        setIsLoading(false);
        // 전액환불로직
      }
    } catch (e) {
      console.error(e);
      makeMsg('오류가 발생했습니다.', 'error');
      setIsLoading(false);
      // 전액환불로직
    }
  };

  const goToProductDetail = (productId: number) => {
    navigate(`/product/${productId}`);
  };

  const orderProduct = async () => {
    try {
      if (!agreePayment) {
        makeMsg('약관에 먼저 동의해주세요.', 'warning');
        return;
      }

      if (!ordererEmail.replaceAll(' ', '')) {
        makeMsg('주문자 이메일을 작성해주세요.', 'warning');
        return;
      }

      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(ordererEmail)) {
        makeMsg('이메일 형식에 맞게 입력해주세요.', 'warning');
        return;
      }

      // 배송지
      if (!deliveryAddr.replaceAll(' ', '')) {
        makeMsg('배송지를 입력하세요.', 'warning');
        return;
      }

      if (
        !receiverNm.replaceAll(' ', '') ||
        !receiverMobno.replaceAll(' ', '')
      ) {
        makeMsg('수령인 정보를 입력하세요.', 'warning');
        return;
      }

      if (
        !/^01([0|1|2|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(receiverMobno)
      ) {
        makeMsg('휴대폰번호 형식에 맞추어 입력하세요.', 'warning');
        return;
      }

      // if (pg < 0) {
      //   makeMsg('결제수단을 선택하세요.', 'warning');
      //   return;
      // }

      let chk = false;
      const confirmButton: ButtonOptions = {
        text: '예',
        value: 'Y',
        visible: true,
      };

      const cancelButton: ButtonOptions = {
        text: '취소',
        value: 'N',
        visible: true,
      };

      await swal({
        text: '상품을 구매하시겠습니까?\n\n현재 계좌 이제 방식으로 결제 가능합니다.\n기업은행 / 411-104924-01-011 / 주식회사 더블티\n5월 중으로 온라인 결제 방식 도입 중에 있습니다.\n양해 부탁드립니다.',
        buttons: {
          confirmButton,
          cancelButton,
        },
      }).then((value) => {
        switch (value) {
          case 'Y':
            chk = true;
            break;
          default:
            chk = false;
            break;
        }
      });
      if (chk) {
        const tempRsp = {
          merchant_uid: String(comInfo.comId).padStart(4, '0') + uuidv4(),
          vbank_date: moment().add(7, 'days').format('YYYY-MM-DD'),
          vbank_num: '411-104924-01-011',
          vbank_name: 'IBK기업은행',
        };

        saveOrder(tempRsp);
        // payment();
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr +=
          extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    setDeliveryZipcode(data.zonecode);
    setDeliveryAddr(fullAddr);

    setIsOpenPost(false);
  };

  return (
    <>
      {isOpenPost ? (
        <s.AddressPopupWrapper>
          <s.AddressPopupTitle>배송지조회</s.AddressPopupTitle>
          <s.AddressPopupCloseButton onClick={() => setIsOpenPost(false)}>
            <MdClose size={'100%'} color={theme.color.black} />
          </s.AddressPopupCloseButton>
          <DaumPostcode
            style={{
              width: '100%',
              height: '100%',
            }}
            autoClose
            onComplete={onCompletePost}
          />
        </s.AddressPopupWrapper>
      ) : null}
      <OrderPresenter
        pg={pg}
        setPg={setPg}
        pgList={pgList}
        itemList={itemList}
        goToProductDetail={goToProductDetail}
        userInfo={userInfo}
        ordererEmail={ordererEmail}
        setOrdererEmail={setOrdererEmail}
        receiverNm={receiverNm}
        setReceiverNm={setReceiverNm}
        receiverMobno={receiverMobno}
        setReceiverMobno={setReceiverMobno}
        orderRequestList={orderRequestList}
        orderRequest={orderRequest}
        setOrderRequest={setOrderRequest}
        orderRequestDetail={orderRequestDetail}
        setOrderRequestDetail={setOrderRequestDetail}
        agreePayment={agreePayment}
        setAgreePayment={setAgreePayment}
        orderProduct={orderProduct}
        setIsOpenPost={setIsOpenPost}
        deliveryZipcode={deliveryZipcode}
        deliveryAddr={deliveryAddr}
        deliveryAddrDetail={deliveryAddrDetail}
        setDeliveryAddrDetail={setDeliveryAddrDetail}
      />
    </>
  );
};

OrderContainer.defaultProps = {};

export default OrderContainer;
