import React, { Dispatch, SetStateAction } from 'react';
import * as s from './MyPageStyled';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import 'components/styles/Paging.css';
import { priceToString } from 'util/util';
import { FaSearch } from 'react-icons/fa';
import theme from 'components/styles/theme';

import MyInfo from './myinfo';
import Cart from './cart';
import Order from './order';
import Article from './article';

interface MyPageProps {
  userInfo: any;
  searchKeyword: string;
  setSearchKeyword: Dispatch<SetStateAction<string>>;
  goToProductList: (keyword?: string) => void;
  checkSearchEnter: (event: any) => void;
  activeScreen: number;
  setActiveScreen: Dispatch<SetStateAction<number>>;
}

const MyPagePresenter = (props: MyPageProps) => {
  return (
    <s.Container>
      <s.TopSelectArea>
        <s.TopItemWrapper>
          <s.TopSelectItem onClick={() => props.goToProductList('')}>
            전체보기
          </s.TopSelectItem>
          <s.TopSelectItem onClick={() => props.goToProductList('삼성전자')}>
            삼성전자
          </s.TopSelectItem>
          <s.TopSelectItem onClick={() => props.goToProductList('LG전자')}>
            LG전자
          </s.TopSelectItem>
          <s.TopSelectItem onClick={() => props.goToProductList('건강식품')}>
            건강식품
          </s.TopSelectItem>
          <s.TopRightArea>
            <s.KeywordSearchArea>
              <s.KeywordSearchInput
                type="text"
                placeholder="검색어를 입력하세요."
                value={props.searchKeyword}
                onChange={(e) => props.setSearchKeyword(e.target.value)}
                onKeyDown={(e) => props.checkSearchEnter(e)}
              />
              <s.KeywordSearchIcon
                onClick={() => props.goToProductList(props.searchKeyword)}
              >
                <FaSearch color={theme.color.black} size={'100%'} />
              </s.KeywordSearchIcon>
            </s.KeywordSearchArea>
          </s.TopRightArea>
        </s.TopItemWrapper>
      </s.TopSelectArea>
      <s.BodyArea>
        <s.LeftArea>
          <s.LeftTitle>마이페이지</s.LeftTitle>
          <s.LeftItem
            active={props.activeScreen === 1}
            onClick={() => props.setActiveScreen(1)}
          >
            내 정보
          </s.LeftItem>
          <s.LeftItem
            active={props.activeScreen === 2}
            onClick={() => props.setActiveScreen(2)}
          >
            장바구니
          </s.LeftItem>
          <s.LeftItem
            active={props.activeScreen === 3}
            onClick={() => props.setActiveScreen(3)}
          >
            주문내역
          </s.LeftItem>
          <s.LeftItem
            active={props.activeScreen === 4}
            onClick={() => props.setActiveScreen(4)}
          >
            내 게시글
          </s.LeftItem>
        </s.LeftArea>
        <s.RightArea>
          {props.activeScreen === 1 ? (
            <MyInfo />
          ) : props.activeScreen === 2 ? (
            <Cart />
          ) : props.activeScreen === 3 ? (
            <Order />
          ) : props.activeScreen === 4 ? (
            <Article />
          ) : null}
        </s.RightArea>
      </s.BodyArea>
    </s.Container>
  );
};

export default MyPagePresenter;
