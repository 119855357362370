import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import DaumPostcode from 'react-daum-postcode';
import theme from 'components/styles/theme';
import * as s from './UpsertDeliveryConditionStyled';

import UpsertDeliveryConditionPresenter from './UpsertDeliveryConditionPresenter';
import { MdClose } from 'react-icons/md';
import { makeMsg } from 'util/util';
import { comnCdApi } from 'api/api-comncd';
import { deliveryApi } from 'api/api-delivery';
import { useRecoilState, useRecoilValue } from 'recoil';
import recoilItem from 'util/recoilitem';

interface Props {
  setActive: Dispatch<SetStateAction<number>>;
  currentCondition: any;
}

const UpsertDeliveryConditionContainer = (props: Props) => {
  const token = useRecoilValue(recoilItem.token);
  const userInfo = useRecoilValue(recoilItem.userInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [deliveryConditionCdList, setDeliveryConditionCdList] = useState<any[]>(
    [],
  );

  const [deliveryConditionId, setDeliveryConditionId] = useState<string>(
    props.currentCondition ? props.currentCondition?.deliveryConditionId : '',
  );
  const [deliveryConditionNm, setDeliveryConditionNm] = useState<string>(
    props.currentCondition ? props.currentCondition?.deliveryConditionNm : '',
  );
  const [description, setDescription] = useState<string>(
    props.currentCondition ? props.currentCondition?.description : '',
  );
  const [deliveryConditionTypeCd, setDeliveryConditionTypeCd] =
    useState<string>(
      props.currentCondition
        ? props.currentCondition?.deliveryConditionTypeCd
        : '',
    );
  const [price, setPrice] = useState<string>(
    props.currentCondition ? props.currentCondition?.price : '',
  );
  const [etc1, setEtc1] = useState<string>(props.currentCondition?.etc1 || '');
  const [showAdditionalPrice, setShowAdditionalPrice] = useState<boolean>(
    props.currentCondition &&
      (props.currentCondition?.additionalPriceJeju ||
        props.currentCondition?.additionalPriceMountain)
      ? true
      : false,
  );
  const [additionalPriceJeju, setAdditionalPriceJeju] = useState<string>(
    props.currentCondition?.additionalPriceJeju
      ? String(props.currentCondition?.additionalPriceJeju)
      : '',
  );
  const [additionalPriceMountain, setAdditionalPriceMountain] =
    useState<string>(
      props.currentCondition?.additionalPriceMountain
        ? String(props.currentCondition?.additionalPriceMountain)
        : '',
    );
  const [addressDv, setAddressDv] = useState<number>(0);
  const [outputAddress, setOutputAddress] = useState<string>('');
  const [outputAddressDetail, setOutputAddressDetail] = useState<string>(
    props.currentCondition ? props.currentCondition?.outputAddress : '',
  );
  const [returnChangeAddress, setReturnChangeAddress] = useState<string>('');
  const [returnChangeAddressDetail, setReturnChangeAddressDetail] =
    useState<string>(
      props.currentCondition ? props.currentCondition?.returnChangeAddress : '',
    );
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [returnPrice, setReturnPrice] = useState<string>(
    props.currentCondition ? String(props.currentCondition.returnPrice) : '',
  );
  const [changePrice, setChangePrice] = useState<string>(
    props.currentCondition ? String(props.currentCondition?.changePrice) : '',
  );
  const [deliveryCompanyList, setDeliveryCompanyList] = useState<any[]>([]);
  const [deliveryCompanyId, setDeliveryCompanyId] = useState<string>(
    props.currentCondition ? props.currentCondition?.deliveryCompanyId : '',
  );
  const [deliveryNotice, setDeliveryNotice] = useState<string>(
    props.currentCondition ? props.currentCondition?.deliveryNotice : '',
  );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = {
        upperComnCd: 'DLVTYPE',
      };

      const res = await comnCdApi.getList(data);
      if (res.data.rsltCd === '00') {
        setDeliveryConditionCdList(
          res.data.data.comnCdList.filter(
            (item: any) =>
              item.comnCd === 'DLVTYPE01' || item.comnCd === 'DLVTYPE02',
          ),
        );
      }

      const deliveryCompanyData = {};
      const res2 = await deliveryApi.getCompanyList(deliveryCompanyData);
      if (res2.data.rsltCd === '00') {
        setDeliveryCompanyList(res2.data.data.deliveryCompanyList);
      }
    } catch (e) {
      console.error(e);
      makeMsg('네트워크 오류가 발생했습니다.', 'error');
    }
  };

  const onChangeOpenPost = (dv: number) => {
    setIsOpenPost(!isOpenPost);
    setAddressDv(dv);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr +=
          extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    if (addressDv === 0) {
      setOutputAddress(data.zonecode);
      setOutputAddressDetail(fullAddr);
    } else {
      setReturnChangeAddress(data.zonecode);
      setReturnChangeAddressDetail(fullAddr);
    }

    setIsOpenPost(false);
  };

  const toList = () => {
    props.setActive(2);
  };

  const save = async () => {
    try {
      if (!deliveryConditionNm.replaceAll(' ', '')) {
        makeMsg('배송조건명을 입력하세요.', 'warning');
        return;
      }

      if (!deliveryConditionTypeCd) {
        makeMsg('배송조건유형을 선택하세요.', 'warning');
        return;
      }

      if (
        deliveryConditionTypeCd !== 'DLVTYPE02' &&
        !price.replaceAll(' ', '')
      ) {
        makeMsg('가격을 설정하세요.', 'warning');
        return;
      }

      if (
        showAdditionalPrice &&
        (!additionalPriceJeju || !additionalPriceMountain)
      ) {
        makeMsg('지역별 배송비를 입력하세요.', 'warning');
        return;
      }

      if (!outputAddressDetail.replaceAll(' ', '')) {
        makeMsg('출고지 주소를 입력하세요.', 'warning');
        return;
      }

      if (!returnPrice.replaceAll(' ', '')) {
        makeMsg('환불시 배송비를 입력하세요.', 'warning');
        return;
      }

      if (!changePrice.replaceAll(' ', '')) {
        makeMsg('교환시 배송비를 입력하세요.', 'warning');
        return;
      }

      if (!returnChangeAddressDetail.replaceAll(' ', '')) {
        makeMsg('교환/반품 주소를 입력하세요.', 'warning');
        return;
      }

      if (!deliveryCompanyId) {
        makeMsg('배송택배사를 선택하세요.', 'warning');
        return;
      }

      if (!deliveryNotice.replaceAll(' ', '')) {
        makeMsg('배송안내를 입력하세요.', 'warning');
        return;
      }

      if (deliveryConditionId) {
        const data: any = {
          deliveryConditionId: deliveryConditionId,
          userId: userInfo.userId,
          deliveryConditionNm: deliveryConditionNm,
          description: description,
          deliveryConditionTypeCd: deliveryConditionTypeCd,
          price:
            deliveryConditionTypeCd === 'DLVTYPE01'
              ? price.replaceAll(',', '')
              : deliveryConditionTypeCd === 'DLVTYPE02'
              ? '0'
              : price,
          etc1: etc1,
          additionalPriceJeju: additionalPriceJeju,
          additionalPriceMountain: additionalPriceMountain,
          outputAddress: outputAddressDetail,
          returnPrice: returnPrice.replaceAll(',', ''),
          changePrice: changePrice.replaceAll(',', ''),
          returnChangeAddress: returnChangeAddressDetail,
          deliveryCompanyId: deliveryCompanyId,
          deliveryNotice: deliveryNotice,
        };

        setIsLoading(true);
        const response = await deliveryApi.update(token, data);
        if (response.data.rsltCd === '00') {
          makeMsg('수정되었습니다.', 'success');
          props.setActive(2);
        } else {
          makeMsg('네트워크 에러가 발생하였습니다.', 'error');
        }
        setIsLoading(false);
      } else {
        const data: any = {
          userId: userInfo.userId,
          deliveryConditionNm: deliveryConditionNm,
          description: description,
          deliveryConditionTypeCd: deliveryConditionTypeCd,
          price:
            deliveryConditionTypeCd === 'DLVTYPE01'
              ? price.replaceAll(',', '')
              : deliveryConditionTypeCd === 'DLVTYPE02'
              ? '0'
              : price,
          etc1: etc1,
          additionalPriceJeju: additionalPriceJeju,
          additionalPriceMountain: additionalPriceMountain,
          outputAddress: outputAddressDetail,
          returnPrice: returnPrice.replaceAll(',', ''),
          changePrice: changePrice.replaceAll(',', ''),
          returnChangeAddress: returnChangeAddressDetail,
          deliveryCompanyId: deliveryCompanyId,
          deliveryNotice: deliveryNotice,
        };

        setIsLoading(true);
        const response = await deliveryApi.insert(token, data);
        if (response.data.rsltCd === '00') {
          makeMsg('등록되었습니다.', 'success');
          props.setActive(2);
        } else {
          makeMsg('네트워크 에러가 발생하였습니다.', 'error');
        }
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 오류가 발생하였습니다.', 'error');
    }
  };

  return (
    <>
      {isOpenPost ? (
        <s.AddressPopupWrapper>
          <s.AddressPopupTitle>
            {addressDv === 0 ? '출고지주소조회' : '반품/교환지주소조회'}
          </s.AddressPopupTitle>
          <s.AddressPopupCloseButton onClick={() => setIsOpenPost(false)}>
            <MdClose size={'100%'} color={theme.color.black} />
          </s.AddressPopupCloseButton>
          <DaumPostcode
            style={{
              width: '100%',
              height: '100%',
            }}
            autoClose
            onComplete={onCompletePost}
          />
        </s.AddressPopupWrapper>
      ) : null}
      <UpsertDeliveryConditionPresenter
        deliveryConditionNm={deliveryConditionNm}
        setDeliveryConditionNm={setDeliveryConditionNm}
        description={description}
        setDescription={setDescription}
        price={price}
        setPrice={setPrice}
        etc1={etc1}
        setEtc1={setEtc1}
        additionalPriceJeju={additionalPriceJeju}
        setAdditionalPriceJeju={setAdditionalPriceJeju}
        additionalPriceMountain={additionalPriceMountain}
        setAdditionalPriceMountain={setAdditionalPriceMountain}
        returnPrice={returnPrice}
        setReturnPrice={setReturnPrice}
        changePrice={changePrice}
        setChangePrice={setChangePrice}
        deliveryCompanyId={deliveryCompanyId}
        setDeliveryCompanyId={setDeliveryCompanyId}
        deliveryNotice={deliveryNotice}
        setDeliveryNotice={setDeliveryNotice}
        deliveryCompanyList={deliveryCompanyList}
        deliveryConditionCdList={deliveryConditionCdList}
        deliveryConditionTypeCd={deliveryConditionTypeCd}
        setDeliveryConditionTypeCd={setDeliveryConditionTypeCd}
        toList={toList}
        onChangeOpenPost={onChangeOpenPost}
        outputAddress={outputAddress}
        outputAddressDetail={outputAddressDetail}
        setOutputAddressDetail={setOutputAddressDetail}
        returnChangeAddress={returnChangeAddress}
        returnChangeAddressDetail={returnChangeAddressDetail}
        setReturnChangeAddressDetail={setReturnChangeAddressDetail}
        save={save}
        showAdditionalPrice={showAdditionalPrice}
        setShowAdditionalPrice={setShowAdditionalPrice}
      />
    </>
  );
};

UpsertDeliveryConditionContainer.defaultProps = {};

export default UpsertDeliveryConditionContainer;
