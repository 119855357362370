import React, { useState, useEffect } from 'react';
import recoilItem from 'util/recoilitem';

import SignUpFormPresenter from './SignUpFormPresenter';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeMsg } from 'util/util';
import { userApi } from 'api/api-user';
import { MdClose } from 'react-icons/md';
import DaumPostcode from 'react-daum-postcode';
import theme from 'components/styles/theme';
import * as s from './SignUpFormStyled';

const SignUpFormContainer = () => {
  const navigate = useNavigate();
  const params = useParams();

  const comInfo = useRecoilValue(recoilItem.comInfo);
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [isGetAuthNum, setIsGetAuthNum] = useState<boolean>(false);

  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConf, setPasswordConf] = useState<string>('');
  const [userNm, setUserNm] = useState<string>('');
  const [birthDate, setBirthDate] = useState<string>('');
  const [mobno1, setMobno1] = useState<string>('010');
  const [mobno2, setMobno2] = useState<string>('');
  const [mobno3, setMobno3] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [agreeA, setAgreeA] = useState<boolean>(false);
  const [agree1, setAgree1] = useState<boolean>(false);
  const [agree2, setAgree2] = useState<boolean>(false);
  const [agree3, setAgree3] = useState<boolean>(false);
  const [agree4, setAgree4] = useState<boolean>(false);
  const [agree5, setAgree5] = useState<boolean>(false);
  const [userTypeCd, setUserTypeCd] = useState<string>(
    params.userTypeCd || 'BUY',
  );
  const [companyNm, setCompanyNm] = useState<string>('');
  const [companyRegno1, setCompanyRegno1] = useState<string>('');
  const [companyRegno2, setCompanyRegno2] = useState<string>('');
  const [companyRegno3, setCompanyRegno3] = useState<string>('');
  const [telemarketingRegno, setTelemarketingRegno] = useState<string>('');
  const [companyAddrZipcode, setCompanyAddrZipcode] = useState<string>('');
  const [companyAddr, setCompanyAddr] = useState<string>('');
  const [isOpenPost, setIsOpenPost] = useState(false);

  useEffect(() => {
    if (!agree1 || !agree2 || !agree3 || !agree4 || !agree5) {
      setAgreeA(false);
    } else {
      setAgreeA(true);
    }
  }, [agree1, agree2, agree3, agree4, agree5]);

  useEffect(() => {
    if (!agreeA) {
      setAgree1(false);
      setAgree2(false);
      setAgree3(false);
      setAgree4(false);
      setAgree5(false);
    } else {
      setAgree1(true);
      setAgree2(true);
      setAgree3(true);
      setAgree4(true);
      setAgree5(true);
    }
  }, [agreeA]);

  const signUp = async () => {
    try {
      if (!id.replaceAll(' ', '')) {
        makeMsg('아이디는 필수입니다.', 'warning');
        return;
      }

      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          password,
        )
      ) {
        makeMsg(
          '비밀번호는 8~16자리 영문 대소문자, 숫자, 특수문자 조합으로 입력해주세요.',
          'warning',
        );
        return;
      }

      if (!passwordConf || password !== passwordConf) {
        makeMsg('비밀번호를 다시 확인해주세요.', 'warning');
        return;
      }

      if (!userNm.replaceAll(' ', '')) {
        makeMsg('이름을 입력해주세요.', 'warning');
        return;
      }

      if (!birthDate.replaceAll(' ', '')) {
        makeMsg('생년월일을 입력해주세요.', 'warning');
        return;
      }

      if (
        !mobno1 ||
        !mobno2.replaceAll(' ', '') ||
        !mobno3.replaceAll(' ', '')
      ) {
        makeMsg('휴대폰번호를 정확히 입력해주세요.', 'warning');
        return;
      }

      if (!userEmail.replaceAll(' ', '')) {
        makeMsg('이메일을 정확히 입력해주세요.', 'warning');
        return;
      }

      if (
        !comInfo.emailDomain &&
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail)
      ) {
        makeMsg('이메일 형식에 맞게 입력해주세요.', 'warning');
        return;
      }

      if (!agree1 || !agree2) {
        makeMsg('필수약관에 동의해주세요.', 'warning');
        return;
      }

      if (params.userTypeCd === 'SUP') {
        if (!companyNm.replaceAll(' ', '')) {
          makeMsg('상호명을 입력하세요.', 'warning');
          return;
        }

        if (
          !companyRegno1.replaceAll(' ', '') ||
          !companyRegno2.replaceAll(' ', '') ||
          !companyRegno3.replaceAll(' ', '')
        ) {
          makeMsg('사업자 등록번호를 정확히 입력해주세요.', 'warning');
          return;
        }

        if (!telemarketingRegno.replaceAll(' ', '')) {
          makeMsg('통신판매업 등록번호를 입력하세요.', 'warning');
          return;
        }

        if (!companyAddr.replaceAll(' ', '')) {
          makeMsg('사업장 주소를 입력하세요.', 'warning');
          return;
        }
      }

      const data: any = {
        userNm: userNm,
        loginId: id,
        password: password,
        birthDt: birthDate,
        userMobno: mobno1 + '-' + mobno2 + '-' + mobno3,
        userEmail: userEmail + comInfo.emailDomain,
        privacyAgreeYn: 'Y',
        usageAgreeYn: 'Y',
        comId: comInfo.comId,
        userTypeCd: params.userTypeCd,
        companyNm: companyNm,
        companyRegno: companyRegno1 + '-' + companyRegno2 + '-' + companyRegno3,
        telemarketingRegno: telemarketingRegno,
        companyAddr: companyAddr,
      };

      setIsLoading(true);
      const signUpRes = await userApi.insert(data);
      if (signUpRes.data.rsltCd === '00') {
        setIsLoading(false);
        makeMsg(
          '가입신청이 완료되었습니다.\n2~3 영업일 이내에 관리자 승인 후 이용이 가능합니다.',
          'success',
        );
        navigate('/');
      } else {
        setIsLoading(false);
        makeMsg(
          '중복된 정보가 조회되었습니다.\n관리자를 통해 회원정보를 확인해주세요.',
          'error',
        );
        return;
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      makeMsg('네트워크 오류가 발생하였습니다.', 'error');
    }
  };

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };

  const onCompletePost = (data: any) => {
    let fullAddr = data.address;
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddr +=
          extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    setCompanyAddrZipcode(data.zonecode);
    setCompanyAddr(fullAddr);
    setIsOpenPost(false);
  };

  const cancelSignUp = () => {
    navigate(-1);
  };

  return (
    <>
      {/* <Header /> */}
      {isOpenPost ? (
        <s.AddressPopupWrapper>
          <s.AddressPopupTitle>주소조회</s.AddressPopupTitle>
          <s.AddressPopupCloseButton onClick={() => setIsOpenPost(false)}>
            <MdClose size={'100%'} color={theme.color.black} />
          </s.AddressPopupCloseButton>
          <DaumPostcode
            style={{
              width: '100%',
              height: '100%',
            }}
            autoClose
            onComplete={onCompletePost}
          />
        </s.AddressPopupWrapper>
      ) : null}
      <SignUpFormPresenter
        comInfo={comInfo}
        isGetAuthNum={isGetAuthNum}
        id={id}
        setId={setId}
        password={password}
        setPassword={setPassword}
        passwordConf={passwordConf}
        setPasswordConf={setPasswordConf}
        userNm={userNm}
        setUserNm={setUserNm}
        birthDate={birthDate}
        setBirthDate={setBirthDate}
        mobno1={mobno1}
        setMobno1={setMobno1}
        mobno2={mobno2}
        setMobno2={setMobno2}
        mobno3={mobno3}
        setMobno3={setMobno3}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        agreeA={agreeA}
        setAgreeA={setAgreeA}
        agree1={agree1}
        setAgree1={setAgree1}
        agree2={agree2}
        setAgree2={setAgree2}
        agree3={agree3}
        setAgree3={setAgree3}
        agree4={agree4}
        setAgree4={setAgree4}
        agree5={agree5}
        setAgree5={setAgree5}
        signUp={signUp}
        cancelSignUp={cancelSignUp}
        userTypeCd={userTypeCd}
        companyNm={companyNm}
        setCompanyNm={setCompanyNm}
        companyRegno1={companyRegno1}
        setCompanyRegno1={setCompanyRegno1}
        companyRegno2={companyRegno2}
        setCompanyRegno2={setCompanyRegno2}
        companyRegno3={companyRegno3}
        setCompanyRegno3={setCompanyRegno3}
        companyAddrZipcode={companyAddrZipcode}
        companyAddr={companyAddr}
        setCompanyAddr={setCompanyAddr}
        telemarketingRegno={telemarketingRegno}
        setTelemarketingRegno={setTelemarketingRegno}
        onChangeOpenPost={onChangeOpenPost}
      />
      {/* <Footer /> */}
    </>
  );
};

SignUpFormContainer.defaultProps = {};

export default SignUpFormContainer;
