import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Service Route Area
import Login from './login';
import SignUp from './signUp';
import SignUpForm from './signUp/signUpForm';

// Buyer Pages
import ProductList from './main/buyer/product/list';
import ProductDetail from './main/buyer/product/detail';
import Order from './main/buyer/order';

import MyPage from './main/buyer/mypage';

// Seller Pages
import Seller from './main/seller';

import NotFound from 'components/404';

import Header from 'components/header';
import Footer from 'components/footer';
import styled from 'styled-components';

import { useRecoilState } from 'recoil';
import recoilItem from 'util/recoilitem';
import { useEffect, useState } from 'react';
import ChannelService from 'util/ChannelService';
import { Audio } from 'react-loader-spinner';
import theme from 'components/styles/theme';
import { comApi } from 'api/api-com';
import { COM_ID } from '../config/config';
import { FaCaretSquareUp } from 'react-icons/fa';

const Router = () => {
  const [isLoading, setIsLoading] = useRecoilState(recoilItem.isLoading);
  const [userInfo, setUserInfo] = useRecoilState(recoilItem.userInfo);
  const [comInfo, setComInfo] = useRecoilState(recoilItem.comInfo);
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScroll(position);
  };

  useEffect(() => {
    fetchData();
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const fetchData = async () => {
    const res = await comApi.detail({ comId: COM_ID });
    if (res.data.rsltCd === '00') {
      setComInfo({
        comId: res.data.data.comData[0].comId,
        comNm: res.data.data.comData[0].comNm,
        emailDomain: res.data.data.comData[0].emailDomain,
        logo: res.data.data.comData[0].logo,
        background: res.data.data.comData[0].background,
        address: res.data.data.comData[0].address,
        regno: res.data.data.comData[0].regno,
        telemarketing: res.data.data.comData[0].telemarketing,
        callCenter: res.data.data.comData[0].callCenter,
        helpEmail: res.data.data.comData[0].helpEmail,
        representitive: res.data.data.comData[0].representitive,
      });
    }
  };

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  // useEffect(() => {
  //   ChannelService.boot({
  //     pluginKey: "be0d42a0-6c68-47bd-8053-c09a9d1f45d9", //please fill with your plugin key
  //     memberId: userInfo.userId,
  //     profile: {
  //       userName: userInfo.userNm,
  //       userEmail: userInfo.userEmail,
  //       userTypeCd: userInfo.userTypeCd,
  //     },
  //   });
  // }, []);

  return (
    <>
      {isLoading ? (
        <Loading>
          <Audio
            height="150"
            width="150"
            color={theme.color.secondary}
            ariaLabel="three-dots-loading"
          />
        </Loading>
      ) : null}
      <BrowserRouter>
        {userInfo.userId ? (
          <>
            <Header />
            {userInfo.userTypeCd === 'BUY' ? (
              <Routes>
                <Route path="/" element={<ProductList />} />
                <Route path="/product/:productId" element={<ProductDetail />} />
                <Route path="/order" element={<Order />} />

                <Route path="/mypage" element={<MyPage />} />
                <Route path="/mypage/:activeScreen" element={<MyPage />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={<Seller />} />

                {/* <Route path="/notfound" element={<NotFound />} /> */}
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )}
            <ScrollTop opacity={scroll > 100} onClick={() => scrollToTop()}>
              <FaCaretSquareUp size={'100%'} color={theme.color.black} />
            </ScrollTop>

            <Footer />
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/signUpForm" element={<SignUpForm />} />
            <Route path="/signUpForm/:userTypeCd" element={<SignUpForm />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
};

export const Loading = styled.div`
  display: flex;
  padding: 30rem 0;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999999999;
`;

export const ScrollTop = styled.div<{ opacity: boolean }>`
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 17rem;
  right: 30rem;
  cursor: pointer;
  z-index: 99999999;
  opacity: ${(props) => (props.opacity ? '1' : '0')};
  transition: 0.35s ease-out;
`;

export default Router;
